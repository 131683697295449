<template>
    <div>
        <Integralmall v-if="$store.state.activeIndex == 'Integralmall'"></Integralmall>
        <Pointorder v-if="$store.state.activeIndex == 'Pointorder'"></Pointorder>
        <Bannersetup v-if="$store.state.activeIndex == 'Bannersetup'"></Bannersetup>
        <Integralsetting v-if="$store.state.activeIndex == 'Integralsetting'"></Integralsetting>
    </div>
</template>

<script>
    import Integralmall from './integralmall'
    import Pointorder from './pointorder'
    import Bannersetup from './bannersetup'
    import Integralsetting from './integralsetting'
    export default {
        components: {Integralmall,Pointorder,Bannersetup,Integralsetting},
        data() {
            return{

            }
        },
        created() {
            console.log(this.$store.state.activeIndex);
        },
        methods:{
            goback(){
                this.$parent.goback()
            }
        }
    }
</script>

<style scoped>

</style>