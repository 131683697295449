<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">卡券管理</div>
      </div>
      <div class="content_box">
        <div>
            <Tabs value="1" @on-click="changeTab">
                <TabPane label="创建卡券" name="1"></TabPane>
                <TabPane label="发放卡券" name="2"></TabPane>
                <TabPane label="充值赠送" name="5"></TabPane>
                <TabPane label="卡券核销" name="3"></TabPane>
                <TabPane label="核销记录" name="4"></TabPane>
            </Tabs>
        </div>
        <div v-if="index=='1'">
            <div class="condition_box">
                <div>
                    <Select v-model="storePrams.coupon_type" style="width:200px">
                            <Option v-for="item in Cardrolltype" :value="item.id" :key="item.id">{{ item.label }}</Option>
                    </Select>
                </div>
                <div style="margin-left:40px">
                    <Input placeholder="请输入卡券名称" v-model="storePrams.keyword" style="width: 200px" />
                </div>
                <div style="margin-left:40px"><Button @click="searchBtn" class="btn">搜索</Button></div>
            </div>
            <div><Button @click="cardModalBtn('add')" class="btn">创建卡券</Button></div>
            <Table stripe style="margin-top:20px" :columns="columns12" :data="data6">
                <template slot-scope="{ index }" slot="index">
                    <strong>{{ index+1 }}</strong>
                </template>
                <template slot-scope="{ row }" slot="coupon_type">
                    <div v-if="row.coupon_type==1">满减</div>
                    <div v-if="row.coupon_type==2">立减</div>
                    <div v-if="row.coupon_type==3">次卡</div>
                    <div v-if="row.coupon_type==4">商品券</div>
                </template>
                <template slot-scope="{ row }" slot="satisfy_money">
                    <div>{{ row.coupon_content }}</div>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <Button type="primary" @click="cardModalBtn(row)" ghost size="small" style="margin-right: 5px;border:1px solid #2D8CF0;background:none">编辑</Button>
                    <Button type="error" @click="delBtn(row.id)" ghost size="small">删除</Button>
                </template>
            </Table>
            <br/>
            <Page :total="total"
                :current="storePrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange"
                :page-size="storePrams.limit"/>
        </div>

        <div v-if="index=='2'">
            <div><Button @click="grantModalBtn()" class="btn">卡券发放</Button></div>
            <Table stripe style="margin-top:20px" :columns="columns13" :data="data7">
                <template slot-scope="{ row }" slot="send_type">
                    <div v-if="row.send_type==1"><Button @click="scan_Btn(row)">二维码</Button></div>
                    <div v-if="row.send_type==2">直接发放</div>
                </template>
                <template slot-scope="{ row }" slot="user_type">
                    <div v-if="row.user_type==1">所有用户</div>
                    <div v-if="row.user_type==2">会员用户</div>
                    <div v-if="row.user_type==3">非会员用户</div>
                </template>
                <template slot-scope="{ row }" slot="receive_info">
                    <div v-if="row.send_type==2">成功发放</div>
                    <div v-else>{{row.receive_info}}</div>
                </template>
            </Table>
            <br/>
            <Page :total="total1"
                :current="couponPrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange1"
                :page-size="couponPrams.limit"/>
        </div>

        <div v-if="index=='5'">
            <div><Button @click="RechargeModalBtn('add')" class="btn">充值赠送</Button></div>
            <Table stripe style="margin-top:20px" :columns="columns16" :data="data10">
                <template slot-scope="{ row }" slot="send_type">
                    <div v-if="row.send_type==1"><Button @click="scan_Btn(row)">二维码</Button></div>
                    <div v-if="row.send_type==2">直接发放</div>
                </template>
                <template slot-scope="{ row }" slot="user_type">
                    <div v-if="row.user_type==1">所有用户</div>
                    <div v-if="row.user_type==2">会员用户</div>
                    <div v-if="row.user_type==3">非会员用户</div>
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <Button type="primary" @click="RechargeModalBtn(row)" ghost size="small" style="margin-right: 5px;border:1px solid #2D8CF0;background:none">编辑</Button>
                    <Button type="error" @click="RechargedelBtn(row.id)" ghost size="small">删除</Button>
                </template>
            </Table>
            <br/>
            <Page :total="total4"
                :current="RechargeListPrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange4"
                :page-size="RechargeListPrams.limit"/>
        </div>

        <div v-if="index=='3'">
            <div class="select_box">
                <div class="hexiao_Text">核销码：</div>
                <div style="margin-left:30px">
                    <Input placeholder="请输入核销码" v-model="writePrams.code" style="width: 260px" />
                </div>
                <div style="margin-left:30px"><Button @click="searchCodeBtn" class="btn">搜索</Button></div>
            </div>
            <Table stripe style="margin-top:20px" :columns="columns14" :data="data8">
                <template slot-scope="{ row }" slot="action">
                    <Button style="border-color:#FF761D;color:#FF761D" type="error" @click="WriteBtn(row.code)" ghost size="small">确认核销</Button>
                </template>
            </Table>
            <br/>
            <Page :total="total2"
                :current="writePrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange2"
                :page-size="writePrams.limit"/>
        </div>

        <div v-if="index=='4'">
            <!-- <div class="select_box">
                <div class="hexiao_Text">核销码：</div>
                <div style="margin-left:30px">
                    <Input placeholder="请输入核销码" v-model="writePrams.code" style="width: 260px" />
                </div>
                <div style="margin-left:30px"><Button @click="searchCodeBtn" class="btn">搜索</Button></div>
            </div> -->
            <Table stripe style="margin-top:20px" :columns="columns15" :data="data9"></Table>
            <br/>
            <Page :total="total3"
                :current="writeListPrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange3"
                :page-size="writeListPrams.limit"/>
        </div>
      </div>

      <Modal
          v-model="cardModal"
          :title="cardtitle"
          width="500"
          :mask-closable="false"
          @on-cancel="cardCancel">
          <Form label-position="right" :label-width="120">
              <FormItem label="卡券名称：">
                  <Input placeholder="请输入卡券名称" v-model="formData.coupon_name"></Input>
              </FormItem>
              <FormItem label="卡券类型：">
                <Select @on-change="typeChange" v-model="formData.coupon_type" style="width:300px">
                    <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="formData.coupon_type == 1" label="订单满：">
                  <Input placeholder="请输入订单满" v-model="formData.satisfy_money"></Input>
              </FormItem>
              <FormItem v-if="formData.coupon_type != 3&&formData.coupon_type != 4" label="立减：">
                  <div style="display:flex;align-itmes:center">
                    <Input placeholder="请输入立减" v-model="formData.reduce_money"></Input>
                    <Tooltip max-width="300" content="消费金额必须大于或等于立减金额，才能使用" placement="left">
                        <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                    </Tooltip>
                  </div>
              </FormItem>
              <FormItem v-if="formData.coupon_type == 3" label="次数：">
                  <Input placeholder="请输入次数" v-model="formData.num"></Input>
              </FormItem>
              <FormItem label="期限类型：">
                <Select v-model="formData.term_type" style="width:300px">
                    <Option v-for="item in cityList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="formData.term_type==2" label="有效天数：">
                  <Input placeholder="请输入有效天数" v-model="formData.days"></Input>
              </FormItem>
              <FormItem v-if="formData.term_type==3" label="日期选择：">
                  <DatePicker @on-change="time" v-model="times" type="daterange" placement="bottom-end" placeholder="点击选择开始结束日期" style="width: 300px"></DatePicker>
                  <!-- 选择日期限制时，显示开始日期与结束日期 -->
              </FormItem>
              <FormItem v-if="formData.coupon_type==1||formData.coupon_type==2" label="限制范围：">
                  <i-switch v-model="formData.goods_limit" :true-value="1" :false-value="0"/>
              </FormItem>
              <span v-if="formData.goods_limit==1">
                  <FormItem label="选择分类：">
                        <Select :max-tag-count="4" :max-tag-placeholder="maxTagPlaceholder" placeholder="不选则默认所有分类" @on-change="class_Change" v-model="formData.cate_ids" filterable multiple>
                            <!-- <Option value="all" key="all" >
                                <p @click="changeAll(formData.cate_ids.length !== classificationList.length)" >{{formData.cate_ids.length !== classificationList.length ? "全选" : "取消全选"}}</p>
                            </Option> -->
                            <Option v-for="item in classificationList" :value="item.id" :key="item.id">{{ item.cate_name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="选择商品：">
                        <Select :max-tag-count="4" :max-tag-placeholder="maxTagPlaceholder" placeholder="不选则默认所有商品" v-model="formData.goods_ids" filterable multiple>
                            <!-- <Option value="all" key="all" >
                                <p @click="changeAll1(formData.goods_ids.length !== commodityList.length)" >{{formData.goods_ids.length !== commodityList.length ? "全选" : "取消全选"}}</p>
                            </Option> -->
                            <Option v-for="item in commodityList" :value="item.id" :key="item.id">{{ item.goods_name }}</Option>
                        </Select>
                    </FormItem>
              </span>
              <span v-if="formData.coupon_type==4">
                    <FormItem label="选择分类：">
                        <Select placeholder="点击选择分类" @on-change="class_Change1" v-model="cate_ids">
                            <Option v-for="item in classificationList" :value="item.id" :key="item.id">{{ item.cate_name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="选择商品：">
                        <Select placeholder="点击选择商品" v-model="goods_ids">
                            <Option v-for="item in commodityList" :value="item.id" :key="item.id">{{ item.goods_name }}</Option>
                        </Select>
                    </FormItem>
              </span>
              <FormItem v-if="formData.coupon_type==1||formData.coupon_type==2||formData.coupon_type==4" label="使用时间限制：">
                  <i-switch v-model="formData.time_limit" :true-value="1" :false-value="0"/>
              </FormItem>
              <FormItem v-if="formData.time_limit==1" label="选择时间：">
                <TimePicker v-model="formData.card_use_time" ref="element" format="HH:mm" type="timerange"  @on-change="changeCardTime($event)" placement="bottom-end" placeholder="点击选择时间" style="width: 300px"></TimePicker>
                
              </FormItem>
            </Form>
          <div slot="footer">
              <Button @click="cardCancel">取消</Button>
              <Button class="btn" @click="cardadd">保存</Button>
          </div>
      </Modal>

        <!-- 二维码弹框 -->
      <Modal
        v-model="code_modal"
          :mask-closable="false"
        title="二维码">
        <div>
            <div class="code_pic"><img :src="code_path" alt=""></div>
            <div class="code_text">扫码领取</div>
        </div>
        <div slot="footer">
            <Button @click="code_modal = false" style="background:#FF761D;color:#FFF;border-color:#FF761D">确定</Button>
        </div>
    </Modal>

    <!-- 卡券发放弹框 -->
    <Modal
          v-model="grant_Modal"
          :mask-closable="false"
          title="卡券发放"
          width="500"
          @on-cancel="grantCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="卡券选择：">
                <Select v-model="grantData.coupon_id" style="width:300px">
                    <Option v-for="item in CouponList" :value="item.id" :key="item.id">{{ item.coupon_name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="卡券数量：">
                  <Input placeholder="请输入卡券数量" v-model="grantData.num"></Input>
              </FormItem>
              <FormItem label="用户类型：">
                <Select @on-change="inputChange" v-model="grantData.user_type" style="width:300px">
                    <Option v-for="item in userType" :value="item.id" :key="item.id">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem label="用户选择：">
                  <Select filterable :placeholder="grantData.user_type==1?'默认选择所有用户':grantData.user_type==2?'默认选择所有会员用户':'默认选择所有非会员用户'" v-model="grantData.user_ids" multiple :max-tag-count="3" :max-tag-placeholder="maxTagPlaceholder">
                    <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="发放类型：">
                <Select v-model="grantData.send_type" style="width:300px">
                    <Option v-for="item in sendType" :value="item.id" :key="item.id">{{ item.label }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="grantCancel">取消</Button>
              <Button class="btn" @click="grantadd">保存</Button>
          </div>
      </Modal>

    <!-- 充值赠送弹框 -->
    <Modal
          v-model="Recharge_Modal"
          :mask-closable="false"
          :title="Rechargetitle"
          width="500"
          @on-cancel="grantCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="充值金额：">
                  <Input placeholder="请输入充值金额" type="number" v-model="rechardata.money"></Input>
              </FormItem>
              <FormItem label="卡券选择：">
                <Select v-model="rechardata.coupon_id" style="width:300px">
                    <Option v-for="item in CouponList" :value="item.id" :key="item.id">{{ item.coupon_name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="卡券数量：">
                  <Input placeholder="请输入卡券数量" type="number" v-model="rechardata.num"></Input>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="RechargeCancel">取消</Button>
              <Button class="btn" @click="Rechargeadd">保存</Button>
          </div>
      </Modal>
  </div>
</template>

<script>
import {indexCard,addCard,delCard,editCard,couponSendList,writeOffRecord,getCoupon,getUsers,couponSend,waitList,writeOff,couponRechargeList,addRecharge,updateRecharge,delRecharge} from '@/api/setting'
import {getCate,getGoods} from '@/api/customer'
export default {
  name: "password",
  data() {
    return {
      index:'1',
      code_modal:false,
      grant_Modal:false,
      Recharge_Modal:false,
      code_path:'',
      cardtitle:'创建卡券',
      Rechargetitle:'充值赠送',
      cardModal:false,
      total:0,
      total1:0,
      total2:0,
      total3:0,
      total4:0,
      times:[],
      grantData:{
          coupon_id:null,
          num:'',
          user_type:1,
          user_ids:[],
          send_type:1
      },
      rechardata:{
          money:'',
          coupon_id:'',
          num:'',
      },
      storePrams:{
        page:1,
        limit:10,
        keyword:'',
        coupon_type:0
      },
      couponPrams:{
        page:1,
        limit:10,
      },
      writePrams:{
        code:'',
        page:1,
        limit:10,
      },
      writeListPrams:{
        page:1,
        limit:10,
      },
      RechargeListPrams:{
        page:1,
        limit:10,
      },
      formData:{
        coupon_name:'',
        coupon_type:1,
        num:'',
        satisfy_money:'',
        reduce_money:'',
        term_type:1,
        days:'',
        goods_limit:0,
        start_date:'',
        end_date:'',
        cate_ids:[],
        goods_ids:[],
        card_use_time:"",
        time_limit:0,
        card_use_end_time:"",
        card_use_start_time:""
      },
      cate_ids:'',
      goods_ids:'',
      cityList: [
          {
              value: 1,
              label: '满减'
          },
          {
              value: 2,
              label: '立减'
          },
          {
              value: 3,
              label: '次卡'
          },
          {
              value: 4,
              label: '商品券'
          }
      ],
      cityList1: [
          {
              value: 1,
              label: '永久有效'
          },
          {
              value: 2,
              label: '天数限制'
          },
          {
              value: 3,
              label: '日期限制'
          },
      ],
      columns12: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '卡券名称',
              key: 'coupon_name',
              align: 'center'
          },
          {
              title: '卡券类型',
              slot: 'coupon_type',
              align: 'center'
          },
          {
              title: '优惠内容',
              slot: 'satisfy_money',
              align: 'center'
          },
          {
              title: '卡券期限',
              key: 'time',
              align: 'center',
              width: 200
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center',
              width: 200
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      columns13: [
          {
              title: '卡券名称',
              key: 'coupon_name',
              align: 'center'
          },
          {
              title: '发放类型',
              slot: 'send_type',
              align: 'center'
          },
          {
              title: '用户类型',
              slot: 'user_type',
              align: 'center'
          },
          {
              title: '状态',
              slot: 'receive_info',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center',
          }
      ],
      columns14: [
          {
              title: '用户姓名',
              key: 'name',
              align: 'center'
          },
          {
              title: '用户编号',
              key: 'user_number',
              align: 'center'
          },
          {
              title: '用户联系方式',
              key: 'phone',
              align: 'center'
          },
          {
              title: '卡券名称',
              key: 'coupon_name',
              align: 'center'
          },
          {
              title: '可核销次数',
              key: 'coupon_num',
              align: 'center',
          },
          {
              title: '卡券内容',
              key: 'coupon_content',
              align: 'center',
          },
          {
              title: '使用期限',
              key: 'time',
              width:200,
              align: 'center',
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      columns15: [
          {
              title: '用户姓名',
              key: 'name',
              align: 'center'
          },
          {
              title: '用户编号',
              key: 'user_number',
              align: 'center'
          },
          {
              title: '用户联系方式',
              key: 'phone',
              align: 'center'
          },
          {
              title: '卡券名称',
              key: 'coupon_name',
              align: 'center'
          },
        //   {
        //       title: '可核销次数',
        //       key: 'coupon_num',
        //       align: 'center',
        //   },
          {
              title: '卡券内容',
              key: 'coupon_content',
              align: 'center',
          },
          {
              title: '使用期限',
              key: 'time',
              width:200,
              align: 'center',
          }
      ],
      columns16: [
          {
              title: '充值',
              key: 'money',
              align: 'center'
          },
          {
              title: '卡券',
              key: 'coupon_name',
              align: 'center'
          },
          {
              title: '赠送数量',
              key: 'num',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center',
          },
          {
              title: '操作',
              slot: 'operation',
              width: 160,
              fixed: 'right',
              align: 'center',
          }
      ],
      CouponList:[],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      data10: [],
      Cardrolltype: [
            {
                id: 0,
                label: '所有类型'
            },
            {
                id: 1,
                label: '满减类型'
            },
            {
                id: 2,
                label: '立减类型'
            },
            {
                id: 3,
                label: '次卡类型'
            }
      ],
      userType: [
            {
                id: 1,
                label: '所有用户'
            },
            {
                id: 2,
                label: '会员用户'
            },
            {
                id: 3,
                label: '非会员用户'
            }
      ],
      userList:[],
      sendType:[
            {
                id: 1,
                label: '生成二维码'
            },
            {
                id: 2,
                label: '直接发放'
            }
      ],
      classificationList: [],
      commodityList: [],
    }
  },
  created() {
      this.indexCard()
  },
  methods: {
    
      changeCardTime(e){
            this.formData.card_use_start_time = e[0]
            this.formData.card_use_end_time = e[1]
      },
      indexCard(){
          indexCard(this.storePrams).then(res=>{
              this.total = res.data.total
              this.data6 = res.data.data
          })
      },
      couponSendList(){
          couponSendList(this.couponPrams).then(res=>{
              this.total1 = res.data.total
              this.data7 = res.data.data
          })
      },
      writeOffRecord(){
          writeOffRecord(this.writeListPrams).then(res=>{
              this.total3 = res.data.total
              this.data9 = res.data.data
          })
      },
      couponRechargeList(){
          couponRechargeList(this.RechargeListPrams).then(res=>{
              this.total4 = res.data.total
              this.data10 = res.data.data
          })
      },
      getCoupon(){
          getCoupon().then(res=>{
              this.CouponList = res.data
          })
      },
      getUsers(){
          getUsers({type:this.grantData.user_type}).then(res=>{
              this.userList = res.data
          })
      },
      waitList(){
          waitList(this.writePrams).then(res=>{
              this.total2 = res.data.total
              this.data8 = res.data.data
          })
      },
      maxTagPlaceholder (num) {
          return '更多'+ num;
      },
      inputChange(){
          this.grantData.user_ids = []
          this.getUsers()
      },
      searchBtn(){
          this.indexCard()
      },
      searchCodeBtn(){
          this.waitList()
      },
      scan_Btn(e){
          this.code_path = e.code_path
          this.code_modal = true
      },
      time(e){
          this.formData.start_date = e[0]
          this.formData.end_date = e[1]
      },
    RechargeModalBtn(e){
        this.getCoupon()
        if (e=='add') {
            this.Rechargetitle = '充值赠送'
            this.Recharge_Modal = true
        }else{
            this.rechardata.id = e.id
            this.rechardata.money = e.money
            this.rechardata.coupon_id = e.coupon_id
            this.rechardata.num = e.num
            this.Rechargetitle = '编辑充值赠送'
            this.Recharge_Modal = true
      }
    },
    // 卡券类型
    typeChange(e){
        console.log(e);
        if (e==4) {
            this.getGoods(2)
        }else{
            this.getGoods()
        }
        this.formData.goods_limit = 0
        this.formData.cate_ids = []
        this.formData.goods_ids = []
        this.cate_ids = ''
        this.goods_ids = ''
        this.commodityList = []
        if (e==3) {
            this.formData.goods_limit = 0
        }
    },
    changeAll (isTrue) {
        const self = this
        setTimeout(() => {
            self.formData.cate_ids = []
            if (isTrue) {
                self.classificationList.forEach(temp => {
                    self.formData.cate_ids.push(temp.id)
                })
            }
        }, 0)
    },
    changeAll1 (isTrue) {
        const self = this
        setTimeout(() => {
            self.formData.goods_ids = []
            if (isTrue) {
                self.commodityList.forEach(temp => {
                    self.formData.goods_ids.push(temp.id)
                })
            }
        }, 0)
    },
    // 监听分类选择
    class_Change(e){
        console.log(e);
        this.formData.goods_ids = []
        this.getGoods()
    },
    class_Change1(e){
        this.formData.goods_ids = []
        if (e) {
            this.formData.cate_ids = [e]
        }
        this.getGoods(2)
    },
    // 获取商品
    getGoods(e){
        let data = {
            type:e,
            cate_ids:this.formData.cate_ids,
        }
        getGoods(data).then(res=>{
            this.commodityList = res.data
        })
    },
    // 获取商品分类
    getCate(){
        getCate().then(res=>{
            this.classificationList = res.data
        })
    },
    RechargeCancel(){
        this.Recharge_Modal = false
        this.rechardata = {
            money:'',
            coupon_id:'',
            num:'',
        }
    },
    Rechargeadd(){
        if (this.Rechargetitle=='充值赠送') {
            addRecharge(this.rechardata).then(res=>{
                this.$Message.success(res.msg)
                this.couponRechargeList()
                this.RechargeCancel()
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        }else{
            updateRecharge(this.rechardata).then(res=>{
                this.$Message.success(res.msg)
                this.couponRechargeList()
                this.RechargeCancel()
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        }
    },
    cardModalBtn(e){
      if (e=='add') {
        this.cardtitle = '创建卡券'
        this.cardModal = true
      }else{
          console.log(e);
        this.formData.id = e.id
        this.times = [e.start_date,e.end_date]
        this.formData.coupon_name = e.coupon_name
        this.formData.num = e.num
        this.formData.goods_limit = e.goods_limit
        this.formData.coupon_type = e.coupon_type
        this.formData.satisfy_money = e.satisfy_money
        this.formData.reduce_money = e.reduce_money
        this.formData.term_type = e.term_type
        this.formData.days = e.days
        this.formData.start_date = e.start_date
        this.formData.end_date = e.end_date
        this.formData.time_limit = e.time_limit  
        if (e.card_use_start_time =="" || e.card_use_start_time == "") {
            this.formData.card_use_time = ""
        } else {
            this.formData.card_use_time = [e.card_use_start_time, e.card_use_end_time]
        }
        
        this.formData.card_use_start_time = e.card_use_start_time
        this.formData.card_use_end_time = e.card_use_end_time
        if (e.coupon_type==4) {
            this.cate_ids = Number(e.cate_ids)
            this.goods_ids = Number(e.goods_ids)
        }
        this.formData.cate_ids = e.cate_ids_arr
        let that = this
        setTimeout(() => {
            that.formData.goods_ids = e.goods_ids_arr
        }, 500);


        this.cardtitle = '编辑卡券'
        this.cardModal = true
      }
      this.getCate()
      if (e.coupon_type==4) {
          this.getGoods(2)
      }else{
          this.getGoods()
      }

    },
    changeTab(e){
      if (e=='1') {
        this.indexCard()
      }else if(e=='2'){
        this.couponSendList()
      }else if(e=='3'){
        this.waitList()
      }else if(e=='4'){
        this.writeOffRecord()
      }else if(e=='5'){
        this.couponRechargeList()
      }
      this.index = e
    },
    cardCancel(){
        this.clearform()
      this.cardModal = false
    },
    grantModalBtn(){
        this.getCoupon()
        this.getUsers()
        this.grant_Modal = true
    },
    grantCancel(){
        this.grant_Modal = false
        this.cleargrantData()
    },
    cleargrantData(){
        this.grantData = {
          coupon_id:null,
          num:'',
          user_type:1,
          user_ids:[],
          send_type:1
        }
    },
    grantadd(){
        couponSend(this.grantData).then(res=>{
            this.$Message.success(res.msg)
            this.couponSendList()
            this.cleargrantData()
            this.grant_Modal = false
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    clearform(){
        this.times = []
        this.formData = {
            coupon_name:'',
            coupon_type:1,
            num:'',
            satisfy_money:'',
            reduce_money:'',
            term_type:1,
            days:'',
            goods_limit:0,
            start_date:'',
            end_date:'',
            cate_ids:[],
            goods_ids:[],
            time_limit:0,
            card_use_time:"",
            card_use_end_time:"",
            card_use_start_time:""
        }
    },
    cardadd(){
        if (this.formData.coupon_type==4) {
            if (this.cate_ids.length==0) return this.$Message.warning('请选择分类')
            if (this.goods_ids.length==0) return this.$Message.warning('请选择商品')
            if (this.cate_ids) {
                this.formData.cate_ids = [this.cate_ids]
            }
            if (this.goods_ids) {
                this.formData.goods_ids = [this.goods_ids]
            }
        }
        if (this.formData.time_limit == 1 && (this.formData.card_use_start_time == ""  || this.formData.card_use_end_time == "" )) {
            return this.$Message.warning('请选择使用时间')
        }
      if (this.cardtitle == '创建卡券') {
          addCard(this.formData).then(res=>{
              this.$Message.success(res.msg)
              this.indexCard()
              this.cardModal = false
              this.clearform()
          }).catch(err=>{
              this.$Message.error(err.msg)
          })
      }else{
          editCard(this.formData).then(res=>{
              this.$Message.success(res.msg)
              this.indexCard()
              this.cardModal = false
              this.clearform()
          }).catch(err=>{
              this.$Message.error(err.msg)
          })
      }
    },
    delBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delCard({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexCard()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    RechargedelBtn(id){
        this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delRecharge({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.couponRechargeList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    WriteBtn(e){
        this.$Modal.confirm({
        title: '提示',
        content: `您正在使用核销功能`,
        onOk: () => {
          writeOff({code:e}).then(res=>{
            this.$Message.success(res.msg)
            this.waitList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    pageChange(index){
      this.storePrams.page = index
      this.indexCard()
    },
    pageChange1(index){
      this.couponPrams.page = index
      this.couponSendList()
    },
    pageChange2(index){
      this.writePrams.page = index
      this.waitList()
    },
    pageChange3(index){
      this.writeListPrams.page = index
      this.writeOffRecord()
    },
    pageChange4(index){
      this.RechargeListPrams.page = index
      this.couponRechargeList()
    },
    backBtn(){
        this.$emit('backview',0)
    }
  }
}
</script>

<style scoped>
.son-page{

}
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    padding: 30px;
    /* padding: 10px 30px; */
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
}
.condition_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px 0 20px 0;
    /* margin-top: 30px; */
}
.select_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    padding: 20px 0;
    align-items: center;
}
.hexiao_Text{
    font-weight: 600;
    font-size: 18px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.code_pic{
    width: 200px;
    height: 200px;
    margin: 0 auto;
}
.code_pic img{
    width: 100%;
    height: 100%;
}
.code_text{
    text-align: center;
    margin-top: 20px;
}
</style>
