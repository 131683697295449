<template>
  <div>
    <!-- formData.type == 1 banner-->
    <div style="margin-top: 10px">
      <Form label-position="right" :label-width="70">
        <FormItem style="margin: 0 0 10px 0" label="尺寸">
          <div style="margin-bottom: 5px" class="just_sb">
            <div class="display_flex">
              <div class="kuai_box">高</div>
              <Input
                v-model="formData.height"
                placeholder="0"
                style="width: 120px"
              ></Input>
            </div>
          </div>
        </FormItem>
      </Form>
    </div>
    <div
      v-for="(item, index) of formData.content.imgList"
      :key="index"
      class="style_box"
    >
      <div class="title_box">
        <div class="title_text">
          <Icon style="font-size: 20px" type="ios-arrow-down" />banner{{
            index + 1
          }}编辑
        </div>
        <div>
          <Icon
            @click="addbanner"
            class="add_icon"
            type="ios-add-circle-outline"
          />
        </div>
      </div>
      <div class="banner_box" @click="openModal(1, index)">
        <img
          @click.stop="delBtn(index)"
          class="del_icon"
          src="@/assets/imgs/del.png"
          alt=""
        />
        <!-- <Upload
          ref="upload"
          :show-upload-list="false"
          :format="['jpg', 'jpeg', 'png', 'gif']"
          :max-size="2048"
          :headers="header"
          :on-format-error="handleFormatError"
          :on-success="(res) => bannerSuccess(res, item)"
          :on-progress="handleProgress"
          multiple
          :action="uploads"
        > -->
        <Icon class="absolute_icon" type="md-add" />
        <!-- </Upload> -->
        <img
          :src="item.value.indexOf('http') == -1 ? item.picview : item.value"
          alt=""
        />
      </div>
      <imageLibrary
        v-if="switch_modal"
        :switch_modal.sync="switch_modal"
        @change="costPlannedAmountChange"
        :select="imgtype"
      ></imageLibrary>
      <div>
        <Form label-position="right" :label-width="70">
          <FormItem label="动作">
            <Select v-model="item.action">
              <Option
                v-for="item in actionList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <FormItem v-if="item.action == 1" v-model="item.path" label="跳转页">
            <Select v-model="item.path">
              <Option
                v-for="item in pageList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <FormItem
            v-if="item.action == 2"
            v-model="item.shop"
            label="跳转商品"
          >
            <Select v-model="item.shop">
              <Option
                v-for="item in shopList"
                :value="item.id"
                :key="item.id"
                >{{ item.goods_name }}</Option
              >
            </Select>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config";
import imageLibrary from "@/components/diy/imageLibrary.vue";
export default {
  components: { imageLibrary },
  props: {
    formData: {
      type: Object,
      default: {},
    },
    actionList: {
      type: Array,
      default: [],
    },
    pageList: {
      type: Array,
      default: [],
    },
    shopList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      srctype: "",
      imgtype: 1,
      switch_modal: false,
      header: { "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token") },
      uploads: config.apiUrl + "/storeapi/data/uploads",
    };
  },
  methods: {
    // 事件处理函数
    async costPlannedAmountChange(param1) {
      console.log(param1);
      console.log("123", this.formData.content.imgList);
      console.log("456", this.imgtype);
      for (var i = 0; i < param1.length; i++) {
        this.formData.content.imgList[this.srctype].picview = param1[i];
        this.formData.content.imgList[this.srctype].value = param1[i];
      }
      this.$Message.destroy();
    },
    openModal(e, index) {
        this.imgtype = e;
        this.switch_modal = true;
        this.srctype = index;
        console.log(index,this.srctype);
    },
    // 添加轮播图
    addbanner() {
      console.log("123", this.formData.content.imgList);
      this.formData.content.imgList.push({
        picview: "http://canyin.hulianzaowu.top/img/diy/banner.png",
        value: "/img/diy/banner.png",
        action: 1,
        path: null,
        shop: null,
      });
    },
    // 删除轮播
    delBtn(e) {
      if (this.formData.content.imgList.length == 1) {
        this.$Message.info("至少保留一张");
      } else {
        this.formData.content.imgList.splice(e, 1);
      }
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    // 轮播图上传
    bannerSuccess(e, item) {
      item.picview = e.data.path;
      item.value = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
  },
};
</script>

<style scoped>
.just_sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display_flex {
  display: flex;
}
.kuai_box {
  width: 30px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #bfbfbf;
}
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.add_icon {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.banner_box {
  width: 100%;
  height: 130px;
  display: flex;
  margin: 10px 0;
  justify-content: center;
  position: relative;
}

.banner_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.del_icon {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 9;
  width: 26px !important;
  height: 26px !important;
  cursor: pointer;
}
.absolute_icon {
  position: absolute;
  font-size: 50px;
  cursor: pointer;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>