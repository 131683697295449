<template>
<div>
     <Cascader :data="data" :load-data="loadData" style="width:300px" @on-change="changeCascaderValue" v-model="city"></Cascader>
</div>
</template>

<script>
    import {getShopRegion} from "@/api/setting"
    export default {
        name: "CitySelect",
        props:{
               default_citys:{
                    type:Array,
                    default:function(){
                         return []
                    },
               }
        },
        data () {
            return {
                city:[],
                code:"",
                data: [],
                submitForm:{}
            }
        },
        mounted() {
            this.city = this.default_citys
            this.code = "";
            this.loadList()
        },
        methods: {
            loadList(func){
                getShopRegion({super_code:this.code}).then(res=>{
                    if(res.code == 200) {
                        if (this.code == "") {
                            this.data = res.data
                        }else {
                            typeof func == "function" && func(res.data)
                        }
                    } else {
                        this.$Message.error(res.msg);
                    }
                })
            },
            loadData (item, callback) {
                this.code = item.value
                item.loading = true
                this.loadList(function(data){
                     item.loading = false;
                     item.children = data
                     callback();
                })
            },
            changeCascaderValue(v,e){
                 this.submitForm.city_number = v
                 let names = []
                 for (var i in e) {
                      names.push(e[i].label)
                 }
                 this.submitForm.city_name = names.join("")
                 this.$emit("select_city", this.submitForm)
            }
        }
    }
</script>

<style scoped>

</style>
