<template>
    <div>
        <div @click="backBtn" class="back_box">
            <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
            <div class="ml10">页面管理</div>
        </div>
        <div class="content_box">
            <div class="pageBox">
                <div v-for="(item,index) of List" :key="index" class="item_page" @click="itemPageClick">
                    <div class="page_title">{{item.title}}</div>
                    <div class="link_code_box">
                        <div class="page_txt" @click="copy(item.page)">{{item.page}}</div>
                        <div class="link_code_pic">
                            <Tooltip content="复制链接" placement="top">
                                <img @click="copy(item.page)" style="margin-right:10px" src="../../../../assets/link.png" alt="">
                            </Tooltip>
                            <Tooltip content="二维码" placement="top">
                                <img @click="lookcode(item.qrcode)" src="../../../../assets/code.png" alt="">
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 二维码弹框 -->
        <Modal
            v-model="CodeModal"
            title="页面二维码"
            @on-ok="CodeModal = false"
            @on-cancel="CodeModal = false">
            <div style="width:100%;display: flex;justify-content: center;">
                <img :src="Imgurl" alt="">
            </div>
        </Modal>
    </div>
</template>

<script>
    import {getPageList} from '@/api/setting'
    export default {
        data(){
            return{
                List:[],
                CodeModal:false,
                Imgurl:''
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){
                getPageList().then(res=>{
                    console.log(res.data.data);
                    this.List = res.data.data
                })
            },
            copy(url){
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                // console.log(oInput.value)
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$Message.success('复制成功');
                oInput.remove()
            },
            lookcode(url){
                this.CodeModal = true
                this.Imgurl = url
            },
            backBtn(){
                this.$emit('backview',0)
            },
            itemPageClick(){
                console.log('click...')

            }
        }
    }
</script>

<style scoped>
    .back_box{
        width: 100%;
        cursor: pointer;
        background: #FFF;
        border-radius: 12px;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        align-items: center;
        padding: 30px;
    }
    .content_box{
        width: 100%;
        height: 75vh;
        overflow: auto;
        margin-top: 30px;
        background: #FFF;
        border-radius: 12px;
        padding: 30px;
    }
    .pageBox{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .item_page{
        padding: 20px 20px;
        background: #F6F6F6;
        border-radius: 10px;
        /* height: 200px; */
        width: 300px;
        margin: 0 20px 20px 0;
    }
    .page_title{
        display: inline-block;
        color: #999;
        border: 1px solid #999;
        padding: 0px 5px;
        border-radius: 4px;
        background: #FFFFFF;
    }
    .link_code_box{
        margin: 20px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .link_code_pic{
        display: flex;
        align-items: center;
    }
    .link_code_pic img{
        cursor: pointer;
        /* width: 25px;
        height: 25px; */
    }
    .page_txt{
        cursor: pointer;
    }
    .page_txt:hover{
        transition: all 0.5s;
        color: #FF761D;
    }
    .page_txt{
        transition: all 0.5s;
    }
</style>