<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">打印机管理</div>
      </div>
      <div class="content_box">
        <div style="padding: 30px 30px 0 30px;" class="search_box">
          <div>
            <Button @click="cardModalBtn('add')" class="btn">新增WiFi打印机</Button>
            <Button style="margin-left:10px" @click="jixingBtn" class="btn">USB机型选择</Button>
          </div>
          <div>
            <Input v-model="storePrams.print_name" style="width:200px" placeholder="请输入"></Input>
            <Button @click="searchBtn" class="ml10">搜索</Button>
          </div>
        </div>
        <Alert type="error" style="margin:20px 30px 30px 30px" >
          温馨提示：
            <template slot="desc">
              <div>请按照我们指定的打印机型号进行相关配置，同品牌其他型号不保证可以正常打印；</div>
              <div style="margin-top:5px">1、无线打印机支持佳博打印机、飞鹅打印机、芯烨打印机，建议购买佳博SH584、佳博GP-R220C+、飞鹅V58W、大趋智能打印机TRENDIT p7</div>
              <div style="margin-top:5px">2、标签打印机仅支持芯烨标签打印机，建议购买XP-T271U 。</div>
              <div style="margin-top:5px">3、支持市面上大部分有线打印机（USB打印机）。 </div>
              <div style="margin-top:5px">本地打印机控件下载地址：<a target="_blank" href="https://www.lodop.net/download.html">https://www.lodop.net/download.html</a> </div>
              <div style="margin-top:5px">购买链接：<a target="_blank" href="https://shop1.hulianzaowu.online/product/view429.html">https://shop1.hulianzaowu.online/product/view429.html</a></div>
            </template>
        </Alert>
       
        <Table stripe style="margin-top:20px;padding:0 30px 30px 30px" :columns="columns12" :data="data6">
            <template slot-scope="{ index }" slot="index">
                <strong>{{ index+1 }}</strong>
            </template>
            <template slot-scope="{ row }" slot="print_type">
              <div>{{row.printer_type_name}}</div>
            </template>
            <template slot-scope="{ row }" slot="zhuo">
              <Button @click="TableBtn(row)">设置桌位区域</Button>
            </template>
            <template slot-scope="{ row }" slot="type">
              <Button @click="issueBtn(row)">设置</Button>
            </template>
            <template slot-scope="{ row }" slot="num">
              <Button @click="timesBtn(row)">{{row.num}}</Button>
            </template>
            <template slot-scope="{ row }" slot="shop">
              <Button :disabled="row.order_template==1?true:false" @click="shopBtn(row)">设置</Button>
              <Tooltip max-width="400" content="出单类型设置分单才可以单独设置打印商品" placement="left">
                  <Icon style="margin-left:10px" type="ios-help-circle" />
              </Tooltip> 
            </template>
            <template slot-scope="{ row }" slot="openswitch">
              <i-switch :value="row.isable == 1" @on-change="change(row)" ></i-switch>
            </template>
            <template slot-scope="{ row }" slot="action">
                <!-- <Button type="primary" @click="cardModalBtn(row)" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border-color:#2D8CF0;background:none">编辑</Button> -->
                <Button type="primary" @click="PrintBtn(row.id)" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border-color:#2D8CF0;background:none">测试打印</Button>
                <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
            </template>
        </Table>
        <br/>
        <Page :total="total"
              :current="storePrams.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange"
              :page-size="storePrams.limit"/>
      </div>

      <Modal
          v-model="cardModal"
          :title="cardtitle"
          width="500"
          :mask-closable="false">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="打印机名称：">
                  <Input placeholder="请输入打印机名称" v-model="formData.print_name"></Input>
              </FormItem>
              <FormItem label="打印机类型：">
                <Select @on-change="ticketChange" v-model="formData.ticket_type" style="width:300px">
                    <Option v-for="item in PrinterList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="formData.ticket_type==1" label="厂家：">
                <Select v-model="formData.print_type" style="width:300px">
                    <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="formData.ticket_type==2" label="厂家：">
                <Select v-model="formData.print_type" style="width:300px">
                    <Option v-for="item in xinyeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="formData.print_type != 4" :label="formData.print_type==2?'注册账号':(formData.print_type==1?'api编码：':'开发者ID:')">
                  <Input :placeholder="formData.print_type==2?'请输入注册账号':(formData.print_type==1?'请输入api编码':'请输入开发者ID')" v-model="formData.member_code"></Input>
              </FormItem>
              <FormItem v-if="formData.print_type == 4" label="appid">
                  <Input placeholder="请输入APPID" v-model="formData.member_code"></Input>
              </FormItem>
              <FormItem label="api密钥：">
                  <Input placeholder="请输入api密钥" v-model="formData.api_key"></Input>
              </FormItem>
              <FormItem v-if="formData.print_type==2 || formData.print_type==4" :label="formData.print_type==4?'绑定码：' : '打印机key：'">
                  <Input placeholder="请输入key" v-model="formData.key"></Input>
              </FormItem>
              <FormItem label="设备编号：">
                  <Input placeholder="请输入设备编号" v-model="formData.print_number"></Input>
              </FormItem>
              <!-- <FormItem v-if="formData.print_type==2" label="打印机key：">
                  <Input placeholder="请输入打印机key" v-model="formData.key"></Input>
              </FormItem> -->
          </Form>
          <div slot="footer">
              <Button @click="cardCancel">取消</Button>
              <Button class="btn" @click="cardadd">保存</Button>
          </div>
      </Modal>

      <Modal
          v-model="issueModal"
          title="出单类型"
          :mask-closable="false"
          width="500">
          <Form style="width:400px;height:200px" label-position="right" :label-width="100">
              <FormItem label="订单类型：">
                <CheckboxGroup @on-change="Checkbox_change" v-model="formData2.order_type">
                    <Checkbox label="1">堂食</Checkbox>
                    <Checkbox label="2">外卖</Checkbox>
                    <Checkbox label="3">自取</Checkbox>
                    <Checkbox label="4">排队取餐</Checkbox>
                </CheckboxGroup>
              </FormItem>
              <FormItem label="出单类型：">
                <Select @on-change="Select_change" v-model="formData2.order_template" style="width:300px">
                    <Option v-for="item in cityList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem label="纸张宽度：">
                <Select  v-model="formData2.machine_width" style="width:300px">
                    <Option v-for="item in machine_width_list" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
              <FormItem label="结尾文本：" v-if="formData2.ticket_type == 2"  >
                  <Input placeholder="请输入打印机结尾文本" v-model="formData2.end_text"></Input>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="issueModal = false">取消</Button>
              <Button class="btn" @click="issueadd">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="shopModal"
          title="设置打印商品"
          :mask-closable="false"
          width="500">
          <Form style="width:400px;height:200px" label-position="left" :label-width="100">
              <FormItem label="商品分类：">
                <Select v-model="formData3.cate_ids" filterable multiple>
                    <Option v-for="item in cityList5" :value="item.id" :key="item.id">{{ item.cate_name }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="shopModal = false">取消</Button>
              <Button class="btn" @click="shopadd">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="shopModal"
          title="设置打印商品"
          :mask-closable="false"
          width="500">
          <Form style="width:400px;height:200px" label-position="left" :label-width="100">
              <FormItem label="商品分类：">
                <Select v-model="formData3.cate_ids" filterable multiple>
                    <Option v-for="item in cityList5" :value="item.id" :key="item.id">{{ item.cate_name }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="shopModal = false">取消</Button>
              <Button class="btn" @click="shopadd">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="TableModal"
          title="设置桌位区域"
          width="500">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="所属区域：">
                <Select v-model="formData1.region_ids" filterable multiple>
                    <Option v-for="item in cityList3" :value="item.id" :key="item.id">{{ item.region_name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="所属桌位：">
                <Select v-model="formData1.desk_ids" filterable multiple>
                    <Option v-for="item in cityList4" :value="item.id" :key="item.id">{{ item.number }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="TableModal = false">取消</Button>
              <Button class="btn" @click="Tableadd">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="PrintModal"
          title="测试打印内容"
          :mask-closable="false"
          width="500"
          @on-cancel="PrintCancel">
          <Form style="width:400px;" label-position="left" :label-width="100">
              <FormItem label="打印内容：">
                  <textarea class="textar" v-model="PrintData.content" placeholder="请输入测试打印机内容" cols="30" rows="10"></textarea>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="PrintCancel">取消</Button>
              <Button class="btn" @click="Printadd">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="PrintingtimesModal"
          title="设置打印次数"
          :mask-closable="false"
          width="500">
          <Form style="width:400px;" label-position="left" :label-width="100">
              <FormItem label="打印次数：">
                <Input v-model="timesData.num" placeholder="请输入打印次数"></Input>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="PrintingtimesModal = false">取消</Button>
              <Button class="btn" @click="timesConfig">确定</Button>
          </div>
      </Modal>

      <Modal
          v-model="printNameListModal"
          title="USB机型选择"
          :mask-closable="false"
          width="500">
          <Form label-position="left" :label-width="100">
              <FormItem label="打印开关：">
                 <i-switch v-model="printNameForm.isable" ></i-switch>
              </FormItem>
              <FormItem label="打印次数：" v-if="printNameForm.isable" style="width:300px">
                <Input v-model="printNameForm.num" placeholder="请输入打印次数"></Input>
              </FormItem>
              <FormItem label="打印机类型：" v-if="printNameForm.isable" >
                <Select v-model="printNameForm.ticket_type" filterable style="width:300px">
                    <Option v-for="(item,index) in ticketdata" :value="item.id" :key="index">{{ item.value }}</Option>
                </Select>
              </FormItem>
              <FormItem label="机型选择：" v-if="printNameForm.isable" >
                <Select v-model="printNameForm.wired_type" filterable style="width:300px">
                    <Option v-for="(item,index) in printNamedata" :value="item" :key="index">{{ item }}</Option>
                </Select>
              </FormItem>
              <FormItem label="纸张宽度："  v-if="printNameForm.isable">
                <Select  v-model="printNameForm.machine_width" style="width:300px">
                    <Option v-for="item in machine_width_list" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="printNameListModal = false">取消</Button>
              <Button class="btn" @click="printNameConfig">确定</Button>
          </div>
      </Modal>
  </div>
</template>

<script>
import a from "../../../../utils/websocket";
import {indexPrinter,addPrinter,editPrinter,delPrinter,modifyPrintStatus,lsDeskArea,lsPosition,setTableArea,getCate,setType,issuePrinter,printTest,setPrintNum,setPrintWiredType} from '@/api/setting'
export default {
  name: "password",
  data() {
    return {
      cardtitle:'新增打印机',
      cardModal:false,
      issueModal:false,
      TableModal:false,
      shopModal:false,
      PrintModal:false,
      printNameListModal:false,
      PrintingtimesModal:false,
      timesData:{
        id:null,
        num:''
      },
      printNamedata:[],
      ticketdata:[
        {id:1,value:'小票打印机'},
        {id:2,value:'标签打印机'},
      ],
      printNameForm:{
        isable:false,
        wired_type:'',
        ticket_type:null,
        num:1
      },
      PrintData:{
        id:null,
        content:''
      },
      total:0,
      storePrams:{
        print_name:'',
        page:1,
        limit:10,
      },
      formData:{
        print_name:'',
        ticket_type:1,
        print_type:null,
        member_code:'',
        api_key:'',
        print_number:'',
        key:''
      },
      formData1:{
        desk_ids:[],
        region_ids:[],
      },
      formData2:{
        order_template:1,
        machine_width:1,
        order_type:[],
      },
      formData3:{
        cate_ids:[],
      },
      PrinterList:[
        {
              value: 1,
              label: '小票打印机'
          },
          {
              value: 2,
              label: '标签打印机'
          },
      ],
      PrinterValue:1,
      xinyeList:[
        {
            value: 1001,
            label: '芯烨打印机'
        }
      ],
      cityList: [
          {
              value: 1,
              label: '佳博打印机'
          },
          {
              value: 2,
              label: '飞鹅打印机'
          },
          {
              value: 1001,
              label: '芯烨打印机'
          },
          {
              value: 4,
              label: '大趋智能打印机'
          }
      ],
      cityList1: [
          {
              value: 1,
              label: '总单'
          },
          {
              value: 2,
              label: '分单'
          },
          {
              value: 3,
              label: '排队取号'
          },
      ],
      machine_width_list: [
          {
              value: 1,
              label: '80MM'
          },
          {
              value: 2,
              label: '58MM'
          }
      ],
      columns12: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '打印机名称',
              key: 'print_name',
              align: 'center'
          },
          {
              title: '打印机类型',
              slot: 'print_type',
              align: 'center'
          },
          {
              title: '打印机编号',
              key: 'print_number',
              align: 'center'
          },
          {
              title: '桌位区域',
              slot: 'zhuo',
              align: 'center'
          },
          {
              title: '出单设置',
              slot: 'type',
              align: 'center'
          },
          {
              title: '打印次数',
              slot: 'num',
              align: 'center'
          },
          {
              title: '打印商品',
              slot: 'shop',
              align: 'center'
          },
          {
              title: '开关',
              width: 160,
              align: 'center',
              slot: 'openswitch'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      cityList3: [],
      cityList4: [],
      cityList5: [],
      data6: []
    }
  },
  created() {
    this.indexPrinter()
    this.lsDeskArea()
    this.lsPosition()
    this.getCate()
  },
  methods: {
    change(e){
        modifyPrintStatus({id:e.id,isable:e.isable}).then(res=>{
          e.isable = e.isable == 1 ? 0 :1;
          this.$Message.success(res.msg)
        }).catch(err=>{
          console.log(err)
        })
    },
    indexPrinter(){
      indexPrinter(this.storePrams).then(res=>{
        this.data6 = res.data.data
        this.total = res.data.total
      })
    },
    lsDeskArea(){
      lsDeskArea().then(res=>{
        res.data.unshift({id:'all',region_name:'所有'})
        this.cityList3 = res.data
      })
    },
    ticketChange(){
      this.formData.print_type = null
    },
    lsPosition(){
      lsPosition().then(res=>{
        res.data.unshift({id:'all',number:'所有'})
        this.cityList4 = res.data
      })
    },
    getCate(){
      getCate().then(res=>{
        this.cityList5 = res.data
      })
    },
    jixingBtn(){
      this.printNameListModal = true
      this.printNamedata = JSON.parse(localStorage.getItem('printNameList'))
      let printNameForm = JSON.parse(localStorage.getItem('printNameForm'))
      console.log(printNameForm)
      this.printNameForm = {
        isable: printNameForm.isable  == 1 ? true : false,
        wired_type:printNameForm.wired_type || "",
        ticket_type:parseInt(printNameForm.ticket_type) || 0,
        num:printNameForm.num || 1,
        machine_width:printNameForm.machine_width||2
      }
    },
    printNameConfig(){
      setPrintWiredType(this.printNameForm).then(res=>{
        this.$Message.success(res.msg)
        localStorage.setItem('printNameForm',JSON.stringify(this.printNameForm))
        this.printNameListModal = false
        a.setPrint()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    Checkbox_change(e){
      if (e.indexOf('4')!=-1) {
        this.formData2.order_type = ['4']
        this.formData2.order_template = 3
      }else{
        this.formData2.order_template = 1
      }
    },
    Select_change(e){
      if (e==3) {
        this.formData2.order_type = ['4']
      }
      if (this.formData2.order_type.indexOf('4')!=-1) {
        this.formData2.order_template = 3
      }
    },
    timesBtn(e){
      this.timesData.id = e.id
      this.timesData.num = e.num
      this.PrintingtimesModal = true
    },
    timesConfig(){
      setPrintNum(this.timesData).then(res=>{
        this.$Message.success(res.msg)
        this.PrintingtimesModal = false
        this.indexPrinter()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    PrintBtn(e){
      this.PrintData.id = e
      this.PrintModal = true
    },
    PrintCancel(){
      this.PrintData.id = ''
      this.PrintData.content = ''
      this.PrintModal = false
    },
    Printadd(){
      printTest(this.PrintData).then(res=>{
        this.$Message.success(res.msg)
        this.PrintModal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    searchBtn(){
      this.indexPrinter()
    },
    cardModalBtn(e){
      if (e=='add') {
        this.cardtitle = '新增打印机'
        this.cardModal = true
      }else{
        this.formData.id = e.id
        this.formData.print_name = e.print_name
        this.formData.print_type = e.print_type
        this.formData.print_number = e.print_number
        this.formData.key = e.key
        this.cardtitle = '编辑打印机'
        this.cardModal = true
      }
    },
    cardCancel(){
      this.clearformData()
      this.cardModal = false
    },
    clearformData(){
      this.formData = {
        print_name:'',
        print_type:'1',
        print_number:'',
        key:''
      }
    },
    cardadd(){
      if (this.cardtitle == '新增打印机') {
        addPrinter(this.formData).then(res=>{
          this.cardModal = false
          this.indexPrinter()
          this.clearformData()
          this.$Message.success(res.msg)
        }).catch(err=>{
          this.indexPrinter()
          this.$Message.error(err.msg)
        })
      }else{
        editPrinter(this.formData).then(res=>{
          this.cardModal = false
          this.indexPrinter()
          this.clearformData()
          this.$Message.success(res.msg)
        }).catch(err=>{
          this.indexPrinter()
          this.$Message.error(err.msg)
        })
      }
    },
    issueBtn(e){
      this.formData2.end_text = e.end_text
      this.formData2.order_type = e.orderTypes
      this.formData2.ticket_type = e.ticket_type
      this.formData2.order_template = e.order_template
      this.formData2.machine_width = e.machine_width
      this.formData2.id = e.id
      this.issueModal = true
    },
    issueadd(){
      issuePrinter(this.formData2).then(res=>{
        this.$Message.success(res.msg)
        this.indexPrinter()
        this.issueModal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    TableBtn(e){
      this.formData1.id = e.id
      if (e.desks.length!=0 && e.desks.indexOf('all')==-1) {
        this.formData1.desk_ids = e.desks.map(Number)
      }else{
        this.formData1.desk_ids = e.desks
      }
      if (e.regions.length!=0 && e.regions.indexOf('all')==-1) {
        this.formData1.region_ids = e.regions.map(Number)
      }else{
        this.formData1.region_ids = e.regions
      }
      this.TableModal = true
    },
    Tableadd(){
      setTableArea(this.formData1).then(res=>{
        this.$Message.success(res.msg)
        this.indexPrinter()
        this.TableModal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    shopBtn(e){
      this.formData3.id = e.id
      this.formData3.cate_ids = e.cates.map(Number)
      this.shopModal = true
    },
    shopadd(){
      setType(this.formData3).then(res=>{
        this.$Message.success(res.msg)
        this.indexPrinter()
        this.shopModal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    pageChange(index){
      this.storePrams.page = index
      this.indexPrinter()
    },
    delBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delPrinter({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexPrinter()
          }).catch(err=>{
            this.indexPrinter()
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    backBtn(){
        this.$emit('backview',0)
    }
  }
}
</script>

<style scoped>
>>>.ivu-form-item{
  margin-top:14px
}
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.search_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textar{
  outline: none;
  width: 360px;
  border: none;
  background: #F8F8F8;
  padding: 0 10px;
  box-sizing: border-box;
  max-height: 200px;
  min-height: 200px;
}
.titile_tishi_box{
  width: 100%;
  margin-top: 20px;
  padding: 10px 30px;
  background: #FFF0E7;
}
.title_txt{
  font-weight: 600;
  font-size: 18px;
}
</style>
<style>
  .ivu-checkbox-checked .ivu-checkbox-inner{
    border-color: #FF761D;
    background-color: #FF761D;
  }
</style>