<template>
  <div>
    <div v-if="Select == 0" class="son-page">
      <div @click="menuclick(item)" :style="'background:url('+item.icon+') no-repeat 100% 100%;'" v-for="(item,index) of menus" :key="index" class="item_box">
        <div>{{item.title}}</div>
      </div>
    </div>
    <div v-if="Select == 'basicsettings'"><Basicsettings @backview="backview"></Basicsettings></div>
    <div v-if="Select == 'tablemanagement'"><Tablemanagement @backview="backview"></Tablemanagement></div>
    <div v-if="Select == 'printer'"><Printer @backview="backview"></Printer></div>
    <div v-if="Select == 'WeChat'"><Wx @backview="backview"></Wx></div>
    <div v-if="Select == 'Alipay'"><Zfb @backview="backview"></Zfb></div>
    <div v-if="Select == 'Cardvolumemanagement'"><Card @backview="backview"></Card></div>
    <div v-if="Select == 'Accountmanagement'"><Account @backview="backview"></Account></div>
    <div v-if="Select == 'cloudstorage'"><Cloudstorage @backview="backview"></Cloudstorage></div>
    <div v-if="Select == 'meituan'"><Meituan @backview="backview"></Meituan></div>
    <div v-if="Select == 'Pagemanagement'"><Pagemanagement @backview="backview"></Pagemanagement></div>
  </div>
</template>

<script>
import Basicsettings from './components/basicsettings'
import Tablemanagement from './components/tablemanagement'
import Printer from './components/printer'
import Wx from './components/wx'
import Zfb from './components/zfb'
import Card from './components/card'
import Account from './components/account'
import Cloudstorage from './components/cloudstorage'
import Meituan from './components/meituan'
import Pagemanagement from './components/pagemanagement'
// import {getCookies} from '@/utils/helper'
export default {
  components: {Basicsettings,Tablemanagement,Printer,Wx,Zfb,Card,Account,Cloudstorage,Meituan,Pagemanagement},
  name: "password",
  data() {
    return {
      Select:0,
      menus:[]
    }
  },
  created() {
    let menu = this.$store.state.menu
    menu.forEach(item => {
      if (item.id==17) {
        item.children.forEach(j=>{
          if (j.id==37) {
            this.menus = j.children
          }
        })
      }
    });
  },
  methods: {
    menuclick(e){
      // if (e.title=='收银') {
      //   // console.log('哈哈 收银！！！')
      //   let url = `${location.origin}/cash/#/?token=${getCookies('hlzw_canyin_token')}`
      //   // let url = 'https://www.baidu.com/?token=' +getCookies('hlzw_canyin_token')
      //   window.open( url , '_blank' )
      // }else{
        this.Select = e.src
      // }
    },
    backview(e){
      this.Select = e
    },
  }
}
</script>

<style scoped>
.son-page{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.item_box{
  cursor: pointer;
  width: 23%;
  height: 100px;
  border-radius: 12px;
  background: #fff;
  margin: 10px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
