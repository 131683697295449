<template>
    <div>
        <!-- formData.type == 1 banner-->
        <bannerStyle
            :formData="formData"
            v-if="formData.type == 1"
            :actionList="actionList"
            :pageList="pageList"
            :shopList="shopList"
        ></bannerStyle>

        <!-- formData.type == 2 图片组件-->
        <picStyle
            v-if="formData.type == 2"
            :formData="formData"
            :modify-hot.sync="hopsot_name"
        >
            <!-- slot -->
            <FormItem label="热区划分">
                <Button @click="openDialog">{{ hopsot_name }}</Button>
                <HotSpot
                    v-if="dialog_modal"
                    :dialog_modal.sync="dialog_modal"
                    :formData="formData"
                    :actionList="actionList"
                    :pageList="pageList"
                    :shopList="shopList"
                    @updateHotspot="updateHotspot"
                ></HotSpot>
            </FormItem>
        </picStyle>

        <!-- formData.type == 3 商品推荐-->
        <goodsStyle v-if="formData.type == 3" :formData="formData"></goodsStyle>

        <!-- formData.type == 4 文字组件-->
        <textStyle
            v-if="formData.type == 4"
            :formData="formData"
            :familyList="familyList"
        ></textStyle>

        <!--formData.type==5 宫格组件 -->
        <gridStyle
            :formData="formData"
            v-if="formData.type == 5"
            :actionList="actionList"
            :pageList="pageList"
            :shopList="shopList"
        ></gridStyle>

        <!-- formData.type == 6 公告组件-->
        <noticeStyle
            v-if="formData.type == 6"
            :formData="formData"
        ></noticeStyle>

        <!-- formData.type == 7 搜索组件-->
        <searchStyle
            v-if="formData.type == 7"
            :formData="formData"
        ></searchStyle>

        <!-- formData.type == 8 辅助空白-->
        <assistStyle
            v-if="formData.type == 8"
            :formData="formData"
        ></assistStyle>

        <!-- formData.type == 9 辅助线-->
        <guideStyle v-if="formData.type == 9" :formData="formData"></guideStyle>

        <!-- formData.type == 10 店铺信息-->
        <shopStyle
            v-if="formData.type == 10"
            :formData="formData"
            :templateList="templateList"
        ></shopStyle>
    </div>
</template>

<script>
import { getDiyGoods } from "@/api/setting";
import HotSpot from "@/components/diy/HotSpot/HotSpot.vue";
import bannerStyle from "@/components/diy/bannerStyle.vue";
import goodsStyle from "@/components/diy/goodsStyle.vue";
import textStyle from "@/components/diy/textStyle.vue";
import gridStyle from "@/components/diy/gridStyle.vue";
import noticeStyle from "@/components/diy/noticeStyle.vue";
import searchStyle from "@/components/diy/searchStyle.vue";
import assistStyle from "@/components/diy/assistStyle.vue";
import guideStyle from "@/components/diy/guideStyle.vue";
import shopStyle from "@/components/diy/shopStyle.vue";
import picStyle from "@/components/diy/picStyle.vue";
export default {
    components: {
        HotSpot,
        bannerStyle,
        goodsStyle,
        textStyle,
        gridStyle,
        noticeStyle,
        searchStyle,
        assistStyle,
        guideStyle,
        shopStyle,
        picStyle,
    },
    data() {
        return {
            formData: {},
            color1: "#19be6b",
            actionList: [
                { value: 1, label: "跳转页面" },
                { value: 2, label: "跳转商品" },
                { value: 3, label: "扫一扫" },
            ],
            familyList: [
                { value: "unset", label: "默认" },
                { value: "serif", label: "衬线" },
                { value: "cursive", label: "草书" },
                { value: "fangsong", label: "仿宋" },
            ],
            pageList: [
                { value: 1, label: "积分商城" },
                { value: 2, label: "外卖选购" },
                { value: 3, label: "自取选购" },
                { value: 4, label: "充值页" },
                { value: 5, label: "当面付" },
            ],
            templateList: [
                { value: 1, label: "模板1" },
                { value: 2, label: "模板2" },
            ],
            shopList: [],
            dialog_modal: false,
            hopsot_name: "划分热区",
        };
    },
    mounted() {
        this.getDiyGoods();
    },
    methods: {
        // 获取商品列表
        getDiyGoods() {
            getDiyGoods().then((res) => {
                this.shopList = res.data;
            });
        },
        //传参
        setdata(e) {
            this.formData = e;
            if (
                this.formData &&
                this.formData.content &&
                typeof this.formData.content.hotspot !== "undefined" &&
                this.formData.content.hotspot.length > 0
            ) {
                this.hopsot_name = "已划分";
            } else {
                this.hopsot_name = "划分热区";
            }
            this.$forceUpdate();
        },
        inputChange(e) {
            this.$emit("getReply", this.formData);
            if (e > 9999) {
                e = Number(9999);
                this.$nextTick(() => {
                    this.formData.content.z_index = Number(9999);
                });
            }
        },
        openDialog() {
            this.dialog_modal = true;
            this.$forceUpdate();
        },
        updateHotspot(e) {
            this.formData = e;
            if (
                typeof this.formData.content.hotspot == "undefined" ||
                this.formData.content.hotspot.length == 0
            ) {
                this.hopsot_name = "划分热区";
            } else {
                this.hopsot_name = "已划分";
            }
            this.$emit("updateAll", e);
        },
    },
};
</script>

<style scoped>
.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.default {
    width: 20%;
    margin: 10px;
    border: 1px solid #eee;
    text-align: center;
}

.active {
    border: 1px solid #409eff;
}
.activeP {
    color: #409eff;
}
</style>

<style>
.ivu-input {
    border: none;
    background: #f8f8f8;
}

.ivu-slider-bar {
    background: #ff761d;
}

.ivu-slider-button {
    border-color: #ff761d;
}
</style>