<template>
  <div>
    <div>
      <!-- formData.type == 9 辅助线-->
      <div class="style_box">
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />辅助线编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem style="margin: 0 0 10px 0" label="背景色">
                <ColorPicker v-model="formData.content.bgColor" />
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="线条样式">
                <div class="border_box">
                  <div
                    @click="lineBtn('solid')"
                    :style="
                      formData.content.border == 'solid'
                        ? 'border-color:#FF761D'
                        : ''
                    "
                    class="border_style wei"
                  >
                    <div class="solid"></div>
                  </div>
                  <div
                    @click="lineBtn('double')"
                    :style="
                      formData.content.border == 'double'
                        ? 'border-color:#FF761D'
                        : ''
                    "
                    class="border_style wei"
                  >
                    <div class="double"></div>
                  </div>
                  <div
                    @click="lineBtn('dashed')"
                    :style="
                      formData.content.border == 'dashed'
                        ? 'border-color:#FF761D'
                        : ''
                    "
                    class="border_style wei"
                  >
                    <div class="dashed"></div>
                  </div>
                  <div
                    @click="lineBtn('dotted')"
                    :style="
                      formData.content.border == 'dotted'
                        ? 'border-color:#FF761D'
                        : ''
                    "
                    class="border_style wei"
                  >
                    <div class="dotted"></div>
                  </div>
                </div>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="上下间距">
                <div>
                  <Slider
                    v-model="formData.content.paddingData.topBottom"
                  ></Slider>
                </div>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="左右间距">
                <div>
                  <Slider
                    v-model="formData.content.paddingData.leftRight"
                  ></Slider>
                </div>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // 线条选择
    lineBtn(e) {
      this.formData.content.border = e;
    },
  },
};
</script>

<style scoped>
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.border_box {
  display: flex;
  border-radius: 5px;
  height: 34px;
}
.border_style {
  width: 25%;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
}

.wei {
  border: 1px solid #999;
}
.double {
  width: 100%;
  border-top-width: 3px;
  border-top-style: double;
  border-color: #999;
}
.dashed {
  width: 100%;
  border-top: 1px dashed #999;
}
.dotted {
  width: 100%;
  border-top: 1px dotted #999;
}
.solid {
  width: 100%;
  border-top: 1px solid #999;
}
.border_box div:nth-of-type(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.border_box div:nth-of-type(4) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>