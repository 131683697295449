<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">桌位管理</div>
      </div>
      <div class="content_box">
        <Tabs value="1" @on-click="changeTab">
          <TabPane label="桌位管理" name="1"></TabPane>
          <TabPane label="区域管理" name="2"></TabPane>
          <TabPane label="桌位类型" name="3"></TabPane>
        </Tabs>
        <div v-if="index=='1'">
          <div class="search_box">
            <Form style="margin-right:20px" inline label-position="left" :label-width="20">
                <FormItem label="">
                  <Button @click="TableBtn('add')" class="btn">新增桌位</Button>
                </FormItem>
                <FormItem label="">
                    <Button @click="batchBtn" class="btn">批量新增</Button>
                </FormItem>
                <FormItem label="">
                    <Button @click="resetBtn" class="btn">一键重置二维码</Button>
                </FormItem>
                <FormItem label="">
                    <Button @click="downloadAll" class="btn">一键下载桌位码</Button>
                </FormItem>
            </Form>
            <Form inline label-position="left" :label-width="70">
                <FormItem label="所属区域">
                    <Select v-model="storePrams.region_id" style="width:200px">
                        <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.region_name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="桌位类型">
                    <Select v-model="storePrams.type_id" style="width:200px">
                        <Option v-for="item in cityList1" :value="item.id" :key="item.id">{{ item.type_name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="">
                    <Button @click="searchBtn">搜索</Button>
                </FormItem>
            </Form>
          </div>
          <Table @on-selection-change="selectionChange" ref="selection" stripe style="margin-top:20px" :columns="columns1" :data="data1">
              <template slot-scope="{ index }" slot="index">
                  <strong>{{ index+1 }}</strong>
              </template>
              <template slot-scope="{ row }" slot="code">
                <Button @click="xiazai(row.id)">下载</Button>
              </template>
              <template slot-scope="{ row }" slot="action">
                  <Button type="primary" @click="TableBtn(row)" ghost size="small" style="margin-right: 5px;background:none;border-color:#3F96F1">编辑</Button>
                  <Button type="error" @click="delBtn(row.id)" ghost size="small">删除</Button>
              </template>
          </Table>
          <br/>
          <div class="flex_center">
            <div><Button @click="Batchdelete" type="error">批量删除</Button></div>
            <Page :total="total"
                :current="storePrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange"
                :page-size="storePrams.limit"/>
          </div>
        </div>
        <div v-if="index=='2'">
          <div class="search_box">
            <Form style="margin-right:20px" inline label-position="left" :label-width="20">
                <FormItem label="">
                  <Button @click="regionBtn('add')" class="btn">新增区域</Button>
                </FormItem>
            </Form>
            <Form inline label-position="left" :label-width="70">
                <FormItem label="">
                    <Input v-model="storePrams1.region_name" placeholder="请输入"></Input>
                </FormItem>
                <FormItem label="">
                    <Button @click="searchBtn1">搜索</Button>
                </FormItem>
            </Form>
          </div>
          <Table stripe style="margin-top:20px" :columns="columns2" :data="data2">
              <template slot-scope="{ index }" slot="index">
                  <strong>{{ index+1 }}</strong>
              </template>
              <template slot-scope="{ row }" slot="action">
                  <Button type="primary" @click="regionBtn(row)" ghost size="small" style="margin-right: 5px;background:none;border-color:#3F96F1">编辑</Button>
                  <Button type="error" @click="delBtn1(row.id)" ghost size="small">删除</Button>
              </template>
          </Table>
          <br/>
          <div class="flex_center1">
            <!-- <div><Button type="error">批量删除</Button></div> -->
            <Page :total="total1"
                :current="storePrams1.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange1"
                :page-size="storePrams1.limit"/>
          </div>
        </div>
        <div v-if="index=='3'">
          <div class="search_box">
            <Form style="margin-right:20px" inline label-position="left" :label-width="20">
                <FormItem label="">
                  <Button @click="typeBtn('add')" class="btn">新增类型</Button>
                </FormItem>
            </Form>
            <Form inline label-position="left" :label-width="70">
                <FormItem label="">
                    <Input v-model="storePrams2.type_name" placeholder="请输入"></Input>
                </FormItem>
                <FormItem label="">
                    <Button @click="searchBtn2">搜索</Button>
                </FormItem>
            </Form>
          </div>
          <Table stripe style="margin-top:20px" :columns="columns3" :data="data3">
              <template slot-scope="{ index }" slot="index">
                  <strong>{{ index+1 }}</strong>
              </template>
              <template slot-scope="{ row }" slot="ren">
                  <div>{{ row.min }} - {{ row.max }}</div>
              </template>
              <template slot-scope="{ row }" slot="action">
                  <Button type="primary" @click="typeBtn(row)" ghost size="small" style="margin-right: 5px;background:none;border-color:#3F96F1">编辑</Button>
                  <Button type="error" @click="delBtn2(row.id)" ghost size="small">删除</Button>
              </template>
          </Table>
          <br/>
          <div class="flex_center1">
            <!-- <div><Button type="error">批量删除</Button></div> -->
            <Page :total="total2"
                :current="storePrams2.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange2"
                :page-size="storePrams2.limit"/>
          </div>
        </div>

      </div>

      <Modal
          v-model="TableModal"
          :title="Tabletitle"
          width="500"
          @on-cancel="TableCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="桌位编号：">
                  <Input placeholder="请输入桌位编号" v-model="formData.number"></Input>
              </FormItem>
              <FormItem label="所属区域：">
                  <Select v-model="formData.region_id">
                      <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.region_name }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="桌位类型：">
                  <Select v-model="formData.type_id">
                      <Option v-for="item in cityList1" :value="item.id" :key="item.id">{{ item.type_name }}</Option>
                  </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="TableCancel">取消</Button>
              <Button class="btn" @click="Tableadd">保存</Button>
          </div>
      </Modal>

      <Modal
          v-model="batchModal"
          title="批量新增"
          width="500"
          @on-cancel="batchCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="所属区域：">
                  <Select v-model="formData1.region_id">
                      <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.region_name }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="桌位类型：">
                  <Select v-model="formData1.type_id">
                      <Option v-for="item in cityList1" :value="item.id" :key="item.id">{{ item.type_name }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="桌位前缀：">
                  <Input placeholder="请输入桌位前缀" v-model="formData1.prefix"></Input>
              </FormItem>
              <FormItem label="桌位序号：">
                  <div class="flex_center">
                    <Input placeholder="开始序号" v-model="formData1.stat_serial"></Input>
                    <div style="color:#DCDEE2;padding:0 20px">——</div>
                    <Input placeholder="结束序号" v-model="formData1.end_serial"></Input>
                  </div>
                  例：桌位前缀为A00，序号为1-4，将批量添加4个A桌，桌位名称分别为：A001，A002，A003，A004
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="batchCancel">取消</Button>
              <Button class="btn" @click="batchadd">保存</Button>
          </div>
      </Modal>

      <Modal
          v-model="regionModal"
          :title="regiontitle"
          width="500"
          @on-cancel="regionCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="区域名称：">
                  <Input placeholder="请输入区域名称" v-model="formData2.region_name"></Input>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="regionCancel">取消</Button>
              <Button class="btn" @click="regionadd">保存</Button>
          </div>
      </Modal>

      <Modal
          v-model="typeModal"
          :title="typetitle"
          width="500"
          @on-cancel="typeCancel">
          <Form style="width:400px" label-position="left" :label-width="100">
              <FormItem label="类型名称：">
                  <Input placeholder="请输入类型名称" v-model="formData3.type_name"></Input>
              </FormItem>
              <FormItem label="最少人数：">
                  <Input placeholder="请输入最少人数" v-model="formData3.min"></Input>
              </FormItem>
              <FormItem label="最多人数：">
                  <Input placeholder="请输入最多人数" v-model="formData3.max"></Input>
              </FormItem>
              <FormItem label="前缀：">
                  <Input placeholder="请输入前缀" v-model="formData3.prefix"></Input>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="typeCancel">取消</Button>
              <Button class="btn" @click="typeadd">保存</Button>
          </div>
      </Modal>
  </div>
</template>

<script>
import {indexDeskType,addDeskType,editDeskType,delDeskType,indexDeskArea,addDeskArea,editDeskArea,delDeskArea,indexPosition,lsDeskType,lsDeskArea,addPosition,editPosition,delPosition,AddsPosition,setDeskCode} from '@/api/setting'
import config from '@/config';
import {getCookies} from '@/utils/helper'
export default {
  name: "password",
  data() {
    return {
      header:{
          
          'hlzw-canyin-authori-zation':getCookies('hlzw_canyin_token')
      },
      uploads: config.apiUrl + '/storeapi/data/uploads',
      index:'1',
      Tabletitle:'新增桌位',
      regiontitle:'新增区域',
      typetitle:'新增类型',
      TableModal:false,
      batchModal:false,
      regionModal:false,
      typeModal:false,
      delID:[],
      total:0,
      total1:0,
      total2:0,
      storePrams:{
        type_id:'',
        region_id:'',
        page:1,
        limit:10,
      },
      storePrams1:{
        region_name:'',
        page:1,
        limit:10,
      },
      storePrams2:{
        type_name:'',
        page:1,
        limit:10,
      },
      formData:{
        number:'',
        region_id:'',
        type_id:'',
      },
      formData1:{
        prefix:'',
        region_id:'',
        type_id:'',
        stat_serial:'',
        end_serial:'',
      },
      formData2:{
        region_name:'',
      },
      formData3:{
        type_name:'',
        min:'',
        max:'',
        prefix:''
      },
      cityList: [],
      cityList1: [],
      columns1: [
          {
              type: 'selection',
              width: 60,
              align: 'center'
          },
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '所属区域',
              key: 'region_name',
              align: 'center'
          },
          {
              title: '桌位类型',
              key: 'type_name',
              align: 'center'
          },
          {
              title: '桌位编号',
              key: 'number',
              align: 'center'
          },
          {
              title: '二维码',
              slot: 'code',
              align: 'center'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      data1: [],
      columns2: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '区域名称',
              key: 'region_name',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      data2: [],
      columns3: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '前缀',
              key: 'prefix',
              align: 'center'
          },
          {
              title: '桌位类型',
              key: 'type_name',
              align: 'center'
          },
          {
              title: '人数区间',
              slot: 'ren',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      data3: []
    }
  },
  created() {
    this.indexPosition()
    this.lsDeskType()
    this.lsDeskArea()
  },
  methods: {
    indexDeskType(){
      indexDeskType(this.storePrams2).then(res=>{
        this.total2 = res.data.total
        this.data3 = res.data.data
      })
    },
    indexDeskArea(){
      indexDeskArea(this.storePrams1).then(res=>{
        this.total1 = res.data.total
        this.data2 = res.data.data
      })
    },
    indexPosition(){
      indexPosition(this.storePrams).then(res=>{
        this.data1 = res.data.data
        this.total = res.data.total
      })
    },
    xiazai(id){
      location.href = config.apiUrl+'/storeapi/qrcode_download?id='+id
    },
    lsDeskType(){
      lsDeskType().then(res=>{
        this.cityList1 = res.data
      })
    },
    lsDeskArea(){
      lsDeskArea().then(res=>{
        this.cityList = res.data
      })
    },
    // 一键下载所有桌位码
    downloadAll(){
      location.href = `${config.apiUrl}/storeapi/apply/setBatchDesk?data_id=${this.$store.state.userInfo.data_id}`
    },
    TableBtn(e){
      if (e=='add') {
        this.Tabletitle = '新增桌位'
        this.TableModal = true
      }else{
        this.formData.id = e.id
        this.formData.number = e.number
        this.formData.region_id = e.region_id
        this.formData.type_id = e.type_id
        this.Tabletitle = '编辑桌位'
        this.TableModal = true
      }
    },
    TableCancel(){
      this.clearformData()
      this.TableModal = false
    },
    Tableadd(){
      if (this.Tabletitle == '新增桌位') {
        addPosition(this.formData).then(res=>{
          this.$Message.success(res.msg)
          this.indexPosition()
          this.TableModal = false
          this.clearformData()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        editPosition(this.formData).then(res=>{
          this.$Message.success(res.msg)
          this.indexPosition()
          this.TableModal = false
          this.clearformData()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }
    },
    batchBtn(){
      this.batchModal = true
    },
    resetBtn(){
        this.$Modal.confirm({
            title: '提示',
            content: `您正在使用一键重置二维码功能`,
            onOk: () => {
              setDeskCode().then(res=>{
                this.$Message.success(res.msg)
                this.indexPosition()
              }).catch(err=>{
                this.$Message.error(err.msg)
              })
            },
            onCancel: () => {
            }
        }); 
    },
    batchCancel(){
      this.clearformData1()
      this.batchModal = false
    },
    batchadd(){
      AddsPosition(this.formData1).then(res=>{
        this.$Message.success(res.msg)
        this.indexPosition()
        this.batchModal = false
        this.clearformData1()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    regionBtn(e){
      if (e=='add') {
        this.regiontitle = '新增区域'
        this.regionModal = true
      }else{
        this.formData2.id = e.id
        this.formData2.region_name = e.region_name
        this.regiontitle = '编辑区域'
        this.regionModal = true
      }
    },
    regionCancel(){
      this.clearformData2()
      this.regionModal = false
    },
    regionadd(){
      if (this.regiontitle == '新增区域') {
        addDeskArea(this.formData2).then(res=>{
          this.$Message.success(res.msg)
          this.indexDeskArea()
          this.regionModal = false
          this.clearformData2()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        editDeskArea(this.formData2).then(res=>{
          this.$Message.success(res.msg)
          this.indexDeskArea()
          this.regionModal = false
          this.clearformData2()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }
    },
    typeBtn(e){
      if (e=='add') {
        this.typetitle = '新增类型'
        this.typeModal = true
      }else{
        this.formData3.id = e.id
        this.formData3.type_name = e.type_name
        this.formData3.min = e.min
        this.formData3.max = e.max
        this.formData3.prefix = e.prefix
        this.typetitle = '编辑类型'
        this.typeModal = true
      }
    },
    typeCancel(){
      this.clearformData3()
      this.typeModal = false
    },
    typeadd(){
      if (this.typetitle == '新增类型') {
        addDeskType(this.formData3).then(res=>{
          this.$Message.success(res.msg)
          this.indexDeskType()
          this.typeModal = false
          this.clearformData3()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        editDeskType(this.formData3).then(res=>{
          this.$Message.success(res.msg)
          this.indexDeskType()
          this.typeModal = false
          this.clearformData3()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }
    },
    clearformData(){
      this.formData = {
        number:'',
        region_id:'',
        type_id:''
      }
    },
    clearformData1(){
      this.formData1 = {
        prefix:'',
        region_id:'',
        type_id:'',
        stat_serial:'',
        end_serial:'',
      }
    },
    clearformData2(){
      this.formData2 = {
        region_name:''
      }
    },
    clearformData3(){
      this.formData3 = {
        type_name:'',
        min:'',
        max:'',
      }
    },
    searchBtn(){
      this.indexPosition()
    },
    searchBtn1(){
      this.indexDeskArea()
    },
    searchBtn2(){
      this.indexDeskType()
    },
    Batchdelete(){
      if (this.delID.length!=0) {
        this.$Modal.confirm({
          title: '提示',
          content: `您正在使用批量删除功能`,
          onOk: () => {
            delPosition({id:this.delID}).then(res=>{
              this.$Message.success(res.msg)
              this.indexPosition()
            }).catch(err=>{
              this.$Message.error(err.msg)
            })
          },
          onCancel: () => {
          }
        }); 
      }
    },
    delBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delPosition({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexPosition()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    delBtn1(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delDeskArea({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexDeskArea()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    delBtn2(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delDeskType({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexDeskType()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    changeTab(e){
      if (e==3) {
        this.indexDeskType()
      }else if(e==2){
        this.indexDeskArea()
      }else if(e==1){
        this.lsDeskType()
        this.lsDeskArea()
        this.indexPosition()
      }
      this.index = e
    },
    selectionChange(row){
        // console.log(row);
        this.delID = []
        row.forEach(item=>{
            // arr = [];
            this.delID.push(item.id)
        })
    },
    //文件上传类型错误
    handleFormatError () {
        this.$Message.warning('暂不支持上传此格式');
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e){
        // this.imgview = e.data.path
        // this.formdata.level_img = e.data.src
        this.$Message.destroy()
        this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress(){
        const msg = this.$Message.loading({
            content: '上传中...',
            duration: 0
        });
        setTimeout(msg, 3000);
    },
    pageChange(index){
      this.storePrams.page = index
      this.indexPosition()
    },
    pageChange1(index){
      this.storePrams1.page = index
      this.indexDeskArea()
    },
    pageChange2(index){
      this.storePrams2.page = index
      this.indexDeskType()
    },
    backBtn(){
        this.$emit('backview',0)
    },
  }
}
</script>

<style scoped>
.son-page{
  position: relative;
}
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.flex_text{
  display: flex;
  align-items: center;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.search_box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_center1{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
<style>
  .ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
