<template>
  <div class="son-page">
    <div @click="backBtn" class="back_box">
      <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
      <div class="ml10">基本设置</div>
    </div>
    <div class="content_box">
      <!-- 店铺设置 -->
      <div class="title_txt">店铺设置</div>
      <Form label-position="right" :label-width="210">
        <FormItem label="开屏广告：">
          <i-switch
            v-model="formData.is_screen"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
        <div style="margin-left: 80px" v-if="formData.is_screen == 1">
          <FormItem label="开屏图片：">
            <div style="display: flex">
              <div @click="openModal(1)">
                <!-- <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="carduploadImgSuccess"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads"> -->
                <div class="uplads_box">
                  <div style="text-align: center">
                    <div style="margin-top: -10px">
                      <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                    </div>
                    <div
                      class="font14"
                      style="margin-top: -10px; color: #9ca7b9"
                    >
                      点击上传
                    </div>
                    <div
                      style="font-size: 12px; color: #9ca7b9; margin-top: -15px"
                    >
                      （375*812）
                    </div>
                  </div>
                </div>
                <!-- </Upload> -->
              </div>
              <imageLibrary
                v-if="switch_modal"
                :switch_modal.sync="switch_modal"
                @change="costPlannedAmountChange"
                :select="imgtype"
              ></imageLibrary>
              <div v-if="new_card_img" class="img_view_Box">
                <img :src="new_card_img" alt="" />
              </div>
            </div>
          </FormItem>
          <FormItem label="广告时间：">
            <Input
              v-model="formData.screen_time"
              style="width: 400px"
              placeholder="请输入跳转等待时间"
            ></Input>
          </FormItem>
        </div>
        <FormItem label="登录授权手机号：">
          <i-switch
            v-model="formData.phone_bind_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="登录时授权手机号"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
        <FormItem label="充值功能：">
          <i-switch
            v-model="formData.is_recharge"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
        <FormItem label="客服功能：">
          <i-switch
            v-model="formData.is_kefu"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
        <FormItem label="商品自动发货：" v-if="formData.show_upload_shipping">
          <i-switch
            v-model="formData.is_upload_shipping"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="若微信小程序被判定需要进行发货，则建议开启自动发货功能，订单结单后，会定时进行发货提交到微信小程序平台"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
      </Form>
      <!-- 店铺设置 end -->
      <div class="title_txt">订单设置</div>
      <!-- 堂食订单设置 -->
      <Form label-position="right" :label-width="210">
        <div class="xiao_title"><h3>堂食订单</h3></div>
        <FormItem label="接单方式：">
          <RadioGroup
            :true-value="1"
            :false-value="2"
            v-model="formData.t_pay_type"
          >
            <Radio :label="1">付款后手动接单</Radio>
            <Radio :label="2">不付款自动接单</Radio>
            <Radio :label="3">付款后自动接单</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem v-if="formData.t_pay_type == 2" label="关闭支付按钮：">
          <i-switch
            v-model="formData.t_close_pay"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            content="开启后，客户无需支付即可完成下单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
        <FormItem label="（用户端）接单后可申请取消：">
          <i-switch
            v-model="formData.t_order_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户多少分钟内，可申请取消"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem v-if="formData.t_order_cancel_status" label="">
            <Input
              v-model="formData.t_order_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟内，可申请取消
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.t_pay_type != 2"
          label="（用户端）自动取消订单："
        >
          <i-switch
            v-model="formData.t_not_pay_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户超时未付款，将会自动取消订单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem v-if="formData.t_not_pay_cancel_status" label="">
            <Input
              v-model="formData.t_not_pay_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟后自动取消订单
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.t_pay_type == 1"
          label="（商家端）自动关闭订单："
        >
          <i-switch
            v-model="formData.t_is_close_order"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未接单，将会自动关闭订单且退款"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.t_is_close_order == 1">
            <div class="flex_center">
              <Input
                v-model="formData.t_close_min"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动关闭订单</div>
            </div>
            <!-- <div class="color_999">仅支持外卖订单与自取订单</div> -->
          </div>
        </FormItem>
        <FormItem label="打印设置：">
          <CheckboxGroup v-model="formData.t_print_mode">
            <Checkbox style="margin-right: 30px" :label="1"
              >接单即打印</Checkbox
            >
            <Checkbox :label="2">付款即打印</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <!-- 堂食订单设置 end -->
        <!-- 自取订单设置 -->
        <div class="xiao_title"><h3>自取订单</h3></div>
        <FormItem label="接单方式：">
          <RadioGroup
            :true-value="1"
            :false-value="2"
            v-model="formData.z_receive_type"
          >
            <Radio :label="1">付款后自动接单</Radio>
            <Radio :label="2">付款后手动接单</Radio>
            <Radio :label="3">不付款自动接单</Radio>
            <Radio :label="4">不付款手动接单</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="就餐模式">
          <CheckboxGroup v-model="formData.z_mode">
            <Checkbox style="margin-right: 30px" :label="1">店内就餐</Checkbox>
            <Checkbox :label="2">外带打包</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="取餐码：">
          <i-switch
            @on-change="z_codeChange"
            v-model="formData.z_code"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
        <FormItem v-if="formData.z_code" label="取餐时是否需要验证：">
          <i-switch
            v-model="formData.z_check"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="开启后，取餐时需要输入取餐码进行验证"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
        <FormItem label="用户预留信息：">
          <i-switch
            v-model="formData.z_user_info_status"
            :true-value="1"
            :false-value="0"
          />
          <FormItem v-if="formData.z_user_info_status" label="">
            <CheckboxGroup v-model="formData.z_user_info">
              <Checkbox style="margin-right: 30px" :label="1">手机号</Checkbox>
              <Checkbox :label="2">姓名</Checkbox>
            </CheckboxGroup>
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.z_receive_type == 3 || formData.z_receive_type == 4"
          label="关闭支付按钮："
        >
          <i-switch
            v-model="formData.z_close_pay"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            content="开启后，客户无需支付即可完成下单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
        <FormItem label="（用户端）接单后可申请取消：">
          <i-switch
            v-model="formData.z_order_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户多少分钟内，可申请取消"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem v-if="formData.z_order_cancel_status" label="">
            <Input
              v-model="formData.z_order_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟内，可申请取消
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.z_receive_type == 1 || formData.z_receive_type == 2"
          label="（用户端）自动取消订单："
        >
          <i-switch
            v-model="formData.z_not_pay_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户超时未付款，将会自动取消订单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem v-if="formData.z_not_pay_cancel_status" label="">
            <Input
              v-model="formData.z_not_pay_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟后自动取消订单
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.z_receive_type == 2 || formData.z_receive_type == 4"
          label="（商家端）自动关闭订单："
        >
          <i-switch
            v-model="formData.z_is_close_order"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未接单，将会自动关闭订单且退款"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.z_is_close_order == 1">
            <div class="flex_center">
              <Input
                v-model="formData.z_close_min"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动关闭订单</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="（商家端）自动触发请取餐：">
          <i-switch
            v-model="formData.z_auto_pick"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未点击请取餐按钮，将会自动触发请取餐功能"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.z_auto_pick == 1">
            <div class="flex_center">
              <Input
                v-model="formData.z_auto_pick_time"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动触发请取餐功能</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="（商家端）自动触发结单：">
          <i-switch
            v-model="formData.z_auto_checkout"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未点击结单按钮，将会自动触发结单功能"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.z_auto_checkout == 1">
            <div class="flex_center">
              <Input
                v-model="formData.z_auto_checkout_time"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动触发结单功能</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="打印设置：">
          <CheckboxGroup v-model="formData.z_print_mode">
            <Checkbox style="margin-right: 30px" :label="1"
              >接单即打印</Checkbox
            >
            <Checkbox :label="2">付款即打印</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="自取协议：">
          <Input
            class="textar"
            type="textarea"
            v-model="formData.z_desc"
            placeholder="请输入自取协议"
          ></Input>
        </FormItem>
        <!-- 自取订单设置 end -->
        <!-- 外卖订单设置 -->
        <div class="xiao_title"><h3>外卖订单</h3></div>
        <FormItem label="接单方式：">
          <RadioGroup
            :true-value="1"
            :false-value="2"
            v-model="formData.receive_type"
          >
            <Radio :label="1">付款后自动接单</Radio>
            <Radio :label="2">付款后手动接单</Radio>
            <Radio :label="3">不付款自动接单</Radio>
            <Radio :label="4">不付款手动接单</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="外卖预留手机号：">
          <i-switch
            v-model="formData.stay_phone"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
        <FormItem
          v-if="formData.receive_type == 3 || formData.receive_type == 4"
          label="关闭支付按钮："
        >
          <i-switch
            v-model="formData.close_pay"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            content="开启后，客户无需支付即可完成下单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
        </FormItem>
        <FormItem label="（用户端）接单后可申请取消：">
          <i-switch
            v-model="formData.order_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户多少分钟内，可申请取消"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem v-if="formData.order_cancel_status" label="">
            <Input
              v-model="formData.order_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟内，可申请取消
          </FormItem>
        </FormItem>
        <FormItem
          v-if="formData.receive_type == 1 || formData.receive_type == 2"
          label="（用户端）自动取消订单："
        >
          <i-switch
            v-model="formData.not_pay_cancel_status"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="客户超时未付款，将会自动取消订单"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <FormItem
            v-if="
              formData.not_pay_cancel_status &&
              (formData.receive_type == 1 || formData.receive_type == 2)
            "
            style="margin-top: 0"
            label=""
          >
            <Input
              v-model="formData.not_pay_cancel_min"
              type="number"
              style="width: 100px"
              placeholder="请输入"
            ></Input>
            分钟后自动取消订单
          </FormItem>
        </FormItem>

        <FormItem
          v-if="formData.receive_type == 2 || formData.receive_type == 4"
          label="（商家端）自动关闭订单："
        >
          <i-switch
            v-model="formData.is_close_order"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未接单，将会自动关闭订单且退款"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.is_close_order == 1">
            <div class="flex_center">
              <Input
                v-model="formData.close_min"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动关闭订单</div>
            </div>
          </div>
        </FormItem>

        <FormItem label="（商家端）自动触发配送：">
          <i-switch
            v-model="formData.w_auto_delivery"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="500"
            content="商家超时未点击配送按钮，将会自动触发配送功能"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.w_auto_delivery == 1">
            <div class="flex_center">
              <Input
                v-model="formData.w_auto_delivery_time"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">分钟后自动触发配送功能</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="自动确认收货：">
          <i-switch
            v-model="formData.is_confirm_goods"
            :true-value="1"
            :false-value="0"
          />
          <Tooltip
            style="cursor: pointer"
            max-width="300"
            content="开启后，订单未确认收货，多少小时后系统将自动为用户确认收货，最小值为1小时"
            placement="top"
          >
            <Icon style="margin-left: 10px" size="20" type="ios-help-circle" />
          </Tooltip>
          <div v-if="formData.is_confirm_goods == 1">
            <div class="flex_center">
              <Input
                v-model="formData.confirm_hour"
                style="width: 200px"
                placeholder="请输入"
              ></Input>
              <div class="ml5">小时后自动确认收货</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="打印设置：">
          <CheckboxGroup v-model="formData.w_print_mode">
            <Checkbox style="margin-right: 30px" :label="1"
              >接单即打印</Checkbox
            >
            <Checkbox :label="2">付款即打印</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <!-- 外卖订单设置 end -->
      </Form>
      <!-- 分享设置 -->
      <div class="title_txt">分享设置</div>
      <Form label-position="right" :label-width="210">
        <FormItem label="分享标题：">
          <Input
            v-model="formData.share_title"
            style="width: 400px"
            placeholder="请输入分享标题"
          ></Input>
        </FormItem>
        <FormItem label="分享封面：">
          <div style="display: flex">
            <div>
              <Upload
                ref="upload"
                :show-upload-list="false"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :headers="header"
                :on-format-error="handleFormatError"
                :on-success="uploadImgSuccess"
                :on-progress="handleProgress"
                multiple
                :action="uploads"
              >
              <div class="uplads_box">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                  <div style="color: #999; font-size: 12px; margin-top: -15px">
                    (130*160)
                  </div>
                </div>
              </div>
              </Upload>
            </div>
            <div v-if="picview" class="img_view_Box">
              <img :src="picview" alt="" />
            </div>
          </div>
        </FormItem>
        <FormItem label="分享简介：">
          <Input
            class="textar"
            type="textarea"
            v-model="formData.share_desc"
            placeholder="请输入分享简介"
          ></Input>
          <!-- <textarea v-model="formData.share_desc" placeholder="请输入分享简介" class="textar" name="" id="" cols="30" rows="10"></textarea> -->
        </FormItem>
      </Form>
      <!-- 分享设置 end -->
    </div>
    <div class="bottom_btn">
      <Button @click="addBtn" class="btn">保存</Button>
    </div>
  </div>
</template>

<script>
import { getBasic, basicSetsetting } from "@/api/setting";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import imageLibrary from "@/components/diy/imageLibrary.vue";
export default {
  components: { imageLibrary },
  name: "password",
  data() {
    return {
      imgtype: 1,
      switch_modal: false,
        header: { "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token") },
        uploads: config.apiUrl + "/storeapi/data/uploads",
      picview: "",
      new_advertisement_cover: "",
      new_card_img: "",
      template_name: "",
      pathList: [
        {
          value: 1,
          label: "积分商城",
        },
        {
          value: 2,
          label: "外卖选购",
        },
        {
          value: 3,
          label: "自取选购",
        },
      ],
      formData: {
        is_kefu: 0,
        show_upload_shipping:0
      },
      cityList: [
        {
          value: 1,
          label: "餐饮模式",
        },
        {
          value: 2,
          label: "会员卡模式",
        },
      ],
    };
  },
  created() {
    this.basicXq();
  },
  methods: {
    // 事件处理函数
    async costPlannedAmountChange(param1, param2) {
      console.log(param1);
        for (var i = 0; i < param1.length; i++) {
          this.new_card_img = param1[i];
        }
        for (var i = 0; i < param2.length; i++) {
          this.formData.card_img = param2[i];
          this.formData.card_img_domain = param2[i];
        }
        this.$Message.destroy();
    },
    openModal(e) {
      this.switch_modal = true;
    },
    basicXq() {
      getBasic().then((res) => {
        this.formData = res.data;
        this.new_card_img = res.data.card_img_domain;
        this.picview = res.data.share_cover_domain;
      });
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.picview = e.data.path;
      this.formData.share_cover = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 第一个Upload上传成功方法
    carduploadImgSuccess(e) {
      this.new_card_img = e.data.path;
      this.formData.card_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    z_codeChange(e) {
      if (e == 0) {
        this.formData.z_check = 0;
      }
    },
    addBtn() {
      if (this.formData.z_mode.length == 0)
        return this.$Message.warning("请选择就餐模式！");
      if (
        this.formData.z_user_info_status &&
        this.formData.z_user_info.length == 0
      )
        return this.$Message.warning("请勾选用户预留信息！");
      // this.formData.template_div = JSON.stringify(this.template_div)
      basicSetsetting(this.formData)
        .then((res) => {
          this.$Message.success(res.msg);
        })
        .catch((err) => {
          this.$Message.error(err.msg);
        });
    },
    backBtn() {
      this.$emit("backview", 0);
    },
  },
};
</script>

<style scoped>
.son-page {
  position: relative;
}
.back_box {
  width: 100%;
  cursor: pointer;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}
.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}
.title_txt {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 10px -10px;
}
.xiao_title {
  margin: 10px 0;
}
.muban_box {
  width: 100%;
  display: flex;
  /* padding: 0 0 20px; */
  align-items: center;
  justify-content: space-between;
}
.view_box {
  position: relative;
  width: 200px;
  height: 270px;
  /* background: red; */
}

.view_box img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.view_box .edit_box:hover {
  opacity: 1;
}

.edit_box {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.edit_icon {
  color: #fff;
  font-size: 80px;
}

.item_muban_box {
  width: 200px;
  height: 350px;
  /* background: #000; */
}

.check_btns {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.btn_no {
  color: #ff761d;
  /* background: #FFC7A3; */
  border-color: #ff761d;
}
.bottom_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
  border-top: 1px solid #eee;
  background: #fff;
}
.flex_center {
  display: flex;
  align-items: center;
}
.ml5 {
  margin-left: 5px;
}
.textar {
  width: 400px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.img_view_Box {
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.color_999 {
  color: #999;
}
.menu_text {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}
.model_box {
  height: 500px;
  overflow: auto;
}
.muban_title {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
</style>
<style>
.ivu-radio-checked .ivu-radio-inner {
  border-color: #ff761d;
}
.ivu-radio-inner:after {
  background-color: #ff761d;
}
.ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #ff761d;
  background-color: #ff761d;
}
.ivu-switch-checked {
  border-color: #ff761d;
  background-color: #ff761d;
}
</style>
