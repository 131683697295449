<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div >
        <Icon size="24" type="ios-arrow-back"/>
      </div>
      <div class="ml10">必点商品</div>
    </div>
    <div class="content_box">
        <Form label-position="left" :label-width="100">
            <FormItem label="启用必点：">
              <i-switch @on-change="switchBtn" size="large" v-model="status" :true-value="1" :false-value="0" >
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
              </i-switch>
            </FormItem>
            <span v-if="status == 1">
                <FormItem label="显示名称：">
                    <Input style="width:360px" placeholder="请输入显示名称" v-model="formData.show_name"></Input>
                </FormItem>
                <FormItem label="必选商品数：">
                    <Input style="width:360px" type="number" placeholder="请输入必选商品数" v-model="formData.must_num"></Input>
                    <Tooltip style="margin-left:10px" content="选择几个必选商品才能下单" placement="right-start">
                        <!-- <Icon size="30" type="md-alert" /> -->
                        <Icon size="20" type="ios-alert-outline" />
                    </Tooltip>
                </FormItem>
                <FormItem label="模式：">
                    <CheckboxGroup @on-change="modeChange" v-model="formData.mode" class="fenbu">
                        <Checkbox v-for="(item,index) of pattern" :key="index" style="margin-left:15px" :label="item.id">{{item.mode_name}}</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="商品选择：">
                    <Transfer :operations="['取消必点','商品必点']" :data="data1" filterable :target-keys="formData.must_goods_ids" :render-format="render1" @on-change="handleChange1"></Transfer>
                </FormItem>
            </span>
        </Form>
    </div>

    <div class="bottom_btn">
        <Button @click="addBtn" class="btn">保存</Button>
    </div>
  </div>
</template>

<script>
import {getMode} from '@/api/customer'
import {mustgetGoods,setMustConf,getMustConf,changeMustStatus} from '../../../../api/elseSetting'

export default {
  name: "password",
  data() {
    return {
        status:0,
        img:require('../../../../assets/bi.png'),
        img1:require('../../../../assets/bi1.png'),
        data1: [],
        pattern:[],
        formData:  {
            show_name:'',
            must_num:'',
            mode:[],
            must_goods_ids:[]
        }
    }
  },
    created() {
        this.replace()
        this.getMode()
        this.getMustConf()
    },
    methods: {
        getList() {
            mustgetGoods({mode:this.formData.mode}).then(res => {
                this.data1 = res.data
            })
        },
        getMustConf(){
            getMustConf().then(res=>{
                this.status = res.data.status
                this.formData.mode = res.data.mode
                this.formData.must_num = res.data.must_num
                this.formData.show_name = res.data.show_name
                this.formData.must_goods_ids = res.data.must_goods_ids
                this.getList()
            })
        },
        addBtn(){
            setMustConf(this.formData).then(res=>{
                this.$Message.success(res.msg)
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        },
        modeChange(){
            this.getList()
            this.formData.must_goods_ids = []
        },
        getMode(){
            getMode().then(res=>{
                this.pattern = res.data
            })
        },
        getMockData () {
            let mockData = [];
            for (let i = 1; i <= 20; i++) {
                mockData.push({
                    key: i.toString(),
                    label: 'Content ' + i,
                    description: 'The desc of content  ' + i,
                    disabled: false
                    // disabled: Math.random() * 3 < 1
                });
            }
            return mockData;
        },
        getTargetKeys () {
            // return this.getMockData()
            //         .filter(() => Math.random() * 2 > 1)
            //         .map(item => item.key);
        },
        render1 (item) {
            return item.label;
        },
        handleChange1 (newTargetKeys) {
            console.log(newTargetKeys);
            this.formData.must_goods_ids = newTargetKeys;
        },
        switchBtn(){
            changeMustStatus().then(res=>{
                this.$Message.success(res.msg)
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
            this.replace()
        },
        replace(){
            setTimeout(() => {
                let a = document.getElementsByClassName('ivu-transfer-list-header-title')
                if (a.length!=0) {
                    a[0].innerHTML = '全部商品'
                    a[1].innerHTML = '必点商品'
                }
            }, 400);
        },
        backBtn() {
            this.$emit('backview', 0)
        }
    }
}
</script>

<style scoped>
.son-page{
    position: relative;
}
.back_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.body_box{
    width: 100%;
    height: 500px;
    overflow: auto;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.chooseeMore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
</style>

<style>
.ivu-input-small{
    height: 36px;
}
.ivu-input-wrapper-small .ivu-input-icon{
    height: 36px;
    line-height: 36px;
}
.ivu-transfer-list{
    width: 300px;
    height: 400px;
}
.ivu-transfer-list-body-with-search .ivu-transfer-list-content{
    margin-top: 20px;
    padding: 0 0 20px;
}
.ivu-transfer-list-header{
    background: unset;
    border-bottom: unset;
}

.ivu-transfer-operation button:nth-child(1){
    border: unset;
    background: url('../../../../assets/bi.png') no-repeat;
    background-size: contain;
}
.ivu-transfer-operation button:nth-child(1) span{
    opacity: 0;
}
.ivu-transfer-operation button:nth-child(2){
    margin-top: 50px;
    border: unset;
    background: url('../../../../assets/bi1.png') no-repeat;
    background-size: contain;
}
.ivu-transfer-operation button:nth-child(2) span{
    opacity: 0;
}
/* .ivu-transfer-operation :last-child{
    background: red;
} */
/* .ivu-btn-primary{
    background: #000;
    background: url('../../../../assets/bi.png');
} */
</style>