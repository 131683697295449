<template>
  <div>
    <!-- 热区组件 -->
    <Modal v-model="dialog_modal" title="热区划分" width="900" :mask-closable="false">
      <div class="main">
        <div class="left">
          <div id="test" style="user-select: none">
            <div class="content" id="test">
              <div
                :style="{
                  transform: 'scale(' + num + ')',
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }"
                @mousedown="moveMouse"
                @click="getOffect"
              >
                <div v-if="new_data.content.hotspot.length > 0">
                  <div
                    v-for="(item, index) in new_data.content.hotspot"
                    :key="index"
                    :ref="'biaozhu' + index"
                    :class="index === idx ? 'biaozhu b_border' : 'biaozhu'"
                    :style="{
                      width: item.width + 'px',
                      height: item.height + 'px',
                      position: 'absolute',
                      left: item.left + 'px',
                      top: item.top + 'px',
                      background: 'rgba(255,255,255,0)',
                      border: '1px dashed #ff761d',
                    }"
                    @click.stop="handelClick(index)"
                    @mousedown.stop="move"
                  >
                    <div class="icon" @click.stop="del(index)">
                      <Icon type="md-close-circle" color="black" size="20" />
                    </div>
                  </div>
                </div>

                <div
                  :style="{
                    height: biaozhuHeight + 'px',
                    width: biaozhuWidth + 'px',
                    top: biaozhuTop + 'px',
                    left: biaozhuLeft + 'px',
                    position: 'absolute',
                    border: '1px dashed #ff761d',
                  }"
                ></div>

                <HotSpotPic :new_data="new_data"></HotSpotPic>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Form :label-width="80">
            <template v-if="isTrue">
              <FormItem label="热区尺寸">
                宽
                <InputNumber
                  v-model="new_data.content.hotspot[idx].width"
                  @on-change="goChange($event, 1)"
                  :min="0"
                >
                </InputNumber>
                <span style="margin: 0 10px 0 0">PX</span>
                高
                <InputNumber
                  v-model="new_data.content.hotspot[idx].height"
                  @on-change="goChange($event, 2)"
                  :min="0"
                >
                </InputNumber>
                <span>PX</span>
              </FormItem>
              <FormItem label="热区位置" style="margin: 0 0 10px 0">
                X
                <InputNumber
                  v-model="new_data.content.hotspot[idx].left"
                  @on-change="goChange($event, 3)"
                  :min="0"
                >
                </InputNumber>
                <span style="margin: 0 10px 0 0">PX</span> Y
                <InputNumber
                  v-model="new_data.content.hotspot[idx].top"
                  @on-change="goChange($event, 4)"
                  :min="0"
                >
                </InputNumber>
                <span>PX</span>
              </FormItem>
              <FormItem label="动作">
                <Select
                  v-model="new_data.content.hotspot[idx].action"
                  @on-change="getchange"
                >
                  <Option
                    v-for="item in actionListSon"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem
                v-if="new_data.content.hotspot[idx].action == 1"
                label="跳转页"
              >
                <Select
                  v-model="new_data.content.hotspot[idx].path"
                  @on-change="getchangePage"
                >
                  <Option
                    v-for="item in pageListSon"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem
                v-if="new_data.content.hotspot[idx].action == 2"
                label="跳转商品"
              >
                <Select
                  v-model="new_data.content.hotspot[idx].shop"
                  @on-change="getchangeGoods"
                >
                  <Option
                    v-for="item in shopListSon"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.goods_name }}
                  </Option>
                </Select>
              </FormItem>
            </template>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button class="btn" @click="modalSet">保存</Button>
      </div>
      <div slot="close" @click="closeModal">
        <i class="ivu-icon ivu-icon-ios-close"></i>
      </div>
    </Modal>
  </div>
</template>

<script>
import HotSpotPic from "@/components/diy/HotSpot/HotSpotPic.vue";
export default {
  components: { HotSpotPic },
  props: {
    formData: {
      type: Object,
      default: {},
    },
    actionList: {
      type: Array,
      default: [],
    },
    pageList: {
      type: Array,
      default: [],
    },
    shopList: {
      type: Array,
      default: [],
    },
    dialog_modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "划分热区",
      isTrue: false,
      num: 1,
      boxArry: [],
      width: "",
      height: "",
      left: "",
      top: "",
      idx: "",
      biaozhuHeight: 0,
      biaozhuWidth: 0,
      biaozhuTop: 0,
      biaozhuLeft: 0,
      actionListSon: this.actionList,
      pageListSon: this.pageList,
      shopListSon: this.shopList,
      modal: false,
      inputWidth: 0,
      inputHeight: 0,
      inputLeft: 0,
      inputTop: 0,
      is_move: false,
      new_data: {
        content: {
          hotspot: [],
          height: 0,
          width: 0,
          paddingData: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          },
        },
      },
      other_data: {},
    };
  },
  mounted() {
    this.new_data = JSON.parse(JSON.stringify(this.formData)); //要值
    this.other_data = this.formData;//要址 只有保存和修改的时候改变
  },
  methods: {
    closeModal() {
      this.$emit("update:dialog_modal", false);
    },
    handleImageError() {
      // 图片加载失败时会触发这个方法
      this.imageExists = false; // 将图片存在状态设置为false
    },
    del(i) {
      this.isTrue = false;
      this.new_data.content.hotspot.splice(i, 1);
      this.idx = "";
    },
    // 保存按钮
    modalSet() {
      this.other_data.content.hotspot = []
      this.new_data.content.hotspot.forEach(item => {
        this.other_data.content.hotspot.push(item)
      })
      this.$emit("updateHotspot", this.other_data);
      this.closeModal();
    },
    getOffect(e) {
      if (this.is_move) {
        this.is_move = false;
        return;
      }
      this.idx = "";
      this.isTrue = false;
      document.onmousemove = null;
      document.onmouseup = null;
    },
    goChange(e, type) {
      if (type == 1) {
        //修改宽度
        this.new_data.content.hotspot[this.idx].width = e;
      } else if (type == 2) {
        //修改高度
        this.new_data.content.hotspot[this.idx].height = e;
      } else if (type == 3) {
        //修改left
        this.new_data.content.hotspot[this.idx].left = e;
      } else {
        //修改top
        this.new_data.content.hotspot[this.idx].top = e;
      }
    },
    getchange(e) {
      this.new_data.content.hotspot[this.idx].actionValue = e;
    },
    getchangePage(e) {
      this.new_data.content.hotspot[this.idx].actionPage = e;
    },
    getchangeGoods(e) {
      this.new_data.content.hotspot[this.idx].actionGoods = e;
    },
    moveMouse(e) {
      if (typeof this.new_data.content.hotspot == "undefined") {
        this.new_data.content.hotspot = [];
      }

      let odiv = e.currentTarget; //获取目标元素
      // console.log('目标元素',odiv)

      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = disX - odiv.getBoundingClientRect().x;
        let top = disY - odiv.getBoundingClientRect().y;
        this.width = (e.clientX - disX) / this.num;
        this.height = (e.clientY - disY) / this.num;
        this.biaozhuWidth = this.width;
        this.biaozhuHeight = this.height;
        this.biaozhuLeft = left;
        this.biaozhuTop = top;
        this.inputWidth = Math.abs(Math.round(this.width));
        this.inputHeight = Math.abs(Math.round(this.height));
        this.inputLeft = Math.round(left);
        this.inputTop = Math.round(top);
        this.is_move = true;
        document.onmouseup = (e) => {
          let left = disX - odiv.getBoundingClientRect().x;
          let top = disY - odiv.getBoundingClientRect().y;
          this.width = (e.clientX - disX) / this.num;
          this.height = (e.clientY - disY) / this.num;
          if (this.width > 5 && this.height > 5) {
            this.new_data.content.hotspot.push({
              width: this.width,
              height: this.height,
              left: left,
              top: top,
              actionValue: "",
              actionPage: "",
              actionGoods: "",
              action: 1,
            });
            this.idx = this.new_data.content.hotspot.length - 1;
            this.isTrue = true;
            this.$forceUpdate();
          }
          this.biaozhuWidth = 0;
          this.biaozhuHeight = 0;
          this.biaozhuLeft = 0;
          this.biaozhuTop = 0;
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    },
    mouseOver2(e) {
      document.onmousedown = (e) => {
        let odiv = e.target; //获取目标元素
        //算出鼠标相对元素的位置
        let disX = e.clientX - odiv.offsetLeft;
        let disY = e.clientY - odiv.offsetTop;
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        //绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;
        //移动当前元素
        this.new_data.content.hotspot[this.idx].width = left;
        this.new_data.content.hotspot[this.idx].height = top;
      };
    },
    move(e) {},
    handelClick(i) {
      this.isTrue = true;
      this.idx = i;
    },
  },
};
</script>

<style scoped>
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}

.main {
  display: flex;
}

.left {
  width: 351px;
  background-color: #e9e9e9;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 351px;
  overflow: hidden;
  position: relative;
}

.icon {
  position: absolute;
  top: -10px;
  right: -10px;
}

#test .el-dialog__body {
  padding: 10px 20px !important;
}

.b_border {
  border: 1px dashed #ff761d !important;
  background-color: rgba(43, 100, 206, 0.3) !important;
}
</style>