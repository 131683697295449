import request from '@/utils/request'

export function getStoreDiy(data) {
    return request({
        url: '/plugin/storeDiy/getStoreDiy',
        method: 'post',
        data
    })
}

export function setStoreDiy(data) {
    return request({
        url: '/plugin/storeDiy/setStoreDiy',
        method: 'post',
        data
    })
}

export function changeDiyStatus(data) {
    return request({
        url: '/plugin/storeDiy/changeDiyStatus',
        method: 'post',
        data
    })
}