<template>
  <div>
    <div v-if="new_data.content.imgs !== undefined ">
      <div
        v-if="new_data.imgtype == 0"
        :style="`height:${new_data.content.height}px;background:${new_data.content.bgColor};`"
      >
        <div :style="`width:100%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="new_data.imgtype == 1"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div :style="`width:40%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:60%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[1].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="new_data.imgtype == 2"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div
          :style="`width:40%;height:${new_data.content.height}px;`"
        >
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:60%;height:${new_data.content.height}px;`">
          <div
            :style="`width:100%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[1].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:100%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[2].picview"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
      id="parentId"
        v-if="new_data.imgtype == 3"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div
          :style="`width:40%;height:${new_data.content.height}px;`"
        >
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:60%;height:${new_data.content.height}px;`">
          <div
            :style="`width:100%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[1].picview"              
              alt=""
            />
          </div>
          <div style="display: flex">
            <div
              :style="`width:50%;height:${new_data.content.height / 2}px;`"
            >
              <img
                :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
                :src="new_data.content.imgs[2].picview"
                alt=""
              />
            </div>
            <div
              :style="`width:50%;height:${new_data.content.height / 2}px;`"
            >
              <img
                :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
                :src="new_data.content.imgs[3].picview"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="new_data.imgtype == 4"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div :style="`width:50%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:50%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[1].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="new_data.imgtype == 5"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div :style="`width:33.3%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:33.3%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[1].picview"
            alt=""
          />
        </div>
        <div :style="`width:33.3%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[2].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="new_data.imgtype == 6"
        :style="`height:${new_data.content.height}px;display:flex;`"
      >
        <div :style="`width:25%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:25%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[1].picview"
            alt=""
          />
        </div>
        <div :style="`width:25%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[2].picview"
            alt=""
          />
        </div>
        <div :style="`width:25%;height:${new_data.content.height}px;`">
          <img
            :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
            :src="new_data.content.imgs[3].picview"
            alt=""
          />
        </div>
      </div>
      <div v-if="new_data.imgtype == 7">
        <div
          :style="`width:100%;height:${
            new_data.content.height / 2
          }px;display:flex;`"
        >
          <div
            :style="`width:50%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[0].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:50%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[1].picview"
              alt=""
            />
          </div>
        </div>
        <div
          :style="`width:100%;height:${
            new_data.content.height / 2
          }px;display:flex`"
        >
          <div
            :style="`width:50%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[2].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:50%;height:${new_data.content.height / 2}px;`"
          >
            <img
              :style="`width:100%;height:100%;padding:${new_data.content.paddingData.top}px ${new_data.content.paddingData.right}px ${new_data.content.paddingData.bottom}px ${new_data.content.paddingData.left}px;object-fit: cover;`"
              :src="new_data.content.imgs[3].picview"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        :style="{
          height: new_data.content.height + 'px',
        }"
      >
      <img
        :style="`width:100%;height:100%;object-fit: cover;`"
        @dragstart.prevent
        :src="new_data.content.picview"
        alt=""
      />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    new_data: {
      typeof: Object,
      default: {},
    },
  },
};
</script>

<style scoped>
</style>