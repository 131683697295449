<template>
    <div>
        <div>
            <Form style="width:400px" :label-width="100">
                <FormItem label="存酒审核：">
                    <i-switch v-model="formData.set_status" :true-value="1" :false-value="0"/>
                </FormItem>
                <FormItem label="存酒有效期：">
                    <div class="progresser">
                        <div onselectstart="return false" onselect="document.selection.empty()" @click="jian" class="icon">-</div>
                        <input v-model="formData.set_days" class="Input" type="number">
                        <div onselectstart="return false" onselect="document.selection.empty()" @click="zen" class="icon">+</div>
                    </div>
                </FormItem>
                <FormItem label="存酒提示语：">
                    <Input v-model="formData.set_tip" placeholder="请输入存酒提示语"></Input>
                </FormItem>
                <FormItem label="存酒协议：">
                    <Input v-model="formData.set_content" type="textarea" placeholder="请输入存酒协议"></Input>
                </FormItem>
            </Form>
        </div>
        <div class="bottom_btn">
            <Button @click="addBtn" class="btn">保存</Button>
        </div>
    </div>
</template>

<script>
    import {alcoholgetConf,alcoholsetConf} from '@/api/setting'
    export default {
        data(){
            return{
                formData:{}
            }
        },
        created(){
            this.getConf()
        },
        methods:{
            getConf(){ // 获取配置
                alcoholgetConf().then(res=>{
                    this.formData = res.data
                })
            },
            jian(){
                if (!this.formData.set_days) {
                    
                }else{
                    this.formData.set_days--
                }
            },
            zen(){
                if (!this.formData.set_days) {
                    this.formData.set_days = 1
                }else{
                    this.formData.set_days++
                }
            },
            addBtn(){
                alcoholsetConf(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
        }
    }
</script>

<style scoped>
    .progresser{
        display: flex;
        align-items: center;
        border: 1px solid #999;
        width: 180px;
        border-radius: 10px;
        overflow: hidden;
    }
    .icon{
        width: 40px;
        text-align: center;
        cursor: pointer;
        font-weight: 600;
        background: #F8F8F8;
    }
    .Input{
        width: 100px;
        border: none;
        outline: none;
        border-left: 1px solid #999;
        border-right: 1px solid #999;
        text-align: center;
    }
    .bottom_btn{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 100px;
        border-top: 1px solid #eee;
        background: #FFF;
    }
    .btn{
        background:#FF761D;
        color:#FFF;
        border-color: #FF761D;
    }
</style>