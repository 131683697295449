<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div >
        <Icon size="24" type="ios-arrow-back"/>
      </div>
      <div class="ml10">餐位费</div>
    </div>
    <div class="content_box">
        <Form label-position="left" :label-width="100">
            <FormItem label="餐位费：">
              <i-switch @on-change="teachange" size="large" v-model="status" :true-value="1" :false-value="0" >
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
              </i-switch>
            </FormItem>
            <span v-if="status == 1">
              <FormItem label="餐位费金额：">
                <Input style="width:360px" placeholder="请输入餐位费金额" v-model="formData.money"></Input>
                <span style="margin-left:20px;color:#999;font-size:12px">单人价格</span>
              </FormItem>
            </span>
        </Form>
    </div>

    <div class="bottom_btn">
        <Button @click="addBtn" class="btn">保存</Button>
    </div>
  </div>
</template>

<script>
import {setSeatConf,getSeatConf,changeSeatStatus} from '../../../../api/elseSetting'
export default {
  name: "password",
  data() {
    return {
        status:0,
        formData:  {
            money: ''
        }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
        getSeatConf().then(res => {
            this.status = res.data.status
            this.formData.money = res.data.money
        })
    },
    teachange(e){
        changeSeatStatus().then(res=>{
            this.$Message.success(res.msg)
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    addBtn(){
        setSeatConf(this.formData).then(res=>{
            this.$Message.success(res.msg)
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    backBtn() {
      this.$emit('backview', 0)
    }
  }
}
</script>

<style scoped>
.son-page{
    position: relative;
}
.back_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.body_box{
    width: 100%;
    height: 500px;
    overflow: auto;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.chooseeMore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
.JumpBtn{
  margin-top: 20px;
}
</style>
