var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"son-page"},[(_vm.modal)?_c('div',[_c('Addmodal',{attrs:{"modalData":_vm.modalData},on:{"closeModal":_vm.closeModal}})],1):_c('div',[_c('div',{staticClass:"back_box",on:{"click":_vm.backBtn}},[_c('div',{staticClass:"pointer"},[_c('Icon',{staticStyle:{"color":"#515A6E"},attrs:{"size":"24","type":"ios-arrow-back"}})],1),_c('div',{staticClass:"ml10"},[_vm._v("积分商城")])]),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"search_box"},[_c('div',[_c('Button',{staticClass:"btn",on:{"click":function($event){return _vm.modalBtn('add')}}},[_vm._v("创建")])],1),_c('div',[_c('Input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入"},model:{value:(_vm.storePrams.keyword),callback:function ($$v) {_vm.$set(_vm.storePrams, "keyword", $$v)},expression:"storePrams.keyword"}}),_c('Button',{staticClass:"ml10",on:{"click":_vm.searchBtn}},[_vm._v("搜索")])],1)]),_c('Table',{staticStyle:{"margin-top":"20px"},attrs:{"stripe":"","columns":_vm.columns,"data":_vm.dataShop},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('strong',[_vm._v(_vm._s(index+1))])]}},{key:"get_type",fn:function(ref){
var row = ref.row;
return [(row.get_type.indexOf('1')!=-1)?_c('div',[_vm._v("自提")]):_vm._e(),(row.get_type.indexOf('2')!=-1)?_c('div',[_vm._v("快递")]):_vm._e()]}},{key:"order_num",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"color":"#FF761D","border-color":"#FF761D"},on:{"click":function($event){return _vm.numModalBtn(row.id)}}},[_vm._v(_vm._s(row.order_num))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"margin-right":"20px","color":"#2D8CF0","border-color":"#2D8CF0","background":"none"},attrs:{"type":"primary","ghost":"","size":"small"},on:{"click":function($event){return _vm.modalBtn(row)}}},[_vm._v("编辑")]),_c('Button',{attrs:{"type":"error","ghost":"","size":"small"},on:{"click":function($event){return _vm.delBtn(row.id)}}},[_vm._v("删除")])]}}])}),_c('br'),_c('Page',{staticStyle:{"text-align":"right"},attrs:{"total":_vm.total,"current":_vm.storePrams.page,"show-elevator":"","show-total":"","page-size":_vm.storePrams.limit},on:{"on-change":_vm.pageChange}})],1)]),_c('Modal',{attrs:{"width":"900"},model:{value:(_vm.numModal),callback:function ($$v) {_vm.numModal=$$v},expression:"numModal"}},[_c('p',{attrs:{"slot":"header"},slot:"header"},[_vm._v("兑换数量")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('Table',{attrs:{"columns":_vm.columns12,"data":_vm.data6},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('strong',[_vm._v(_vm._s(index+1))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status==1)?_c('div',[_vm._v("待取货")]):_vm._e(),(row.status==2)?_c('div',[_vm._v("已取货")]):_vm._e(),(row.status==1001)?_c('div',[_vm._v("待发货")]):_vm._e(),(row.status==1002)?_c('div',[_vm._v("已发货")]):_vm._e()]}},{key:"get_type",fn:function(ref){
var row = ref.row;
return [(row.get_type==1)?_c('div',[_vm._v("自提")]):_vm._e(),(row.get_type==2)?_c('div',[_c('Button',{on:{"click":function($event){return _vm.infoBtn(row)}}},[_vm._v("快递")])],1):_vm._e()]}}])}),_c('br'),_c('Page',{staticStyle:{"text-align":"right"},attrs:{"total":_vm.total1,"current":_vm.storePrams1.page,"show-elevator":"","show-total":"","page-size":_vm.storePrams1.limit},on:{"on-change":_vm.pageChange1}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"})]),_c('Modal',{attrs:{"width":"400"},model:{value:(_vm.infoModal),callback:function ($$v) {_vm.infoModal=$$v},expression:"infoModal"}},[_c('p',{attrs:{"slot":"header"},slot:"header"},[_vm._v("快递信息")]),_c('div',[_c('Form',{attrs:{"label-position":"left","label-width":100}},[_c('FormItem',{staticStyle:{"margin":"0"},attrs:{"label":"收货人："}},[_vm._v(" "+_vm._s(_vm.address_json.name)+" ")]),_c('FormItem',{staticStyle:{"margin":"0"},attrs:{"label":"联系方式："}},[_vm._v(" "+_vm._s(_vm.address_json.phone)+" ")]),_c('FormItem',{attrs:{"label":"收货地址："}},[_vm._v(" "+_vm._s(_vm.address_json.address)+" ")]),_c('FormItem',{attrs:{"label":"配送信息："}},[_vm._v(" "+_vm._s(_vm.distribution_info?_vm.distribution_info:'暂无配送信息')+" ")])],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticClass:"btn",on:{"click":function($event){_vm.infoModal = false}}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }