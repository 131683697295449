<template>
    <div>
        <div class="box">
            <div @click="menuClick(1)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/ban.png" alt="">
                    <div class="menu_text">banner</div>
                </div>
            </div>
            <div @click="menuClick(2)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/tp.png" alt="">
                    <div class="menu_text">图片组件</div>
                </div>
            </div>
            <div @click="menuClick(3)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/tj.png" alt="">
                    <div class="menu_text">商品推荐</div>
                </div>
            </div>
            <div @click="menuClick(4)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/wz.png" alt="">
                    <div class="menu_text">文字组件</div>
                </div>
            </div>
            <div @click="menuClick(5)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/gge.png" alt="">
                    <div class="menu_text">宫格组件</div>
                </div>
            </div>
            <div @click="menuClick(6)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/gg.png" alt="">
                    <div class="menu_text">公告组件</div>
                </div>
            </div>
            <div @click="menuClick(7)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/ss.png" alt="">
                    <div class="menu_text">搜索组件</div>
                </div>
            </div>
            <div @click="menuClick(8)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/fztb.png" alt="">
                    <div class="menu_text">辅助空白</div>
                </div>
            </div>
            <div @click="menuClick(9)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/fz.png" alt="">
                    <div class="menu_text">辅助线</div>
                </div>
            </div>
            <div @click="menuClick(10)" class="menu_box">
                <div>
                    <img class="menu_icon" src="@/assets/imgs/dp.png" alt="">
                    <div class="menu_text">店铺信息</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            type:{
                type:Number,
                default:0
            }
        },
        methods:{
            menuClick(e){
                this.$emit('menuClick',e)
            },
        }
    }
</script>

<style scoped>
    img{
        vertical-align: middle;
    }
    .box{
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        padding: 20px 20px;
    }
    .menu_box{
        cursor: pointer;
        width: 70px;
        height: 65px;
        text-align: center;
        border-radius: 10px;
        background: #F8F8F8;
        margin: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menu_icon{
        width:25px;
        height:25px
    }
    .menu_text{
        font-size:13px
    }
    .title_box{
        display: flex;
        font-weight: 600;
        align-items: center;
        margin-bottom: 10px;
    }
    .title_box img{
        width: 20px;
        height: 20px;
        margin: 0 10px;
    }
    .banner_img{
        width: 100%;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #eee;
    }
    .banner_img img{
        width: 100%;
    }
    .recommend{
        width: 100%;
        border-radius: 20px;
        padding: 10px;
        display: flex;
        justify-content: space-around;
        border: 1px solid #eee;
    }
    .recommend_box{
        width: 110px;
        background: #F5F5F5;
        border:1px solid #eee
    }
    .recommend_box img{
        width: 100%;
    }
    .shop_info{
        font-size: 12px;
        display: flex;
        padding: 2px;
        align-items: center;
        justify-content: space-between;
    }
    .shop_desc{
        padding: 2px;
        font-size: 12px;
        color: #999;
    }
</style>