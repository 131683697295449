<template>
  <div>
    <div>
      <!-- formData.type == 10 店铺信息-->
      <div class="style_box">
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />店铺信息编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem label="模板样式">
                <Select v-model="formData.content.template">
                  <Option
                    v-for="item in templateList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="背景色">
                <ColorPicker v-model="formData.content.bgColor" />
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="标题色">
                <ColorPicker v-model="formData.content.titleColor" />
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="上下间距">
                <div>
                  <Slider
                    v-model="formData.content.paddingData.topBottom"
                  ></Slider>
                </div>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="左右间距">
                <div>
                  <Slider
                    v-model="formData.content.paddingData.leftRight"
                  ></Slider>
                </div>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="圆角">
                <Input
                  v-model="formData.content.radius"
                  placeholder="请输入文字内容"
                ></Input>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        formData:{
            type:Object,
            default:{}
        },
        templateList:{
            type:Array,
            default:[]
        }
    },
    data() {
        return {
        }
    },
};
</script>

<style scoped>
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
</style>