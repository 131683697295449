<template>
  <div>
    <div>
      <!-- formData.type == 3 商品推荐-->
      <div class="style_box">
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />标题编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem style="margin: 0 0 10px 0" label="内容">
                <Input
                  v-model="formData.content.titleData.content"
                  placeholder="请输入内容"
                ></Input>
              </FormItem>
              <FormItem label="字号">
                <div class="just_sb" style="display: unset">
                  <Input
                    v-model="formData.content.titleData.size"
                    placeholder="请输入字号"
                    style="width: 240px"
                  ></Input>
                  <ColorPicker
                    v-model="formData.content.titleData.textColor"
                    style="margin-left: 5px"
                  />
                </div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />图片编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem style="margin: 0 0 10px 0" label="内边距">
                <div style="margin-bottom: 5px" class="just_sb">
                  <div class="display_flex">
                    <div class="kuai_box">上</div>
                    <Input
                      v-model="formData.content.paddingData.top"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                  <div class="display_flex">
                    <div class="kuai_box">下</div>
                    <Input
                      v-model="formData.content.paddingData.bottom"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                </div>
                <div class="just_sb">
                  <div class="display_flex">
                    <div class="kuai_box">左</div>
                    <Input
                      v-model="formData.content.paddingData.left"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                  <div class="display_flex">
                    <div class="kuai_box">右</div>
                    <Input
                      v-model="formData.content.paddingData.right"
                      placeholder="0"
                      style="width: 120px"
                    ></Input>
                  </div>
                </div>
              </FormItem>
              <FormItem label="背景色">
                <ColorPicker v-model="formData.content.bgColor" />
              </FormItem>
            </Form>
          </div>
        </div>
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />文字编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <div>商品名</div>
              <FormItem style="margin: 0 0 5px 0" label="字号">
                <div class="just_sb" style="display: unset">
                  <Input
                    v-model="formData.content.textStyle.nameSize"
                    placeholder="请输入字号"
                    style="width: 100px"
                  ></Input>
                  <ColorPicker
                    v-model="formData.content.textStyle.nameColor"
                    style="margin-left: 5px"
                  />
                </div>
              </FormItem>
              <div>价格</div>
              <FormItem style="margin: 0 0 5px 0" label="字号">
                <div class="just_sb" style="display: unset">
                  <Input
                    v-model="formData.content.textStyle.priceSize"
                    placeholder="请输入字号"
                    style="width: 100px"
                  ></Input>
                  <ColorPicker
                    v-model="formData.content.textStyle.priceColor"
                    style="margin-left: 5px"
                  />
                </div>
              </FormItem>
              <div>简介</div>
              <FormItem style="margin: 0 0 5px 0" label="字号">
                <div class="just_sb" style="display: unset">
                  <Input
                    v-model="formData.content.textStyle.descSize"
                    placeholder="请输入字号"
                    style="width: 100px"
                  ></Input>
                  <ColorPicker
                    v-model="formData.content.textStyle.descColor"
                    style="margin-left: 5px"
                  />
                </div>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        formData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
        }
    },
};
</script>

<style scoped>
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.just_sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display_flex {
  display: flex;
}
.kuai_box {
  width: 30px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #bfbfbf;
}
</style>