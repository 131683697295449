import axios from 'axios';
import {Message} from 'view-design';
import config from '@/config';

const service = axios.create({
    baseURL: config.baserUrl,
    timeout: 10000 // 请求超时时间
})


service.interceptors.response.use(
    response=>{
        let status = response.data ? response.data.code : 0
        const code = status
        switch (code) {
            case 200:
                return response.data
            case 400:
                return Promise.reject( response.data || { msg: '未知错误' })
            case 400011:
            case 500:
            case 400012:
                return Promise.reject( { msg: '系统异常' })
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                break
            default:
                break
        }
    },
    error=>{
        if (typeof error.response == "undefined") {
            Message.error("服务器异常");
        }
        return Promise.reject(error);
    }

)

export default service;
