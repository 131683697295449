<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div >
        <Icon size="24" type="ios-arrow-back"/>
      </div>
      <div class="ml10">DIY首页样式</div>
    </div>
    <div class="content_box">
        <!-- DIY模板 -->
        <div class="diy_box">
            <div class="left_menu">
                <div class="right_style_title">基础组件</div>
                <Menu @menuClick="menuClick"></Menu>
            </div>
            <div class="center_content">
                <div class="preservation_box"><Button @click="confirmBtn" class="btn">保存</Button></div>
                <Form style="height:40px;display: flex;align-items: center;justify-content: center;" label-position="right" :label-width="100">
                    <FormItem style="margin:0 0 0 0" label="小程序背景色">
                        <ColorPicker v-model="bgColor" />
                    </FormItem>
                </Form>
                <Phone  :style="'background:'+bgColor" ref="child" :myArray="myArray" @myArrayChange="myArrayChange" @contentBtn="contentBtn(arguments)" @clearStyle="clearStyle"></Phone>
            </div>
            <div class="right_style">
                <div class="right_style_title">组件样式</div>
                <div class="right_style_height">
                    <Style ref="assig" @getReply="getReply" @updateAll="updateAll"></Style>
                </div>
            </div>
        </div>
        <!-- end -->
    </div>
  </div>
</template>

<script>
import {getStoreDiy,setStoreDiy,changeDiyStatus} from "@/api/diy"
import Menu from "./menu/menu";
import Phone from "./phone/phone";
import Style from "./style/style";
export default {
    components:{Menu,Phone,Style},
    data() {
        return {
            bgColor:'#FFFFFF',
            formData:  {
                status:1,
            },
            myArray: [],
            current_index:0,
            index:''
        }
    },
    created() {
        this.getStoreDiy()
    },
    methods: {
        getReply (param) {
            this.$refs.child.dealImgIndex(param)
        },
        // 获取diy数据
        getStoreDiy(){
            getStoreDiy().then(res=>{
                if (res.data.div_content.length!=0) {
                    this.myArray = res.data.div_content.myArray
                    for(let i in this.myArray) {
                        if(this.myArray[i].type == 2 && typeof(this.myArray[i].imgtype) == "undefined" ) {                            
                            this.myArray[i].imgtype = 0
                            this.myArray[i].content.hotspot = []
                            this.myArray[i].content.imgs= [ {picview:this.myArray[i].content.picview ? this.myArray[i].content.picview : "http://canyin.hulianzaowu.top/img/diy/goods.png", value:"/img/diy/goods.png"}]
                        }
                        if(this.myArray[i].content.height){
                            this.myArray[i].content.height = this.myArray[i].content.height * 1
                        }
                        this.myArray[i].imgkey = ''
                    }
                    this.bgColor = res.data.div_content.bgColor
                    this.$refs.child.setArray(this.myArray);
                }
            })
        },
        // 左侧菜单点击
        menuClick(e){
            switch (e) {
                case 1:
                    this.myArray.push({type:1,height:300,content:{imgList:[{value:'/img/diy/banner.png',picview:'http://canyin.hulianzaowu.top/img/diy/banner.png',action:1,path:null,shop:null}]}})
                    break;
                case 2:
                    this.myArray.push({type:2,imgtype:0,content:{hotspot:[],imgs:[{value:'/img/diy/goods.png',picview:'http://canyin.hulianzaowu.top/img/diy/goods.png'}],bgColor:'',width:'100%',height:'80',z_index:'999',action:1,path:null,shop:null,location:{top:0,left:0},paddingData:{top:0,right:0,bottom:0,left:0},}})
                    break;
                case 3:
                    this.myArray.push({type:3,content:{bgColor:'#F5F5F5',titleData:{content:'我是标题',size:'16',textColor:'#333333'},paddingData:{top:0,right:0,bottom:0,left:0},textStyle:{nameSize:'14',nameColor:'#333',priceSize:'12',priceColor:'#FF0000',descSize:'12',descColor:'#999999'}}})
                    break;
                case 4:
                    this.myArray.push({type:4,content:{width:114,height:28,text:'文字展示！！！',family:'unset',size:'16',weight:'100',textColor:'#333333',location:{top:0,left:0}}})
                    break;
                case 5:
                    this.myArray.push({type:5,content:{bgColor:'#FFFFFF',width:50,height:50,size:'14',textColor:'#333333',status:1,paddingData:{topBottom:0,leftRight:0},iconList:[{value:'/img/diy/smail.png',picview:'http://canyin.hulianzaowu.top/img/diy/smail.png',action:1,path:null,shop:null,title:'标题',explain:'功能描述'},{value:'/img/diy/smail.png',picview:'http://canyin.hulianzaowu.top/img/diy/smail.png',action:1,path:null,shop:null,title:'标题',explain:'功能描述'},{value:'/img/diy/smail.png',picview:'http://canyin.hulianzaowu.top/img/diy/smail.png',action:1,path:null,shop:null,title:'标题',explain:'功能描述'}]}})
                    break;
                case 6:
                    this.myArray.push({type:6,content:{text:'我是公告！！！',size:'16',textColor:'#FFFFFF',bgColor:'#FF761D'}})
                    break;
                case 7:
                    this.myArray.push({type:7,content:{text:'搜索商品',size:'14',textColor:'#999999',bgColor:'#EEEEEE',switch:true,height:30,radius:50,paddingData:{topBottom:16,leftRight:16}}})
                    break;
                case 8:
                    this.myArray.push({type:8,content:{bgColor:'#FFFFFF',height:30}})
                    break;
                case 9:
                    this.myArray.push({type:9,content:{bgColor:'#999999',border:'solid',paddingData:{topBottom:16,leftRight:16}}})
                    break;
                case 10:
                    this.myArray.push({type:10,content:{bgColor:'#FFFFFF',titleColor:'#333333',template:1,radius:0,paddingData:{topBottom:0,leftRight:0}}})
                    break;
                default:
                    console.log('啥也不是');
                    break;
            }
            setTimeout(function(){
                let phone_document = document.querySelector(".phone_box")
                phone_document.scroll({ top: phone_document.scrollHeight, behavior: 'smooth' })
            }, 100)
            
            // this.$refs.child.scroll({ top: this.$refs.child.scrollHeight, behavior: 'smooth' });
        },
        // 模板开启状态
        statusChange(){
            changeDiyStatus().then(res=>{
                this.$Message.success(res.msg)
            })
        },
        // 组件拖动回调
        myArrayChange(e,i){
            console.log('移动结束回调', e, i)
            this.myArray = e
            this.index = i
            if(e[i].type !== 2){
                this.$refs.assig.setdata(e[i]);
            }
        },
        // 编辑组件样式回调
        contentBtn(e){
            console.log('编辑组件样式回调',e)
            this.$refs.assig.setdata(e[0]);
            this.current_index = e[1]
            e[0].content.height = e[0].content.height * 1
            // this.styles = JSON.parse(JSON.stringify(e))
        },
        // 清除组件样式
        clearStyle(){
            let a = {}
            this.$refs.assig.setdata(a);
        },
        confirmBtn(){
            console.log('获取数据详情',this.myArray)
            let data = {
                bgColor:this.bgColor,
                myArray:this.myArray
            }
            setStoreDiy({content:JSON.stringify(data)}).then(res=>{
                this.$Message.success(res.msg)
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        },
        backBtn() {
            this.$emit('backview', false)
        },
        updateAll(e){
            this.myArray[this.current_index] = e;
        }
    }
}
</script>

<style scoped>
.son-page{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: #FFF;
}
.back_box{
    width: 200px;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 20px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
}
.add_box{
    width: 100%;
    margin-top: 20px;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.content_box{
    width: 100%;
    height: 78vh;
    padding: 0 0 10px;
    /* overflow: auto; */
    margin-top: -20px;
    background: #FFF;
    border-radius: 12px;
    /* padding: 30px; */
}

/* 模板样式 */
.diy_box{
    display: flex;
    /* justify-content: center; */
    margin-top: 30px;
}
.left_menu{
    width: 380px;
    height: 76vh;
    overflow: auto;
}
.center_content{
    background: #E9E9E9;
    width: 630px;
    height: 100vh;
    overflow: hidden;
}
.right_style{
    width: 630px;
    height: 76vh;
    /* overflow: hidden; */
}
.right_style_height{
    height: 76vh;
    /* overflow: auto; */
}
.preservation_box{
    background: #FFF;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #E9E9E9;
    padding: 0 20px;
}
.right_style_title{
    background: #FFF;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #E9E9E9;
}
.left_menu .right_style_title{
    justify-content: end;
}
</style>

<style>
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  background-color: #f5f5f5;
}
.ivu-select-single .ivu-select-selection{
    border: unset;
    background: #F8F8FA;
}
.ivu-switch-disabled.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
</style>
