<template>
    <div class="son-page">
        <div>
            <div @click="backBtn" class="back_box">
                <div class="pointer"><Icon style="color:#515A6E" size="24" type="ios-arrow-back" /></div>
                <div class="ml10">积分订单</div>
            </div>
            <div class="content_box">
                <div class="search_box">
                    <Form inline label-position="left" :label-width="80">
                        <FormItem style="margin:0" label="关键词：">
                            <Input v-model="storePrams.keyword" placeholder="请输入关键词"></Input>
                        </FormItem>
                        <FormItem style="margin:0" label="">
                            <Select v-model="storePrams.get_type" style="width:200px">
                                <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem style="margin:0" label="">
                            <Select v-model="storePrams.exchange_type" style="width:200px">
                                <Option v-for="item in cityList1" :value="item.id" :key="item.id">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem style="margin:0" label="">
                            <Button @click="searchBtn" class="btn">搜索</Button>
                        </FormItem>
                    </Form>
                </div>
                <Table stripe style="margin-top:20px" :columns="columns" :data="dataShop">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div v-if="row.status==1">待取货</div>
                        <div v-if="row.status==2">已取货</div>
                        <div v-if="row.status==1001">待发货</div>
                        <div v-if="row.status==1002">已发货</div>
                    </template>
                    <template slot-scope="{ row }" slot="get_type">
                        <div v-if="row.get_type==1">自提</div>
                        <div v-if="row.get_type==2"><Button @click="infoBtn(row)" style="color:#FF761D;border-color:#FF761D">快递</Button></div>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="numModalBtn(row.id)" v-if="row.status==1" type="primary" ghost size="small" style="width:58px;margin-right: 20px;color:#91C50F;border-color:#91C50F;background:none">兑换</Button>
                        <Button v-if="row.status==2" type="primary" ghost size="small" style="width:58px;margin-right: 20px;color:#999;border-color:#999;background:none">已取货</Button>
                        <Button @click="distributionBtn(row.id)" v-if="row.status==1001" type="primary" ghost size="small" style="width:58px;margin-right: 20px;color:#2D8CF0;border-color:#2D8CF0;background:none">配送</Button>
                        <Button v-if="row.status==1002" type="primary" ghost size="small" style="width:58px;margin-right: 20px;color:#999;border-color:#999;background:none">已发货</Button>
                        <!-- <Button type="error" ghost size="small">取消</Button> -->
                    </template>
                </Table>
                <br/>
                <Page :total="total"
                    :current="storePrams.page"
                    style="text-align:right"
                    show-elevator
                    show-total
                    @on-change="pageChange"
                    :page-size="storePrams.limit"/>
            </div>
        </div>

        <Modal v-model="numModal" width="400">
            <p slot="header"></p>
            <div style="display: flex;align-items: center;justify-content: center;">
                <Icon style="color:#52B502" size="40" type="ios-checkmark-circle" />兑换成功！
            </div>
            <div slot="footer">
                <Button @click="numModal = false" class="btn">确认</Button>
            </div>
        </Modal>

        <Modal v-model="distributionModal" width="430">
            <p slot="header"></p>
            <div>
                <Form label-position="left" :label-width="100">
                    <FormItem style="margin:0" label="配送信息：">
                        <textarea v-model="modalForm.distribution_info" class="textarea_style"></textarea>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="closeDistribution">取消</Button>
                <Button @click="addDistribution" class="btn">确认</Button>
            </div>
        </Modal>

        <Modal v-model="infoModal" width="400">
            <p slot="header">快递信息</p>
            <div>
                <Form label-position="left" :label-width="100">
                    <FormItem style="margin:0" label="收货人：">
                        {{address_json.name}}
                    </FormItem>
                    <FormItem style="margin:0" label="联系方式：">
                        {{address_json.phone}}
                    </FormItem>
                    <FormItem label="收货地址：">
                        {{address_json.address}}
                    </FormItem>
                    <FormItem label="配送信息：">
                        {{distribution_info?distribution_info:'暂无配送信息'}}
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="infoModal = false" class="btn">确定</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
    import Addmodal from "./components/addmodal";
    import {orderList,integralhandle} from '@/api/setting'
    export default {
        components:{Addmodal},
        data(){
            return{
                total:0,
                storePrams:{
                    keyword:'',
                    get_type:0,
                    exchange_type:0,
                    page:1,
                    limit:10,
                },
                modalForm:{
                   id:null, 
                   distribution_info:''
                },
                numModal:false,
                infoModal:false,
                distributionModal:false,
                modalData:{
                    title:'新增商品',
                    id:0,
                },
                columns: [
                    {
                        title: '#',
                        slot: 'index',
                        width:60,
                        align: 'center'
                    },
                    {
                        title: '商品名',
                        key: 'goods_name',
                        align: 'center'
                    },
                    {
                        title: '会员名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        key: 'phone',
                        align: 'center'
                    },
                    {
                        title: '兑换码',
                        key: 'pickup_code',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        align: 'center'
                    },
                    {
                        title: '提取方式',
                        slot: 'get_type',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                address_json:{},
                distribution_info:'',
                dataShop:[],
                cityList: [
                    {
                        id: 0,
                        label: '所有'
                    },
                    {
                        id: 1,
                        label: '自提'
                    },
                    {
                        id: 2,
                        label: '快递'
                    }
                ],
                cityList1: [
                    {
                        id: 0,
                        label: '所有'
                    },
                    {
                        id: 1,
                        label: '未兑换'
                    },
                    {
                        id: 2,
                        label: '已兑换'
                    }
                ],
            }
        },
        created(){
            this.orderList()
        },
        methods:{
            orderList(){
                orderList(this.storePrams).then(res=>{
                    this.dataShop = res.data.data
                    this.total = res.data.total
                })
            },
            searchBtn(){
                this.orderList()
            },
            numModalBtn(id){
                this.modalForm.id = id
                integralhandle(this.modalForm).then(res=>{
                    this.numModal = true
                    this.orderList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            closeDistribution(){
                this.distributionModal = false
                this.modalForm = {id:null,distribution_info:''}
            },
            addDistribution(){
                integralhandle(this.modalForm).then(res=>{
                    this.$Message.success(res.msg)
                    this.closeDistribution()
                    this.orderList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            distributionBtn(id){
                this.distributionModal = true
                this.modalForm.id = id
            },
            infoBtn(e){
                this.address_json = e.address_json
                this.distribution_info = e.distribution_info
                this.infoModal = true
            },
            backBtn(){
                this.$parent.goback()
            },
            pageChange(index){
                this.storePrams.page = index
                this.orderList()
            }
        }
    }
</script>

<style scoped>
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    padding: 30px;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.search_box{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.btn{
    background:#FF761D;
    color:#FFF;
    border-color: #FF761D;
}
.textarea_style{
    outline: none;
    line-height: 19px;
    border-color: #DCDEE2;
    max-width: 300px;
    min-width: 300px;
    max-height: 80px;
    min-height: 80px;
}
</style>