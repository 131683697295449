import { render, staticRenderFns } from "./HotSpot.vue?vue&type=template&id=d20e66be&scoped=true&"
import script from "./HotSpot.vue?vue&type=script&lang=js&"
export * from "./HotSpot.vue?vue&type=script&lang=js&"
import style0 from "./HotSpot.vue?vue&type=style&index=0&id=d20e66be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d20e66be",
  null
  
)

export default component.exports