<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">外卖配送</div>
      </div>
      <div class="content_box">
        <Form style="width:900px" label-position="left" :label-width="140">
          <FormItem label="配送方式：">
            <RadioGroup v-model="DistrData.delivery_type">
                <Radio class="w100" :label="1">自配送</Radio>
                <Radio :label="2">美团配送</Radio>
            </RadioGroup>
          </FormItem>

          <span v-if="DistrData.delivery_type==1">
            <FormItem label="配送距离限制：">
              <i-switch v-model="DistrData.distance_limit_status" size="large" :true-value="1" :false-value="0">
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
              </i-switch>
            </FormItem>
            <FormItem v-if="DistrData.distance_limit_status" label="">
              <Input v-model="DistrData.distance_limit_num" placeholder="请输入配送距离" style="width: 140px" type="number" /> <span style="margin-left:10px"> 公里</span>
            </FormItem>
            <FormItem label="配送费类型：">
              <RadioGroup v-model="DistrData.mode">
                  <Radio class="w100" :label="1">固定金额</Radio>
                  <Radio :label="2">按距离收费</Radio>
              </RadioGroup>
            </FormItem>
            <span v-if="DistrData.mode==1">
              <FormItem label="">
                <div class="line_box">
                  <div style="width:80px" class="left_box">起送价</div>
                  <div><input type="number" style="width:250px" class="inpclass" v-model="DistrData.g_start_delivery_price" /></div>
                  <div style="width:40px" class="center_box">元</div>
                  <div style="width:40px" class="center_box">满</div>
                  <div><input type="number" style="width:250px" class="inpclass" v-model="DistrData.g_full_price" /></div>
                  <div style="width:100px" class="right_box">元免费配送</div>
                </div>
              </FormItem>
              <FormItem label="">
                <div class="line_box">
                  <div style="width:50px" class="left_box">每单</div>
                  <div><input type="number" style="width:670px" class="inpclass" v-model="DistrData.price" /></div>
                  <div style="width:40px" class="right_box">元</div>
                </div>
              </FormItem>
            </span>
            <span v-if="DistrData.mode==2">
              <FormItem label="">
                <div class="line_box">
                  <div style="width:80px" class="left_box">起送价</div>
                  <div><input type="number" style="width:250px" class="inpclass" v-model="DistrData.j_start_delivery_price" /></div>
                  <div style="width:40px" class="center_box">元</div>
                  <div style="width:40px" class="center_box">满</div>
                  <div><input type="number" style="width:250px" class="inpclass" v-model="DistrData.j_full_price" /></div>
                  <div style="width:100px" class="right_box">元免费配送</div>
                </div>
              </FormItem>
              <FormItem label="">
                <div class="line_box">
                  <div style="width:50px" class="left_box">起步</div>
                  <div><input type="number" style="width:140px" class="inpclass" v-model="DistrData.start_price_distance" /></div>
                  <div style="width:70px" class="center_box">公里内</div>
                  <div><input type="number" style="width:140px" class="inpclass" v-model="DistrData.start_price" /></div>
                  <div style="width:180px" class="center_box">元，每增加1公里加</div>
                  <div><input type="number" style="width:140px" class="inpclass" v-model="DistrData.start_price_add" /></div>
                  <div style="width:40px" class="right_box">元</div>
                </div>
              </FormItem>
              <FormItem label="">
                <div class="line_box">
                  <div style="width:50px" class="left_box">超出</div>
                  <div><input type="number" style="width:130px" class="inpclass" v-model="DistrData.exceed_distance" /></div>
                  <div style="width:170px" class="center_box">公里后，每增加1公里加</div>
                  <div><input type="number" style="width:130px" class="inpclass" v-model="DistrData.exceed_distance_add" /></div>
                  <div style="width:110px" class="center_box">元，最高收取</div>
                  <div><input type="number" style="width:130px" class="inpclass" v-model="DistrData.exceed_distance_max" /></div>
                  <div style="width:40px" class="right_box">元</div>
                </div>
              </FormItem>
              <FormItem style="color:#A94442;margin-top:-20px" label="">
                计费规则:例如设置起步3km内5元,每增加1km加1元,超过6km,每增加1km加2元。如果配送距离5km,配送费: 5+ (5-3) X1= 7元;配送距离8km,配送费: 5+ (8-3) X2= 15元。
              </FormItem>
              <FormItem label="路径计算方式：">
                <RadioGroup v-model="DistrData.compute_mode">
                    <Radio class="w100" :label="1">骑行规划距离</Radio>
                    <Radio class="w100 ml_20" :label="2">驾车导航距离</Radio>
                    <Radio class="w100 ml_20" :label="3">步行距离</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="配送距离取整：">
                <RadioGroup v-model="DistrData.distance_int_mode">
                    <Radio class="w100" :label="1">默认</Radio>
                    <Radio class="w100 ml_20" :label="2">向上取整</Radio>
                    <Radio class="w100 ml_20" :label="3">向下取整</Radio>
                </RadioGroup>
              </FormItem>
            </span>
          </span>

          <span v-if="DistrData.delivery_type==2">
            <!-- <FormItem label="开启美团：">
              <i-switch @on-change="switchBtn" v-model="formData.it_disabled" :true-value="0" :false-value="1"/>
            </FormItem> -->
            <!-- <span v-if="formData.it_disabled==0"> -->
              <FormItem label="审核状态：">
              <div v-if="status==0">审核中</div>
                <div v-else-if="status==1">审核成功</div>
                <div v-else-if="status==2" style="color:red">审核失败，请修改后重新提交（原因：{{info.reason}}）</div>
                <div v-else>尚未创建</div>
              </FormItem>
              <FormItem label="美团appkey">
                  <Input v-model="formData.appkey" placeholder="请输入美团key"></Input>
              </FormItem>
              <FormItem label="美团secret">
                  <Input v-model="formData.secret" placeholder="请输入美团秘钥"></Input>
              </FormItem>
              <FormItem label="门店联系人：">
                  <Input v-model="formData.name" placeholder="请输入门店联系电话"></Input>
              </FormItem>
              <FormItem label="门店联系人手机号：">
                  <Input v-model="formData.phone" placeholder="请输入门店联系人手机号："></Input>
              </FormItem>
              <FormItem label="门店地址：">
                  <Input v-model="formData.address" placeholder="请输入门店地址："></Input>
              </FormItem>
              <FormItem label="一级分类：">
                  <Select @on-select="changeCategory" v-model="formData.category">
                      <Option v-for="item in one_category" :value="item.code" :key="item.code">{{ item.title }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="二级分类：">
                  <Select @on-select="changeSecondCategory" v-model="formData.second_category">
                      <Option v-for="item in second_category" :value="item.code" :key="item.code">{{ item.title }}</Option>
                  </Select>
              </FormItem>
              <FormItem label="配送类型：">
                <Select v-model="formData.distribution">
                    <Option v-for="item in distribution" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="商家承担比率：">
                  <Input v-model="formData.delivery_rate" type="number" placeholder="商家所需承担配送费用。例：填写0.3。则商家承担30%的配送费用"></Input>
              </FormItem>
              <FormItem label="美团余额：">
                <Button @click="detailedBtn">明细</Button>
              </FormItem>
            <!-- </span> -->
          </span>

        </Form>
      </div>
      <div class="bottom_btn">
          <!-- <Button v-if="DistrData.delivery_type==1?true:formData.it_disabled==0?true:false" @click="addBtn" class="btn">保存</Button> -->
          <Button @click="addBtn" class="btn">保存</Button>
          <!-- <Button @click="addBtn" class="btn">保存</Button> -->
      </div>

      <Modal
        :mask-closable='false'
        width="800"
        v-model="balancemodal"
        title="美团金额变动记录">
        <Form :label-width="100">
          <FormItem label="当前余额：">
              <div style="display:flex">
                <div class="num_Txt">{{mt_amount}}</div>
              </div>
            </FormItem>
            <!-- <FormItem label="当前余额：">
              <div style="display:flex">
                <div class="num_Txt">{{amount}}</div>
                <div style="margin-left:20px"><Button @click="changecli">变动</Button></div>
              </div>
            </FormItem> -->
            <FormItem label="日期选择：">
              <div style="display:flex">
                <div><DatePicker @on-change="time1" type="daterange" placement="bottom-start" placeholder="点击选择日期" style="width: 200px"></DatePicker></div>
                <div style="margin-left:20px"><Button @click="searchBtn1" class="btn">搜索</Button></div>
              </div>
            </FormItem>
        </Form>
        <Table ref="selection" stripe :columns="balanceColumns" :data="data5">
          <template slot-scope="{ index }" slot="index">
            <div>{{index+1}}</div>
          </template>
          <template slot-scope="{ row }" slot="scene">
            <div v-if="row.scene==1">管理员操作</div>
            <div v-if="row.scene==2">外卖配送费</div>
          </template>
        </Table>
        <br/>
        <Page :total="total1"
              :current="balancedata.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange1"
              :page-size="balancedata.limit"/>
        <div slot="footer">
            <!-- <Button @click="balancemodal = false" class="btn">关闭</Button> -->
        </div>
    </Modal>
  </div>
</template>

<script>
var that;
import {mtinfo,typeinfo,create,disabled,getDeliveryConf,setDeliveryConf,mtAmountList} from '@/api/setting'
export default {
  name: "password",
  data() {
    return {
      info:{},
      status:null,
      feedata:{
        input:0
      },
      DistrData:{
        delivery_type:1,
        distance_limit_status:0,
        mode:1,
        g_start_delivery_price:'',
        g_full_price:'',
        price:'',
        j_start_delivery_price:'',
        j_full_price:'',
        start_price_distance:'',
        start_price:'',
        start_price_add:'',
        exceed_distance:'',
        exceed_distance_add:'',
        exceed_distance_max:'',
        distance_limit_num:5,
        is_weight:0,
        is_time_add:0,
      },
      balancedata:{
        page:1,
        limit:5,
        start_time:'',
        end_time:''
      },
      balanceColumns:[
        {
          title: '#',
          slot: 'index',
          align: 'center',
          width: 60
        },
        {
          title: '时间',
          key: 'created_at',
          align: 'center',
        },
        {
          title: '金额',
          key: 'money',
          align: 'center',
        },
        {
          title: '类型',
          slot: 'scene',
          align: 'center',
        },
        {
          title: '说明',
          key: 'desc',
          align: 'center',
        }
      ],
      mt_amount:0,
      total1:0,
      data5:[],
      balancemodal:false,
      select_category_code:'',
      one_category: [],
      second_category: [],
      second_category2: [],
      distribution: [],
      formData:{
        it_disabled:1,
        name:'',
        phone:'',
        address:'',
        category:null,
        distribution:null,
        second_category:null,
        delivery_rate:'',
        appkey:"",
        secret:""
      },
      op_code:null
    }
  },
  created() {
    that = this;
    this.getDeliveryConf()
    this.typeinfo()
  },
  methods: {
    changeCategory(e){
        this.formData.category = e.value;
        if (this.formData.category !=0 ) {
            this.second_category = [];
            this.second_category2.forEach(function(item){
                if (that.formData.category == item.p_code) {
                    that.second_category.push(item);
                }
            })
            this.formData.second_category = "";
        } else {
            this.second_category = this.second_category2;
        }
    },
    changeSecondCategory(e){
        this.formData.second_category = e.value;
        if (this.formData.second_category !=0 ) {
            this.second_category.forEach(function(item){
                if (that.formData.second_category == item.code) {
                    that.select_category_code = item.p_code
                }
            })

            this.one_category.forEach(function(item){
                if (item.code == that.select_category_code) {
                     that.formData.category = item.code
                }
            })
        }
    },
    getDeliveryConf(){
      getDeliveryConf().then(res=>{
        this.DistrData = res.data
      })
    },
    time1(e){
      this.balancedata.start_time = e[0]
      this.balancedata.end_time = e[1]
    },
    mtAmountList(){
      mtAmountList(this.balancedata).then(res=>{
        this.mt_amount = res.data.mt_amount
        this.total1 = res.data.total
        this.data5 = res.data.data
      })
    },
    searchBtn1(){
      this.mtAmountList()
    },
    detailedBtn(){
      this.mtAmountList()
      this.balancemodal = true
    },
    mtinfo(){
      mtinfo().then(res=>{
        this.status = res.data.status
        this.info = res.data
        this.formData.category = parseInt(res.data.category)
        this.formData.second_category = parseInt(res.data.second_category)
        this.formData.distribution = res.data.distribution
        this.formData.it_disabled = res.data.it_disabled
        this.formData.delivery_rate = res.data.delivery_rate
        this.formData.appkey = res.data.appkey
        this.formData.secret = res.data.secret
        if (res.data.name) {
          this.formData.name = res.data.name
          this.formData.phone = res.data.phone
          this.formData.address = res.data.address
        }
      })
    },
    typeinfo(){
      typeinfo().then(res=>{
        this.one_category = res.data.category.one_category
        this.second_category = res.data.category.second_category
        this.second_category2 = res.data.category.second_category
        this.distribution = res.data.distribution

        this.formData.name = res.data.store.name
        this.formData.phone = res.data.store.phone
        this.formData.address = res.data.store.address
        this.mtinfo()
      })
    },
    addBtn(){
      if (this.DistrData.delivery_type==2) {
        this.formData.delivery_type = this.DistrData.delivery_type
       create(this.formData).then(res=>{
          this.$Message.success(res.msg)
          this.mtinfo()
        }).catch(err=>{
          this.$Message.error(err.msg)
        }) 
      }else{
        setDeliveryConf(this.DistrData).then(res=>{
          this.$Message.success(res.msg)
        }).catch(err=>{
          this.$Message.error(err.msg)
        }) 
      }
    },
    switchBtn(e){
      disabled({type:e}).then(res=>{
        this.$Message.success(res.msg)
      })
    },
    pageChange1(index){
      this.balancedata.page = index
      this.mtAmountList()
    },
    backBtn(){
        this.$emit('backview',0)
    },
  }
}
</script>

<style scoped>
.son-page{
  position: relative;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
  position: relative;
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.DW_txt{
  position: absolute;
  font-size: 20px;
  top: 40px;
  right: 40%;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.flex_text{
  display: flex;
  align-items: center;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.img_view_Box{
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-right: 20px;
  overflow: hidden;
}
.img_view_Box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.ml_20{
  margin-left: 20px;
}
.w100{
  width: 100px;
}
.line_box{
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: flex;
}
.left_box{
  text-align: center;
  border-right: 1px solid #CCCCCC;
}
.right_box{
  border-left: 1px solid #CCCCCC;
  text-align: center;
}
.center_box{
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  text-align: center;
}
.inpclass{
  border: none;
  outline: none;
  padding: 0 5px;
}
.num_Txt{
  color: #FF761D;
  font-size: 16px;
}
</style>
<style>
  .ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
