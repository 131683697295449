<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div><Icon size="24" type="ios-arrow-back" /></div>
      <div class="ml10">新人立减</div>
    </div>
    <div class="content_box">
      <div><Button @click="cardModalBtn('add')" class="btn">创建</Button></div>
      <Table stripe style="margin-top: 20px" :columns="columns12" :data="data6">
        <template slot-scope="{ index }" slot="index">
          <strong>{{ index + 1 }}</strong>
        </template>
        <template slot-scope="{ row }" slot="joins">
          <div>
            <div v-if="row.joins.indexOf('1') != -1">新客</div>
            <div v-if="row.joins.indexOf('2') != -1">会员</div>
            <div v-if="row.joins.indexOf('3') != -1">用户</div>
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button
            type="success"
            ghost
            size="small"
            v-if="row.status == 0"
            @click="push(row)"
            style="margin-right: 5px"
            >启用</Button
          >
          <!--                <Button type="success" ghost size="small" v-if="row.status==2" @click="push(row)" style="margin-right: 5px">发布</Button>-->
          <Button
            type="warning"
            ghost
            size="small"
            v-if="row.status == 1"
            @click="push(row)"
            style="margin-right: 5px"
            >停止</Button
          >
          <Button
            type="primary"
            @click="cardModalBtn(row.id)"
            v-if="row.status != 2"
            ghost
            size="small"
            style="
              margin-right: 5px;
              color: #2d8cf0;
              border: 1px solid #2d8cf0;
              background: none;
            "
            >编辑</Button
          >
          <Button type="error" @click="removeIt(row.id)" ghost size="small"
            >删除</Button
          >
        </template>
      </Table>
      <br />
      <Page
        :total="total"
        :current="storePrams.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange"
        :page-size="storePrams.limit"
      />
    </div>

    <Modal
      v-model="cardModal"
      :title="cardtitle"
      width="500"
      @on-cancel="cardCancel"
    >
      <Form style="width: 400px" label-position="right" :label-width="100">
        <FormItem label="活动名称：">
          <Input
            placeholder="请输入活动名称"
            v-model="formData.activity_title"
          ></Input>
        </FormItem>
        <FormItem label="分享时封面：">
          <div style="display: flex">
            <div @click="openModal(1)">
              <!-- <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgSuccess"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads"> -->
              <div class="uplads_box">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                </div>
              </div>
              <!-- </Upload> -->
            </div>
            <imageLibrary
              v-if="switch_modal"
              :switch_modal.sync="switch_modal"
              @change="costPlannedAmountChange"
              :select="imgtype"
            ></imageLibrary>
            <div class="upPic" v-if="activityPic">
              <img :src="activityPic" alt="" />
            </div>
          </div>
        </FormItem>
        <FormItem label="立减金额：">
          <Input
            placeholder="请输入立减金额"
            v-model="formData.dec_money"
          ></Input>
        </FormItem>
        <FormItem label="日期选择：">
          <DatePicker
            @on-change="time"
            v-model="times"
            type="daterange"
            placement="bottom-end"
            placeholder="点击选择开始结束日期"
            style="width: 300px"
          ></DatePicker>
        </FormItem>
        <FormItem label="参与人群：">
          <CheckboxGroup v-model="formData.joins">
            <Checkbox :label="1">新客</Checkbox>
            <Checkbox :label="2">会员</Checkbox>
            <Checkbox :label="3">普通用户</Checkbox>
          </CheckboxGroup>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cardCancel">取消</Button>
        <Button class="btn" @click="cardadd">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  declist,
  decadd,
  dechandle,
  details,
  decupdate,
  decdel,
} from "@/api/setting";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import imageLibrary from "@/components/diy/imageLibrary.vue";
export default {
  components: { imageLibrary },
  name: "password",
  data() {
    return {
      imgtype: 1,
      switch_modal: false,
      header: {
        "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
      },
      uploads: config.apiUrl + "/storeapi/data/uploads",
      cardtitle: "创建卡卷",
      cardModal: false,
      activityPic: "",
      times: [],
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
      },
      formData: {
        activity_img: "",
        activity_title: "",
        joins: [],
        dec_money: "",
        start_date: "",
        end_date: "",
      },
      columns12: [
        {
          title: "#",
          slot: "index",
          width: 60,
          align: "center",
        },
        {
          title: "活动名称",
          key: "activity_title",
          align: "center",
        },
        {
          title: "活动时间",
          key: "activity_time",
          align: "center",
        },
        {
          title: "立减金额",
          key: "dec_money",
          align: "center",
        },
        {
          title: "参与人群",
          slot: "joins",
          align: "center",
        },
        {
          title: "启用时间",
          key: "publish_time",
          align: "center",
        },
        {
          title: "停止时间",
          key: "stop_time",
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          fixed: "right",
          align: "center",
          slot: "action",
        },
      ],
      data6: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 事件处理函数
    async costPlannedAmountChange(param1) {
      console.log(param1);
      for (var i = 0; i < param1.length; i++) {
        this.activityPic = param1[i];
        this.formData.activity_img = param1[i];
        // this.new_data.content.imgs[this.formData.imgkey].picview = param1[i];
        // this.new_data.content.imgs[this.formData.imgkey].value = param1[i];
      }
      this.$Message.destroy();
    },
    openModal(e) {
      this.imgtype = e;
      this.switch_modal = true;
    },
    getList() {
      declist(this.storePrams).then((res) => {
        this.total = res.data.total;
        this.data6 = res.data.data;
      });
    },
    cardModalBtn(e) {
      if (e == "add") {
        this.cardtitle = "新建活动";
        this.cardModal = true;
      } else {
        details({ id: e }).then((res) => {
          this.times = [res.data.start_date, res.data.end_date];
          this.cardtitle = "编辑活动";
          this.cardModal = true;
          this.activityPic = res.data.activity_img_domain;
          this.formData.id = res.data.id;
          this.formData.end_date = res.data.end_date;
          this.formData.start_date = res.data.start_date;
          this.formData.joins = res.data.joins.map(Number);
          this.formData.dec_money = res.data.dec_money;
          this.formData.activity_title = res.data.activity_title;
          this.formData.activity_img = res.data.activity_img;
        });
      }
    },
    cardCancel() {
      this.cardModal = false;
      this.clearformData();
    },
    time(e) {
      this.formData.start_date = e[0];
      this.formData.end_date = e[1];
    },
    clearformData() {
      this.activityPic = "";
      this.times = [];
      this.formData = {
        activity_img: "",
        activity_title: "",
        joins: [],
        dec_money: "",
        start_date: "",
        end_date: "",
      };
    },
    push(e) {
      let status = 0,
        msg = "请问您是否确认启用活动，确定无误后点击确认发布。";
      if (e.status == 0) {
        status = 1;
      } else if (e.status == 1) {
        status = 2;
        msg = "请问您是否确认停止活动，确定无误后点击确认停止。";
      } else if (e.status == 2) {
        status = 1;
      }
      this.$Modal.confirm({
        title: "提示",
        content: msg,
        onOk: () => {
          dechandle({ id: e.id, status: status })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    removeIt(id) {
      this.$Modal.confirm({
        title: "删除提示",
        content: "请问您是否确认删除此活动，删除后您将无法恢复！！！",
        onOk: () => {
          decdel({ id: id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((res) => {
              this.$Message.error(res.msg);
            });
        },
        onCancel: () => {},
      });
    },
    cardadd() {
      if (this.cardtitle == "新建活动") {
        decadd(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.getList();
            this.cardModal = false;
            this.clearformData();
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else {
        decupdate(this.formData)
          .then((res) => {
            this.$Message.success(res.msg);
            this.getList();
            this.cardModal = false;
            this.clearformData();
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getList();
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.activityPic = e.data.path;
      this.formData.activity_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    backBtn() {
      this.$emit("backview", 0);
    },
  },
};
</script>

<style scoped>
/* .son-page{

} */
.back_box {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}
.content_box {
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
</style>
