import request from '@/utils/requestAPI'

export function api (data) {
    return request({
        url: '/api/',
        method: 'post',
        data
    })
}

export function appletscheck (data) {
    return request({
        url: '/api/appletscheck',
        method: 'post',
        data
    })
}