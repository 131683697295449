<template>
    <div class="">
        <div @click="backBtn" class="back_box">
            <div class="pointer"><Icon style="color:#515A6E" size="24" type="ios-arrow-back" /></div>
            <div class="ml10">积分设置</div>
        </div>
        <div class="page_box">
            <div class="line_box">
                <div class="title">每充值1元增加：</div>
                <div><input v-model="formData.grow_recharge" class="inp_style" type="text"></div>
                <div class="btn_txt">积分</div>
            </div>
            <div class="line_box">
                <div class="title">微信每次付款1元增加：</div>
                <div><input v-model="formData.grow_wx_pay" class="inp_style" type="text"></div>
                <div class="btn_txt">积分</div>
            </div>
            <div class="line_box">
                <div class="title">支付宝每次付款1元增加：</div>
                <div><input v-model="formData.grow_zfb_pay" class="inp_style" type="text"></div>
                <div class="btn_txt">积分</div>
            </div>
            <div class="line_box">
                <div class="title">余额每次付款1元增加：</div>
                <div><input v-model="formData.grow_yue_pay" class="inp_style" type="text"></div>
                <div class="btn_txt">积分</div>
            </div>
            <div class="line_box">
                <div class="title">积分通知：</div>
                <div>
                    <i-switch v-model="formData.is_notice" :true-value="1" :false-value="0">
                        <!-- <span slot="open">开启</span>
                        <span slot="close">关闭</span> -->
                    </i-switch>
                </div>
                <div class="tishi_txt">（开启后，积分变动将会发出短信通知）</div>
            </div>
            <div class="line_box">
                <div class="title">积分通知列表：</div>
                <div><Button @click="lookBtn">查 看</Button></div>
            </div>
            <div class="line_box">
                <div class="title">积分抵扣：</div>
                <div>
                    <i-switch v-model="formData.is_discount" :true-value="1" :false-value="0">
                        <!-- <span slot="open">开启</span>
                        <span slot="close">关闭</span> -->
                    </i-switch>
                </div>
                <!-- <div class="btn_txt">积分</div> -->
            </div>
            <span v-if="formData.is_discount==1">
                <div class="line_box">
                    <div class="title"></div>
                    <div><input v-model="formData.money_discount_num" style="width:100px" class="inp_style" type="text"></div>
                    <div style="color:#000" class="ml10">积分抵扣一元</div>
                </div>
                <div class="line_box">
                    <div class="title"></div>
                    <div style="color:#000">最高抵扣当前订单的</div>
                    <div><input v-model="formData.max_discount_rate" style="width:100px" placeholder="百分百" class="inp_style ml10" type="text"></div>
                    <div style="color:#000" class="ml10">%</div>
                </div>
            </span>

            <div class="bottom_btn">
                <Button @click="configBtn" class="btn">保存</Button>
            </div>
        </div>

        <Modal v-model="modalName" width="900">
            <p slot="header">积分通知列表</p>
            <div>
                <Form inline label-position="left" :label-width="90">
                    <FormItem label="手机号：">
                        <Input v-model="storePrams.keyword" style="width: 200px" placeholder="输入手机号"></Input>
                    </FormItem>
                    <FormItem style="margin-left:20px" label="日期选择：">
                        <DatePicker @on-change="timeChange" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 200px"></DatePicker>
                    </FormItem>
                    <FormItem style="margin-left:20px" label="">
                        <Button @click="searchBtn">搜 索</Button>
                    </FormItem>
                </Form>
                <Table stripe style="margin-top:20px" :columns="columns" :data="data">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="send_status">
                        <div v-if="row.send_status==1">成功</div>
                        <div v-if="row.send_status==2">失败</div>
                    </template>
                </Table>
                <br/>
                <Page :total="total"
                    :current="storePrams.page"
                    style="text-align:right"
                    show-elevator
                    show-total
                    @on-change="pageChange"
                    :page-size="storePrams.limit"/>
            </div>
            <div slot="footer">
                <!-- <Button type="error" size="large" long :loading="modal_loading" @click="del">Delete</Button> -->
            </div>
        </Modal>

    </div>
</template>

<script>
    import {integralgetConf,integralsetConf,getSmsRecord} from '@/api/setting'
    export default {
        data(){
            return{
                total:0,
                storePrams:{
                    start_time:'',
                    end_time:'',
                    keyword:'',
                    page:1,
                    limit:6,
                },
                modalName:false,
                formData:{},
                columns: [
                    {
                        title: '#',
                        slot: 'index',
                        width:60,
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        key: 'phone',
                        align: 'center'
                    },
                    {
                        title: '内容',
                        key: 'content',
                        align: 'center'
                    },
                    {
                        title: '时间',
                        key: 'updated_at',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'send_status',
                        align: 'center'
                    }
                ],
                data:[]
            }
        },
        created(){
            this.getConf()
        },
        methods:{
            getConf(){
                integralgetConf().then(res=>{
                    this.formData = res.data
                })
            },
            getSmsRecord(){
                getSmsRecord(this.storePrams).then(res=>{
                    this.data = res.data.data
                    this.total = res.data.total
                    console.log(this.data);
                })
            },
            lookBtn(){
                this.getSmsRecord()
                this.modalName = true
            },
            configBtn(){
                integralsetConf(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            searchBtn(){
                this.getSmsRecord()
            },
            timeChange(e){
                this.storePrams.start_time = e[0]
                this.storePrams.end_time = e[1]
            },
            backBtn(){
                this.$parent.goback()
            },
            pageChange(index){
                this.storePrams.page = index
            }
        }
    }
</script>

<style scoped>
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    padding: 30px;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
}
.page_box{
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 75vh;
    background: #FFF;
    padding: 50px 0px;
}
.line_box{
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.title{
    width: 200px;
    color: #000000;
    text-align: right;
}
.inp_style{
    border: none;
    outline: none;
    background: #F8F8F8;
    padding: 0 20px;
    width: 240px;
    height: 36px;
    border-radius: 3px;
    color: #999;
}
.btn_txt{
    width: 80px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #000000;
    border: 1px solid #e9e9e9;
}
.tishi_txt{
    color: #FE761D;
    font-size: 12px;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.btn{
    background:#FF761D;
    color:#FFF;
    border-color: #FF761D;
}
</style>