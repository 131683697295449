<template>
  <div>
      <div>
      <!-- formData.type == 6 公告组件-->
      <div class="style_box">
        <div>
          <div class="title_box">
            <div class="title_text">
              <Icon style="font-size: 20px" type="ios-arrow-down" />公告编辑
            </div>
          </div>
          <div style="margin: 10px 0">
            <Form label-position="right" :label-width="70">
              <FormItem style="margin: 0 0 10px 0" label="文字内容">
                <Input
                  v-model="formData.content.text"
                  placeholder="请输入文字内容"
                ></Input>
              </FormItem>
              <FormItem style="margin: 0 0 10px 0" label="背景色">
                <ColorPicker v-model="formData.content.bgColor" />
              </FormItem>
              <FormItem label="字号">
                <div class="just_sb">
                  <Input
                    v-model="formData.content.size"
                    placeholder="请输入字号"
                    style="width: 130px"
                  ></Input>
                  <ColorPicker v-model="formData.content.textColor" />
                </div>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        formData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
        }
    },
};
</script>

<style scoped>
.style_box {
  padding: 0 10px;
}
.title_box {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.title_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.just_sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
} 
</style>