<template>
    <div>
        <div>
            <Form inline :label-width="0">
                <FormItem label="">
                    <Input placeholder="请输入酒水品牌、手机号、姓名" v-model="storePrams.keyword" style="width: 220px;margin-right: 20px"/>
                </FormItem>
                <FormItem label="">
                    <DatePicker @on-change="time" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 220px;margin-right: 20px"></DatePicker>
                </FormItem>
                <FormItem label="">
                    <Select v-model="storePrams.status" style="width:220px">
                        <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.value }}</Option>
                    </Select>
                </FormItem>
                <FormItem style="margin-left:20px">
                    <div style="display:flex">
                        <Button @click="searchBtn" class="btn">查询</Button>
                        <Button @click="daochu" style="margin-left:20px">导出</Button>
                    </div>
                </FormItem>
            </Form>
            <Button @click="recordBtn" class="btn">新增存酒记录</Button>
            <div>
                <Table stripe style="margin-top:20px" :columns="columns" :data="data">
                    <template slot-scope="{ row }" slot="status">
                        <div style="color:#FF853B" v-if="row.status==1">待审核</div>
                        <div style="color:#4EDB7C" v-if="row.status==2">储存中</div>
                        <div style="color:#999999" v-if="row.status==1001">已失效</div>
                        <div style="color:#FF0000" v-if="row.status==1002">已拒绝</div>
                        <div style="color:#999999" v-if="row.status==1003">已过期</div>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <div class="btn_box">
                            <Button @click="wineBtn(row)" v-if="row.status==2" type="success" ghost size="small" style="margin-right: 5px;border:1px solid #42BBF1;color:#42BBF1">取酒</Button>
                            <Button @click="examineBtn(row)" v-if="row.status==1" type="warning" ghost size="small" style="margin-right: 5px">审核</Button>
                            <Button @click="lookBtn(row)" type="primary"  ghost size="small" style="margin-right: 5px;color:#2D8CF0;border:1px solid #2D8CF0;background:none">查看</Button>
                            <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
                        </div>
                    </template>
                </Table>
                <br/>
                <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
            </div>
        </div>

        <!-- 新增存酒记录 -->
        <Modal
            v-model="recordModal"
            title="新增存酒记录"
            @on-cancel="recordCancel">
            <Form :label-width="100">
                <FormItem label="酒水品牌：">
                    <Select v-model="recordData.brand_id">
                        <Option v-for="item in WineList" :value="item.id" :key="item.id">{{ item.brand_name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="联系名称：">
                    <Input placeholder="请输入联系名称" v-model="recordData.name"/>
                </FormItem>
                <FormItem label="联系电话：">
                    <Input type="number" placeholder="请输入联系电话" v-model="recordData.mobile"/>
                </FormItem>
                <FormItem label="寄存数量：">
                    <Input type="number" placeholder="请输入寄存数量" v-model="recordData.num"/>
                </FormItem>
                <FormItem label="备注：">
                    <Input type="textarea" placeholder="请输入备注" v-model="recordData.remarks"/>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button class="btn" @click="recordCancel">取消</Button>
                <Button class="btn" @click="recordOk">确认</Button>
            </div>
        </Modal>

        <!-- 寄存审核 -->
        <Modal
            width="400"
            v-model="examineModal"
            title="审核"
            @on-cancel="examineCancel">
            <Form :label-width="100">
                <FormItem style="margin:0" label="订单编号：">
                    {{WineData.number_no}}
                </FormItem>
                <FormItem style="margin:0" label="酒水名称：">
                    {{WineData.brand_name}}
                </FormItem>
                <FormItem style="margin:0" label="联系名称：">
                    {{WineData.name}}
                </FormItem>
                <FormItem style="margin:0" label="联系电话：">
                    {{WineData.mobile}}
                </FormItem>
                <FormItem style="margin:0" label="寄存时间：">
                    {{WineData.created_at}}
                </FormItem>
                <FormItem style="margin:0" label="过期时间：">
                    {{WineData.expire_time}}
                </FormItem>
                <FormItem style="margin:0" label="寄存数量：">
                    {{WineData.surplus_num}}
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="examineOk(1002)">拒绝</Button>
                <Button class="btn" @click="examineOk(2)">同意</Button>
            </div>
        </Modal>

        <!-- 取酒 -->
        <Modal
            v-model="wineModal"
            title="取酒"
            @on-cancel="wineCancel">
            <Form :label-width="100">
                <FormItem style="margin:0" label="订单编号：">
                    {{WineData.number_no}}
                </FormItem>
                <FormItem style="margin:0" label="酒水名称：">
                    {{WineData.brand_name}}
                </FormItem>
                <FormItem style="margin:0" label="联系名称：">
                    {{WineData.name}}
                </FormItem>
                <FormItem style="margin:0" label="联系电话：">
                    {{WineData.mobile}}
                </FormItem>
                <FormItem style="margin:0" label="寄存时间：">
                    {{WineData.created_at}}
                </FormItem>
                <FormItem style="margin:0" label="过期时间：">
                    {{WineData.expire_time}}
                </FormItem>
                <FormItem style="margin:0" label="寄存数量：">
                    {{WineData.surplus_num}}
                </FormItem>
                <FormItem style="margin:0" label="取酒数量：">
                    <Input v-model="num" type="number" placeholder="请输入取酒数量" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="wineCancel">取消</Button>
                <Button class="btn" @click="wineOk">确认</Button>
            </div>
        </Modal>

        <!-- 查看弹框 -->
        <Modal
            v-model="lookModal"
            title="查看">
            <Form :label-width="100">
                <FormItem style="margin:0" label="订单编号：">
                    {{WineData.number_no}}
                </FormItem>
                <FormItem style="margin:0" label="酒水名称：">
                    {{WineData.brand_name}}
                </FormItem>
                <FormItem style="margin:0" label="联系名称：">
                    {{WineData.name}}
                </FormItem>
                <FormItem style="margin:0" label="联系电话：">
                    {{WineData.mobile}}
                </FormItem>
                <FormItem style="margin:0" label="寄存数量：">
                    {{WineData.surplus_num}}
                </FormItem>
                <FormItem style="margin:0" label="寄存状态：">
                    <div v-if="WineData.status==1">待审核</div>
                    <div v-if="WineData.status==2">储存中</div>
                    <div v-if="WineData.status==1001">已失效</div>
                    <div v-if="WineData.status==1002">已拒绝</div>
                    <div v-if="WineData.status==1003">已过期</div>
                </FormItem>
                <FormItem style="margin:0" label="寄存备注：">
                    <div style="margin-top:6px;line-height:24px">
                        {{WineData.remarks?WineData.remarks:'暂无备注~'}}
                    </div>
                </FormItem>
                <FormItem style="margin:0" label="存酒时间：">
                    {{WineData.created_at}}
                </FormItem>
                <FormItem style="margin:0" label="过期时间：">
                    {{WineData.expire_time}}
                </FormItem>
            </Form>
            <div slot="footer">
                <Button class="btn" @click="lookOk">确认</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
    import {depositList,getBrand,depositAdd,depositApprove,depositTake,depositDel} from '@/api/setting'
    import config from '@/config';
    export default {
        data(){
            return{
                uploads: config.apiUrl + '/storeapi/alcohol/depositExport',
                typeList:[
                    {id:0,value:'全部'},
                    {id:1,value:'待审核'},
                    {id:2,value:'储存中'},
                    {id:1001,value:'已失效'},
                    {id:1002,value:'已拒绝'},
                    {id:1003,value:'已过期'}
                ],
                total:0,
                num:null,
                storePrams:{
                    keyword:'',
                    status:0,
                    start_time:'',
                    end_time:'',
                    page:1,
                    limit:10
                },
                columns:[
                    {
                        title: '订单编号',
                        key: 'number_no',
                        align: 'center'
                    },
                    {
                        title: '酒水品牌',
                        key: 'brand_name',
                        align: 'center'
                    },
                    {
                        title: '联系电话',
                        key: 'mobile',
                        align: 'center'
                    },
                    {
                        title: '联系姓名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '寄存时间',
                        key: 'created_at',
                        align: 'center'
                    },
                    {
                        title: '寄存剩余',
                        key: 'surplus_num',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot:'status',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 180,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                recordData:{
                    brand_id:null,
                    name:'',
                    mobile:'',
                    num:'',
                    remarks:''
                },
                data:[],
                WineList:[],
                WineData:{},
                recordModal:false,
                examineModal:false,
                wineModal:false,
                lookModal:false,
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){
                depositList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            recordBtn(){ // 打开存酒记录弹框
                this.recordModal = true
                this.getBrand()
            },
            getBrand(){ // 获取酒水品牌
                getBrand().then(res=>{
                    this.WineList = res.data
                })
            },
            recordOk(){ // 新增存酒记录弹框
                depositAdd(this.recordData).then(res=>{
                    this.$Message.success(res.msg)
                    this.recordCancel()
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            recordCancel(){ // 关闭存酒记录弹框
                this.recordModal = false
                this.recordData = {
                    brand_id:null,
                    name:'',
                    mobile:'',
                    num:'',
                    remarks:''
                }
            },
            examineBtn(e){ // 打开审核弹框
                this.WineData = e
                this.examineModal = true
            },
            examineOk(status){ // 审核操作按钮
                let data = {id:this.WineData.id,status:status}
                depositApprove(data).then(res=>{
                    this.$Message.success(res.msg)
                    this.examineModal = false
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            examineCancel(){ // 审核取消
                this.examineModal = false
            },
            wineBtn(e){ // 打开取酒弹框
                this.WineData = e
                this.wineModal = true
            },
            wineOk(){ // 取酒确定按钮
                let data = {id:this.WineData.id,num:this.num}
                depositTake(data).then(res=>{
                    this.$Message.success(res.msg)
                    this.wineCancel()
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            wineCancel(){ // 取酒取消按钮
                this.num = null
                this.wineModal = false
            },
            lookBtn(e){ // 查看安按钮
                this.WineData = e
                this.lookModal = true
            },
            lookOk(){ // 查看确定按钮
                this.lookModal = false
            },
            searchBtn(){ // 搜索
                this.getList()
            },
            daochu(){ // 导出
                location.href = `${this.uploads}?data_id=${this.$store.state.userInfo.data_id}&status=${this.storePrams.status}&keyword=${this.storePrams.keyword}&start_time=${this.storePrams.start_time}&end_time=${this.storePrams.end_time}`
            },
            time(e){ // 时间监听
                this.storePrams.start_time = e[0]
                this.storePrams.end_time = e[1]
            },
            delBtn(id){
                this.$Modal.confirm({
                    title: '您正使用删除操作',
                    content: '请问是否删除，删除后将无法恢复！！！',
                    onOk: () => {
                        depositDel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                });
            },
            pageChange(index){ // 分页
                this.storePrams.page = index
                this.getList()
            },
        }
    }
</script>

<style scoped>
    .btn{
        background:#FF761D;
        color:#FFF;
        border-color: #FF761D;
    }
    .btn_box{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>