<template>
    <div class="son-page">
        <div class="back_box pointer" @click="backBtn">
            <div ><Icon size="24" type="ios-arrow-back"/></div>
            <div class="ml10">自提点</div>
        </div>
        <div class="content_box">
            <Form label-position="left" :label-width="100">
                <FormItem label="开启多地址：">
                    <i-switch size="large" v-model="pickup_status" @on-change="kaiqi" :true-value="1" :false-value="0" >
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
            </Form>
            <span v-if="pickup_status">
                <Button @click="modalBtn('add')" class="btn">新建地址</Button>
                <Table stripe style="margin-top:20px" :columns="columns" :data="data">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        <i-switch @on-change="switchChange($event,row.id)" size="large" v-model="row.status" :true-value="1" :false-value="0" ></i-switch>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button type="primary" @click="modalBtn(row)" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border:1px solid #2D8CF0;background:none">编辑</Button>
                        <Button type="error" @click="removeIt(row.id)" ghost size="small">删除</Button>
                    </template>
                </Table>
                <br/>
                <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
            </span>
        </div>

        <Modal
            v-model="modal"
            :title="modalTitle"
            @on-ok="ok"
            @on-cancel="cancel">
            <Form style="padding:0 30px" ref="formData" hide-required-mark :model="formData" :rules="ruleInData" label-position="left" :label-width="80">
                <FormItem label="名称：" prop="title">
                    <Input placeholder="请输入名称" v-model="formData.title"></Input>
                </FormItem>
                <FormItem label="地址：" prop="address">
                    <Input type="textarea" placeholder="请输入地址" v-model="formData.address"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="ok('formData')" class="btn">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import {getPickupList,pickupDel,changeStorePickup,addPickup,changePick} from '@/api/setting'
    export default {
        data(){
            return{
                modal:false,
                total:0,
                storePrams:{
                    page:1,
                    limit:10,
                },
                pickup_status:0,
                columns:[
                    {
                        title: '#',
                        slot: 'index',
                        width:60,
                        align: 'center'
                    },
                    {
                        title: 'ID',
                        key: 'id',
                        align: 'center'
                    },
                    {
                        title: '名称',
                        key: 'title',
                        align: 'center'
                    },
                    {
                        title: '地址',
                        key: 'address',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'type',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 200,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                ruleInData:{
                    title: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请输入地址', trigger: 'blur' }
                    ],
                },
                data:[],
                modalTitle:'',
                formData:{
                    title:'',
                    address:''
                }
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){ // 获取列表
                getPickupList(this.storePrams).then(res=>{
                    this.pickup_status = res.data.pickup_status
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            kaiqi(e){
                changeStorePickup({pickup_status:e}).then(res=>{
                    this.$Message.success(res.msg)
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            switchChange(e,id){
                changePick({status:e,id:id}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            modalBtn(e){
                if (e=='add') {
                    this.modalTitle = '新建地址'
                }else{
                    this.formData.id = e.id
                    this.formData.title = e.title
                    this.formData.address = e.address
                    this.modalTitle = '编辑地址'
                }
                this.modal = true
            },
            ok(name){ // 弹框确定按钮
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        addPickup(this.formData).then(res=>{
                            this.$Message.success(res.msg)
                            this.cancel()
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    } else {
                        this.$Message.error('请完善表单!');
                    }
                })
            },
            cancel(){ // 弹框取消按钮
                this.$refs["formData"].resetFields();
                this.modal = false
                this.formData = {
                    title:'',
                    address:''
                }
            },
            removeIt(id){ // 删除
                this.$Modal.confirm({
                    title: '删除提示',
                    content: '请问您是否确认删除，删除后您将无法恢复！！！',
                    onOk: () => {
                        pickupDel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(res => {
                            this.$Message.error(res.msg)
                        })
                    },
                    onCancel: () => {}
                });
            },
            pageChange(index){ // 分页
                this.storePrams.page = index
                this.getList()
            },
            backBtn() { // 返回
                this.$emit('backview', 0)
            }
        }
    }
</script>

<style scoped>
    .back_box{
        width: 100%;
        background: #FFF;
        border-radius: 12px;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        align-items: center;
        padding: 30px;
    }
    .content_box{
        width: 100%;
        height: 75vh;
        overflow: auto;
        margin-top: 30px;
        background: #FFF;
        border-radius: 12px;
        padding: 30px;
    }
    .ivu-switch-checked{
        border-color: #FF761D;
        background-color: #FF761D;
    }
    .btn{
        background:#FF761D;
        color:#FFF;
        border-color: #FF761D;
    }
</style>