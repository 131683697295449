<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">账户设置</div>
      </div>
      <div class="content_box">
        <Tabs value="1" @on-click="changeTab">
          <TabPane label="账户列表" name="1"></TabPane>
          <TabPane label="角色权限" name="2"></TabPane>
        </Tabs>
        <div v-if="index=='1'">
          <div><Button @click="accountBtn('add')" class="btn">新增账号</Button></div>
          <Table stripe style="margin-top:20px" :columns="columns1" :data="data1">
              <template slot-scope="{ index }" slot="index">
                  <strong>{{ index+1 }}</strong>
              </template>
              <template slot-scope="{ row }" slot="action">
                  <Button type="primary" @click="accountBtn(row)" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border-color:#2D8CF0;background:none">编辑</Button>
                  <Button type="error" @click="delBtn(row.id)" ghost size="small">删除</Button>
              </template>
          </Table>
          <br/>
          <Page :total="total"
                :current="storePrams.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange"
                :page-size="storePrams.limit"/>
        </div>
        <div v-if="index=='2'">
          <div><Button @click="roleBtn('add')" class="btn">新增角色</Button></div>
          <Table stripe style="margin-top:20px" :columns="columns2" :data="data2">
              <template slot-scope="{ index }" slot="index">
                  <strong>{{ index+1 }}</strong>
              </template>
              <template slot-scope="{ row }" slot="action">
                  <Button type="primary" @click="roleBtn(row.id)" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border-color:#2D8CF0;background:none">编辑</Button>
                  <Button type="error" @click="delBtn1(row.id)" ghost size="small">删除</Button>
              </template>
          </Table>
          <br/>
          <Page :total="total1"
                :current="storePrams1.page"
                style="text-align:right"
                show-elevator
                show-total
                @on-change="pageChange1"
                :page-size="storePrams1.limit"/>
        </div>

      </div>
      <Modal
          v-model="accountModal"
          :title="accounttitle"
          width="500"
          @on-cancel="accountCancel">
          <Form style="width:400px" label-position="right" :label-width="100">
              <FormItem label="账户：">
                  <Input placeholder="请输入账户" v-model="formData.account"></Input>
              </FormItem>
              <FormItem label="密码：">
                  <Input type="password" placeholder="请输入密码" v-model="formData.password"></Input>
              </FormItem>
              <FormItem label="角色：">
                <Select v-model="formData.group_id" style="width:300px">
                    <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.title }}</Option>
                </Select>
              </FormItem>
              <FormItem label="插件：">
                <Select v-model="formData.plugin_ids" multiple :max-tag-count="2" :max-tag-placeholder="maxTagPlaceholder">
                    <Option v-for="item in PluginData" :value="item.id" :key="item.id">{{ item.title }}</Option>
                </Select>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="accountCancel">取消</Button>
              <Button class="btn" @click="accountadd">保存</Button>
          </div>
      </Modal>

      <Modal
          v-model="roleModal"
          :title="roletitle"
          width="800"
          @on-cancel="roleCancel">
          <div style="max-height:500px;overflow:auto">
            <Form style="width:360px" label-position="right" :label-width="100">
                <FormItem label="角色名称：">
                    <Input placeholder="请输入角色名称" v-model="formData1.title"></Input>
                </FormItem>
            </Form>
            <div class="jurisdiction_box">
              <div>
                <Form style="width:360px" label-position="right" :label-width="100">
                    <FormItem label="导航权限：">
                      <Tree ref="tree" :data="data3" show-checkbox></Tree>
                    </FormItem>
                </Form>
              </div>
              <div>
                <Form style="width:360px" label-position="right" :label-width="120">
                    <FormItem label="APP导航权限：">
                      <Tree ref="apptree" :data="appjurisdiction" show-checkbox></Tree>
                    </FormItem>
                </Form>
              </div>
            </div>
          </div>
          <div slot="footer">
              <Button @click="roleCancel">取消</Button>
              <Button class="btn" @click="roleadd">保存</Button>
          </div>
      </Modal>
  </div>
</template>

<script>
import {indexGroup,addGroup,infoGroup,editGroup,delGroup,indexUser,lsGroup,addUser,editUser,delUser,listMenu,appMenu,getPlugin} from '@/api/setting'
export default {
  name: "password",
  data() {
    return {
      index:'1',
      accounttitle:'新增账号',
      roletitle:'新增角色',
      accountModal:false,
      roleModal:false,
      total:0,
      total1:0,
      storePrams:{
        page:1,
        limit:10,
      },
      storePrams1:{
        page:1,
        limit:10,
      },
      formData:{
        account:'',
        password:'',
        group_id:'',
        plugin_ids:[]
      },
      formData1:{
        title:'',
        status:1,
        rules:[],
        app_rules:[]
      },
      cityList: [],
      columns1: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '账户',
              key: 'account',
              align: 'center'
          },
          {
              title: '角色',
              key: 'group_name',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      data1: [],
      columns2: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '角色名称',
              key: 'title',
              align: 'center'
          },
          {
              title: '创建时间',
              key: 'created_at',
              align: 'center'
          },
          {
              title: '操作',
              width: 160,
              fixed: 'right',
              align: 'center',
              slot: 'action'
          }
      ],
      data2: [],
      data3: [],
      appjurisdiction: [],
      PluginData:[]
    }
  },
  created() {
    this.indexGroup()
    this.indexUser()
    this.lsGroup()
    this.getPlugin()
  },
  methods: {
    indexGroup(){
      indexGroup(this.storePrams1).then(res=>{
        this.total1 = res.data.total1
        this.data2 = res.data.data
      })
    },
    getPlugin(){
      getPlugin().then(res=>{
        console.log(res);
        this.PluginData = res.data
      })
    },
    indexUser(){
      indexUser(this.storePrams).then(res=>{
        this.total = res.data.total1
        this.data1 = res.data.data
      })
    },
    maxTagPlaceholder (num) {
      return '更多 '+ num;
    },
    listMenu(){
      listMenu().then(res=>{
        this.data3 = res.data
        if (this.formData1.rules.length!=0) {
          this.shai(this.data3,this.formData1.rules)
        }
      })
    },
    appMenu(){
      appMenu().then(res=>{
        this.appjurisdiction = res.data
        if (this.formData1.app_rules.length!=0) {
          this.shai(this.appjurisdiction,this.formData1.app_rules)
        }
      })
    },
    shai(data,rules){
      data.forEach((item) => {
        if (rules.indexOf(item.id)!=-1) {
          this.$set(item, "expand", true);
          if (item.children.length!=0) {
            this.$set(item, "checked", false);
          }else{
            this.$set(item, "checked", true);
          }
          if (item.children.length!=0) {
            this.shai(item.children,rules)
          }
        }
      });
    },
    lsGroup(){
      lsGroup().then(res=>{
        this.cityList = res.data
      })
    },
    accountBtn(e){
      if (e=='add') {
        this.accounttitle = '新增账号'
        this.accountModal = true
      }else{
        this.formData.id = e.id
        this.formData.account = e.account
        this.formData.group_id = e.group_id
        this.formData.plugin_ids = e.plugin_ids
        this.accounttitle = '编辑账号'
        this.accountModal = true
      }
    },
    accountCancel(){
      this.accountModal = false
      this.clearForm()
    },
    accountadd(){
      if (this.accounttitle == '新增账号') {
        addUser(this.formData).then(res=>{
          this.$Message.success(res.msg)
          this.indexUser()
          this.accountModal = false
          this.clearForm()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        editUser(this.formData).then(res=>{
          this.$Message.success(res.msg)
          this.indexUser()
          this.accountModal = false
          this.clearForm()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }
    },
    roleBtn(e){
      if (e=='add') {
        this.listMenu()
        this.appMenu()
        this.roletitle = '新增角色'
        this.roleModal = true
      }else{
        infoGroup({id:e}).then(res=>{
          this.formData1.rules = res.data.rules
          this.formData1.app_rules = res.data.app_rules
          this.listMenu()
          this.appMenu()
          this.formData1.id = res.data.id
          this.formData1.title = res.data.title
        })
        this.roletitle = '编辑角色'
        this.roleModal = true
      }
    },
    roleCancel(){
      this.roleModal = false
      this.clearForm1()
    },
    roleadd(){
      if (this.roletitle == '新增角色') {
        this.formData1.rules = []
        this.$refs.tree.getCheckedAndIndeterminateNodes().map((node) => {
          this.formData1.rules.push(node.id)
        })
        console.log( this.formData1)
        this.$refs.apptree.getCheckedAndIndeterminateNodes().map((node) => {
          this.formData1.app_rules.push(node.id)
        })
        addGroup(this.formData1).then(res=>{
          this.$Message.success(res.msg)
          this.indexGroup()
          this.roleModal = false
          this.clearForm1()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        this.formData1.rules = []
        this.formData1.app_rules = []
        this.$refs.tree.getCheckedAndIndeterminateNodes().map((node) => {
          this.formData1.rules.push(node.id)
        })
        this.$refs.apptree.getCheckedAndIndeterminateNodes().map((node) => {
          this.formData1.app_rules.push(node.id)
        })
        editGroup(this.formData1).then(res=>{
          this.$Message.success(res.msg)
          this.indexGroup()
          this.roleModal = false
          this.clearForm1()
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }
    },
    clearForm1(){
      this.formData1 = {
        title:'',
        status:1,
        rules:[],
        app_rules:[]
      }
    },
    clearForm(){
      this.formData = {
        account:'',
        password:'',
        group_id:'',
        plugin_ids:[]
      }
    },
    pageChange(index){
      this.storePrams.page = index
    },
    delBtn1(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delGroup({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexGroup()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    delBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用删除功能`,
        onOk: () => {
          delUser({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.indexUser()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    pageChange1(index){
      this.storePrams1.page = index
    },
    changeTab(e){
      if (e=='1') {
        this.indexUser()
        this.lsGroup()
      }
      this.index = e
    },
    //文件上传类型错误
    handleFormatError () {
        this.$Message.warning('暂不支持上传此格式');
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e){
        // this.imgview = e.data.path
        // this.formdata.level_img = e.data.src
        this.$Message.destroy()
        this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress(){
        const msg = this.$Message.loading({
            content: '上传中...',
            duration: 0
        });
        setTimeout(msg, 3000);
    },
    backBtn(){
        this.$emit('backview',0)
    },
  }
}
</script>

<style scoped>
.son-page{
  position: relative;
}
.back_box{
    cursor: pointer;
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.jurisdiction_box{
  display: flex;
  justify-content: space-between;
}
.flex_text{
  display: flex;
  align-items: center;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
</style>
<style>
  .ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color: #FF761D;
  background-color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
