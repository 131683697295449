<template>
    <div>
        <div>
            <Form inline :label-width="0">
                <FormItem style="margin-left:20px">
                    <div style="display:flex">
                        <Button @click="ModalBtn('add')" class="btn">新增酒品名称</Button>
                        <Button @click="delBtns" style="margin-left:20px">批量删除</Button>
                    </div>
                </FormItem>
            </Form>
            <div>
                <Table @on-selection-change="selectionChange" ref="selection" stripe :columns="columns" :data="data">
                    <template slot-scope="{ row }" slot="status">
                        <i-switch v-model="row.status" :true-value="1" :false-value="0"/>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="ModalBtn(row)" type="primary"  ghost size="small" style="margin-right: 5px;color:#2D8CF0;border:1px solid #2D8CF0;background:none">编辑</Button>
                        <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
                    </template>
                </Table>
                <br/>
                <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
            </div>
        </div>

        <!-- 寄存审核 -->
        <Modal
            v-model="BoxModal"
            :title="modalTitle"
            @on-ok="alcoholOk"
            @on-cancel="alcoholCancel">
            <Form :label-width="100">
                <FormItem label="酒品名称：">
                    <Input v-model="formeData.brand_name" placeholder="请输入酒品名称"></Input>
                </FormItem>
                <FormItem label="说明：">
                    <Input v-model="formeData.brand_content" type="textarea" placeholder="请输入说明"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="alcoholCancel">取消</Button>
                <Button class="btn" @click="alcoholOk">确定</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
    import {brandList,brandAdd,brandDel} from '@/api/setting'
    export default {
        data(){
            return{
                formeData:{
                    brand_name:'',
                    brand_content:''
                },
                BoxModal:false,
                modalTitle:'新增酒品名称',
                total:0,
                storePrams:{
                    page:1,
                    limit:10
                },
                columns:[
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: 'ID',
                        key: 'id',
                        align: 'center'
                    },
                    {
                        title: '酒品名称',
                        key: 'brand_name',
                        align: 'center'
                    },
                    {
                        title: '说明',
                        key: 'brand_content',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot:'status',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                data:[],
                ids:[],
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){
                brandList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            ModalBtn(e){ // 打开新增弹框
                if (e=='add') {
                    this.modalTitle = '新增酒品名称'
                }else{
                    this.formeData.id = e.id
                    this.formeData.brand_name = e.brand_name
                    this.formeData.brand_content = e.brand_content
                    this.modalTitle = '编辑酒品名称'
                }
                this.BoxModal = true
            },
            alcoholOk(){ // 酒水名称确定按钮
                brandAdd(this.formeData).then(res=>{
                    this.$Message.success(res.msg)
                    this.alcoholCancel()
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            alcoholCancel(){ // 酒水名称取消按钮
                this.formeData={
                    brand_name:'',
                    brand_content:''
                }
                this.BoxModal = false
            },
            selectionChange(row){ // 批量监听
                this.ids = []
                row.forEach(item=>{
                    this.ids.push(item.id)
                })
            },
            delBtn(id){
                this.$Modal.confirm({
                    title: '您正使用删除操作',
                    content: '请问是否删除，删除后将无法恢复！！！',
                    onOk: () => {
                        brandDel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                });
            },
            delBtns(){
                if (this.ids.length==0) return this.$Message.warning('请选择要批量删除的数据')
                this.$Modal.confirm({
                    title: '您正使用批量删除操作',
                    content: '请问是否删除，删除后将无法恢复！！！',
                    onOk: () => {
                        brandDel({id:this.ids}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                });
            },
            pageChange(index){ // 分页
                this.storePrams.page = index
                this.getList()
            },
        }
    }
</script>

<style scoped>
    .btn{
        background:#FF761D;
        color:#FFF;
        border-color: #FF761D;
    }
</style>