<template>
    <div ref="back_box" class="phone_box">
        <draggable
            v-model="formData"
            chosen-class="chosen"
            force-fallback="true"
            group="people"
            animation="500"
            @start="onStart"
            @end="onEnd"
        >
            <transition-group>
                <div v-for="(item, index) in formData" :key="index + 'a'" @click="clickActive(item, index)">
                    <div
                        :class="active_id === index ? 'item-active' : 'item'"
                    >
                        <img
                            @click.stop.prevent="delBtn(index)"
                            class="del_icon"
                            src="@/assets/imgs/del.png"
                            alt=""
                        />
                        <!-- item.type == 1 banner -->
                        <div v-if="item.type == 1">
                            <div
                                :style="`height:${item.height}px`"
                                class="swiper-container"
                            >
                                <div class="swiper-wrapper">
                                    <div
                                        v-for="(o, p) of item.content.imgList"
                                        :key="p + 'b'"
                                        class="swiper-slide"
                                    >
                                        <img
                                            style="
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            "
                                            :src="
                                                o.value.indexOf('http') == -1
                                                    ? o.picview
                                                    : o.value
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                        <!--item.type == 2 图片组件 -->
                        <div v-if="item.type == 2">
                            <div
                                @dragstart="dragstart($event)"
                                @dragend="dragend($event, item)"
                                :style="`height:${item.content.height}px;background:${item.content.bgColor}`"
                            >
                                <picGrid
                                    :message="item"
                                    :chooseIndex="active_id === index ? 1 : 0"
                                ></picGrid>
                            </div>
                        </div>
                        <!-- item.type == 3 商品推荐 -->
                        <div v-if="item.type == 3">
                            <div class="recommend_da_box">
                                <div
                                    :style="`font-size:${item.content.titleData.size}px;color:${item.content.titleData.textColor}`"
                                >
                                    {{ item.content.titleData.content }}
                                </div>
                                <div class="recommend">
                                    <div
                                        :style="`background:${item.content.bgColor}`"
                                        class="recommend_box"
                                    >
                                        <img
                                            :style="`padding:${item.content.paddingData.top}px ${item.content.paddingData.right}px ${item.content.paddingData.bottom}px ${item.content.paddingData.left}px`"
                                            src="@/assets/imgs/kuai.png"
                                            alt=""
                                        />
                                        <div class="shop_info">
                                            <div
                                                :style="`font-size:${item.content.textStyle.nameSize}px;color:${item.content.textStyle.nameColor}`"
                                            >
                                                商品名称
                                            </div>
                                            <div
                                                :style="`font-size:${item.content.textStyle.priceSize}px;color:${item.content.textStyle.priceColor}`"
                                            >
                                                ￥9.99
                                            </div>
                                        </div>
                                        <div
                                            :style="`font-size:${item.content.textStyle.descSize}px;color:${item.content.textStyle.descColor}`"
                                        >
                                            商品简介
                                        </div>
                                    </div>
                                    <div
                                        :style="`background:${item.content.bgColor}`"
                                        class="recommend_box"
                                    >
                                        <img
                                            :style="`padding:${item.content.paddingData.top}px ${item.content.paddingData.right}px ${item.content.paddingData.bottom}px ${item.content.paddingData.left}px`"
                                            src="@/assets/imgs/kuai.png"
                                            alt=""
                                        />
                                        <div class="shop_info">
                                            <div
                                                :style="`font-size:${item.content.textStyle.nameSize}px;color:${item.content.textStyle.nameColor}`"
                                            >
                                                商品名称
                                            </div>
                                            <div
                                                :style="`font-size:${item.content.textStyle.priceSize}px;color:${item.content.textStyle.priceColor}`"
                                            >
                                                ￥9.99
                                            </div>
                                        </div>
                                        <div
                                            :style="`font-size:${item.content.textStyle.descSize}px;color:${item.content.textStyle.descColor}`"
                                        >
                                            商品简介
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- item.type == 4 文字组件-->
                        <div v-if="item.type == 4">
                            <div
                                :ref="'textBox' + index"
                                @dragstart="dragstart($event)"
                                @dragend="dragend($event, item, index)"
                            >
                                <img
                                    style="bottom: -26px"
                                    class="move_icon"
                                    src="@/assets/imgs/bai.png"
                                    alt=""
                                />
                                <span
                                    :style="`font-size:${item.content.size}px;color:${item.content.textColor};font-family:${item.content.family};font-weight:${item.content.weight}`"
                                    >{{ item.content.text }}</span
                                >
                            </div>
                        </div>
                        <!-- item.type == 5 宫格组件 -->
                        <div
                            v-if="item.type == 5"
                            :style="`padding:${item.content.paddingData.topBottom}px ${item.content.paddingData.leftRight}px;background:${item.content.bgColor};`"
                        >
                            <div class="gongGe_box">
                                <div
                                    v-for="(o, p) of item.content.iconList"
                                    :key="p"
                                    class="item_box"
                                >
                                    <img
                                        :style="`width:${item.content.width}px;height:${item.content.height}px`"
                                        :src="o.picview"
                                        alt=""
                                    />
                                    <div
                                        :style="`font-size:${item.content.size}px;color:${item.content.textColor}`"
                                        class="gongGe_title"
                                    >
                                        {{ o.title }}
                                    </div>
                                    <div
                                        v-if="item.content.status"
                                        class="gongGe_title1"
                                    >
                                        {{ o.explain }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- item.type == 6 公告组件 -->
                        <div v-if="item.type == 6">
                            <div
                                :style="`background:${item.content.bgColor}`"
                                class="Notice_box"
                            >
                                <Icon
                                    :style="`font-size:${item.content.size}px;color:${item.content.textColor}`"
                                    type="md-volume-up"
                                />
                                <span
                                    :style="`font-size:${item.content.size}px;color:${item.content.textColor}`"
                                    >{{ item.content.text }}</span
                                >
                            </div>
                        </div>
                        <!-- item.type == 7 搜索组件 -->
                        <div
                            v-if="item.type == 7"
                            style="background: #fff"
                            :style="`padding:${item.content.paddingData.topBottom}px ${item.content.paddingData.leftRight}px`"
                        >
                            <div
                                class="searchBox"
                                :style="`background:${item.content.bgColor};border-radius:${item.content.radius}px;height:${item.content.height}px`"
                            >
                                <Icon type="ios-search" />
                                <div
                                    :style="`color:${item.content.textColor};font-size:${item.content.size}px`"
                                    style="margin-left: 5px"
                                >
                                    {{ item.content.text }}
                                </div>
                            </div>
                        </div>
                        <!-- item.type == 8 辅助空白 -->
                        <div v-if="item.type == 8">
                            <div
                                class="searchBox"
                                :style="`background:${item.content.bgColor};width:100%;height:${item.content.height}px`"
                            ></div>
                        </div>
                        <!-- item.type == 9 辅助线 -->
                        <div
                            v-if="item.type == 9"
                            :style="`padding:${item.content.paddingData.topBottom}px ${item.content.paddingData.leftRight}px`"
                        >
                            <div
                                :style="`border-top-width:${
                                    item.content.border == 'double' ? 3 : 1
                                }px; border-top-style:${
                                    item.content.border
                                };border-color: ${item.content.bgColor}`"
                            ></div>
                        </div>
                        <!-- item.type == 10 店铺信息 -->
                        <div
                            v-if="item.type == 10"
                            :style="`padding:${item.content.paddingData.topBottom}px ${item.content.paddingData.leftRight}px;background:${item.content.bgColor};border-radius:${item.content.radius}px;`"
                        >
                            <div v-if="item.content.template == 1">
                                <div
                                    class="store_name"
                                    :style="`color:${item.content.titleColor}`"
                                >
                                    互联造物
                                </div>
                                <div class="store_shop_name">
                                    热门商品：商品名称、商品名称、商品名称
                                </div>
                                <div>
                                    <img
                                        class="store_img"
                                        src="http://canyin.hulianzaowu.top/img/diy/goods.png"
                                    />
                                    <img
                                        class="store_img"
                                        src="http://canyin.hulianzaowu.top/img/diy/goods.png"
                                    />
                                    <img
                                        class="store_img"
                                        src="http://canyin.hulianzaowu.top/img/diy/goods.png"
                                    />
                                </div>
                                <div class="text_hui mb_5">
                                    营业时间：上午11:00-晚上00:00
                                </div>
                                <div class="just_sb mb_5">
                                    <div class="text_hui">
                                        地址：安徽合肥莲花电子产业园a座203室
                                    </div>
                                    <div class="text_huang">一键导航</div>
                                </div>
                                <div class="just_sb mb_5">
                                    <div class="text_hui">
                                        电话：18888888888
                                    </div>
                                    <div class="text_huang">一键拨号</div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="display_flex mb_5">
                                    <img
                                        class="store_img"
                                        src="http://canyin.hulianzaowu.top/img/diy/goods.png"
                                    />
                                    <div class="">
                                        <div
                                            class="store_name"
                                            :style="`color:${item.content.titleColor}`"
                                        >
                                            互联造物
                                        </div>
                                        <div class="text_hui">总销量2621份</div>
                                    </div>
                                </div>
                                <div class="text_hui mb_5 align_center">
                                    <img
                                        class="icon_o"
                                        src="@/assets/imgs/dp.png"
                                        alt=""
                                    />
                                    <div>营业时间：上午11:00-晚上00:00</div>
                                </div>
                                <div class="just_sb mb_5">
                                    <div class="text_hui align_center">
                                        <img
                                            class="icon_o"
                                            src="@/assets/imgs/addres.png"
                                            alt=""
                                        />
                                        <div>
                                            地址：安徽合肥莲花电子产业园a座203室
                                        </div>
                                    </div>
                                    <div class="navigation">导航</div>
                                </div>
                                <div class="just_sb mb_5">
                                    <div class="text_hui align_center">
                                        <img
                                            class="icon_o"
                                            src="@/assets/imgs/phone.png"
                                            alt=""
                                        />
                                        <div>电话：18888888888</div>
                                    </div>
                                    <div class="text_huang">
                                        <img
                                            style="width: 20px; height: 22px"
                                            src="@/assets/imgs/hphone.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
import Swiper from "swiper";
import draggable from "vuedraggable";
import { number } from "echarts";
import picGrid from "@/components/diy/picGrid.vue";
export default {
    components: { draggable, picGrid },
    props: {
        myArray: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            drag: false,
            formData: this.myArray,
            startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
            startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
            active_id: "",
        };
    },
    mounted() {
        this.initSwiper();
    },
    methods: {
        // 初始化轮播
        initSwiper() {
            var swiper = new Swiper(".swiper-container", {
                // pagination: '.swiper-pagination', //分页器
                observer: true,
                observeParents: true,
                loop: true, //循环
                spaceBetween: 30, // swiper-slide 间的距离为30
                autoplay: 5000, //时长
                autoplayDisableOnInteraction: false, //点击不会取消自动
            });
        },
        // 拖拽开始事件
        dragstart(e) {
            this.startclientX = e.clientX; // 记录拖拽元素初始位置
            this.startclientY = e.clientY;
        },
        // 拖拽完成事件
        dragend(e, item, ind) {
            let initWidth = 0;
            if (item.type == 4) {
                initWidth = this.$refs[`textBox${ind}`][0].clientWidth + 4;
            } else {
                initWidth = item.content.width;
            }
            let x = e.clientX - this.startclientX; // 计算偏移量
            let y = e.clientY - this.startclientY;
            item.content.location.left += x; // 实现拖拽元素随偏移量移动
            item.content.location.top += y;
            if (item.content.location.left <= 350 - initWidth) {
                item.content.location.left = item.content.location.left;
            } else {
                item.content.location.left = 350 - initWidth;
                item.content.location.left = item.content.location.left;
            }
            if (item.content.location.left < 0) {
                item.content.location.left = 0;
                item.content.location.left = item.content.location.left;
            }
            if (item.content.location.top >= 0) {
                item.content.location.top = item.content.location.top;
            } else {
                item.content.location.top = 0;
                item.content.location.top = item.content.location.top;
            }
        },
        // 删除
        delBtn(e) {
            this.$emit("clearStyle", " ");
            this.formData.splice(e, 1);
            this.active_id = "";
        },
        // 延时初始化
        timeout() {
            setTimeout(() => {
                this.initSwiper();
            }, 500);
        },
        setArray(e) {
            this.formData = e;
        },
        // 图片拖动
        movepic(e) {
            console.log(e);
        },
        // 移动时
        onStart(e) {
            this.drag = true;
            this.active_id = e.oldDraggableIndex;
        },
        // 移动结束
        onEnd(e) {
            // this.initSwiper()
            this.drag = false;
            this.active_id = e.newDraggableIndex;
            this.$emit("myArrayChange", this.formData, this.active_id);
        },
        goConsole(item) {
            console.log("itemitemitem", item);
        },
        clickActive(e, index) {
            console.log("点击事件", e, this.formData);
            this.$emit("contentBtn", e, index);
            this.active_id = index;
        },
        dealImgIndex(e) {
            this.formData[this.active_id] = e;
            this.$forceUpdate();
        },
    },
};
</script>

<style scoped>
* {
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早期浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
}
.phone_box {
    margin: 0px auto 0;
    width: 351px;
    height: 80vh;
    overflow: auto;
    background: #fff;
    position: relative;
}
.item {
    background-color: #fdfdfd;
    border: solid 2px #ffffff;
    cursor: pointer;
    position: relative;
}
.del_icon {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 9;
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.move_icon {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 9;
    width: 100%;
    height: 26px;
}
.item-active .del_icon {
    opacity: 1;
}
.item-active {
    border: solid 2px #ff761d !important;
    box-shadow: 5px 5px 3px 4px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 999;
    cursor: pointer;
}
.item:hover {
    border: dashed 2px #ff761d !important;
    cursor: pointer;
}
.chosen {
    border: dashed 2px #ff761d !important;
}

.swiper-container {
    width: 100%;
    height: 150px;
}

.recommend_da_box {
    padding: 0 10px;
}
.recommend {
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}
.recommend_box {
    width: 48%;
    height: 223px;
    background: #f5f5f5;
    border: 1px solid #eee;
}
.recommend_box img {
    width: 100%;
    height: 165px;
}
.shop_info {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Notice_box {
    display: flex;
    align-items: center;
    line-height: 18px;
}
.searchBox {
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.store_name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.store_shop_name {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
}
.store_img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.just_sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.display_flex {
    display: flex;
}
.align_center {
    display: flex;
    align-items: center;
}
.icon_o {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.text_hui {
    color: #999;
    font-size: 12px;
}
.text_huang {
    font-size: 12px;
    color: #ff761d;
}
.mb_5 {
    margin-bottom: 5px;
}
.navigation {
    background-image: linear-gradient(#fe9d23, #ffc46c);
    color: #fff;
    border-radius: 5px;
    padding: 2px 10px;
    font-size: 12px;
}
.gongGe_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.item_box {
    width: 33.33%;
    text-align: center;
    margin-bottom: 5px;
}
.gongGe_title {
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.gongGe_title1 {
    font-size: 12px;
    color: #999;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>

<style>
.swiper-pagination-bullet-active {
    background: #ff8430 !important;
}
</style>