<template>
    <div class="son-page">
        <div v-if="modal">
            <Addmodal @closeModal="closeModal" :modalData="modalData"></Addmodal>
        </div>
        <div v-else>
            <div @click="backBtn" class="back_box">
                <div class="pointer"><Icon style="color:#515A6E" size="24" type="ios-arrow-back" /></div>
                <div class="ml10">积分商城</div>
            </div>
            <div class="content_box">
                <div class="search_box">
                    <div><Button @click="modalBtn('add')" class="btn">创建</Button></div>
                    <div>
                        <Input style="width:200px" v-model="storePrams.keyword" placeholder="请输入"></Input>
                        <Button @click="searchBtn" class="ml10">搜索</Button>
                    </div>
                </div>
                <Table stripe style="margin-top:20px" :columns="columns" :data="dataShop">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="get_type">
                        <div v-if="row.get_type.indexOf('1')!=-1">自提</div>
                        <div v-if="row.get_type.indexOf('2')!=-1">快递</div>
                    </template>
                    <template slot-scope="{ row }" slot="order_num">
                        <Button @click="numModalBtn(row.id)" style="color:#FF761D;border-color:#FF761D">{{row.order_num}}</Button>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="modalBtn(row)" type="primary" ghost size="small" style="margin-right: 20px;color:#2D8CF0;border-color:#2D8CF0;background:none">编辑</Button>
                        <Button type="error" @click="delBtn(row.id)" ghost size="small">删除</Button>
                    </template>
                </Table>
                <br/>
                <Page :total="total"
                    :current="storePrams.page"
                    style="text-align:right"
                    show-elevator
                    show-total
                    @on-change="pageChange"
                    :page-size="storePrams.limit"/>
            </div>
        </div>

        <Modal v-model="numModal" width="900">
            <p slot="header">兑换数量</p>
            <div style="text-align:center">
                <Table :columns="columns12" :data="data6">
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div v-if="row.status==1">待取货</div>
                        <div v-if="row.status==2">已取货</div>
                        <div v-if="row.status==1001">待发货</div>
                        <div v-if="row.status==1002">已发货</div>
                    </template>
                    <template slot-scope="{ row }" slot="get_type">
                        <div v-if="row.get_type==1">自提</div>
                        <div v-if="row.get_type==2"><Button @click="infoBtn(row)">快递</Button></div>
                    </template>
                </Table>
                <br />
                <Page :total="total1"
                    :current="storePrams1.page"
                    style="text-align:right"
                    show-elevator
                    show-total
                    @on-change="pageChange1"
                    :page-size="storePrams1.limit"/>
            </div>
            <div slot="footer">
                <!-- <Button class="btn">确定</Button> -->
            </div>
        </Modal>

        <Modal v-model="infoModal" width="400">
            <p slot="header">快递信息</p>
            <div>
                <Form label-position="left" :label-width="100">
                    <FormItem style="margin:0" label="收货人：">
                        {{address_json.name}}
                    </FormItem>
                    <FormItem style="margin:0" label="联系方式：">
                        {{address_json.phone}}
                    </FormItem>
                    <FormItem label="收货地址：">
                        {{address_json.address}}
                    </FormItem>
                    <FormItem label="配送信息：">
                        {{distribution_info?distribution_info:'暂无配送信息'}}
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="infoModal = false" class="btn">确定</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
    import {goodsList,goodsOrder,goodsDel} from '@/api/setting'
    import Addmodal from "./components/addmodal";
    export default {
        components:{Addmodal},
        data(){
            return{
                total:0,
                total1:0,
                storePrams:{
                    keyword:'',
                    page:1,
                    limit:10,
                },
                storePrams1:{
                    id:0,
                    page:1,
                    limit:10,
                },
                modal:false,
                numModal:false,
                infoModal:false,
                modalData:{
                    title:'新增商品',
                    id:0,
                },
                columns: [
                    {
                        title: '#',
                        slot: 'index',
                        width:60,
                        align: 'center'
                    },
                    {
                        title: '商品名',
                        key: 'goods_name',
                        align: 'center'
                    },
                    {
                        title: '积分',
                        key: 'num',
                        align: 'center'
                    },
                    {
                        title: '提取方式',
                        slot: 'get_type',
                        align: 'center'
                    },
                    {
                        title: '库存数量',
                        key: 'stock_num',
                        align: 'center'
                    },
                    {
                        title: '已兑换数量',
                        slot: 'order_num',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                dataShop:[],
                columns12: [
                    {
                        title: '#',
                        slot: 'index',
                        width:60,
                        align: 'center'
                    },
                    {
                        title: '商品名',
                        key: 'goods_name',
                        align: 'center'
                    },
                    {
                        title: '会员名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        key: 'phone',
                        align: 'center'
                    },
                    {
                        title: '兑换码',
                        key: 'pickup_code',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        align: 'center'
                    },
                    {
                        title: '提货方式',
                        slot: 'get_type',
                        width: 150,
                        align: 'center'
                    }
                ],
                data6: [],
                distribution_info:'',
                address_json:{}
            }
        },
        created(){
            this.goodsList()
        },
        methods:{
            goodsList(){
                goodsList(this.storePrams).then(res=>{
                    this.dataShop = res.data.data
                    this.total = res.data.total
                })
            },
            goodsOrder(){
                goodsOrder(this.storePrams1).then(res=>{
                    this.total1 = res.data.total1
                    this.data6 = res.data.data
                })
            },
            searchBtn(){
                this.goodsList()
            },
            modalBtn(e){
                if (e=='add') {
                    this.modalData.title = '新增商品'
                    this.modal = true
                }else{
                    this.modalData.id = e.id
                    this.modalData.title = '编辑商品'
                    this.modal = true
                }
            },
            delBtn(id){
                this.$Modal.confirm({
                    title: '提示',
                    content: `您正在使用删除功能`,
                    onOk: () => {
                        goodsDel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.goodsList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                    onCancel: () => {
                    }
                });
            },
            closeModal(e){
                if (e=='add') {
                    this.goodsList()
                }
                this.modal = false
            },
            numModalBtn(id){
                this.storePrams1.id = id
                this.goodsOrder()
                this.numModal = true
            },
            infoBtn(e){
                this.distribution_info = e.distribution_info
                this.address_json = e.address_json
                this.infoModal = true
            },
            backBtn(){
                this.$parent.goback()
            },
            pageChange(index){
                this.storePrams.page = index
                this.goodsList()
            },
            pageChange1(){
                this.storePrams1.page = index
                this.goodsOrder()
            }
        }
    }
</script>

<style scoped>
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    padding: 30px;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.search_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn{
    background:#FF761D;
    color:#FFF;
    border-color: #FF761D;
}
</style>