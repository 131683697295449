<template>
    <div>
        <div class="back_box" @click="backBtn">
            <div >
                <Icon size="24" type="ios-arrow-back"/>
            </div>
            <div class="ml10">酒水寄存</div>
        </div>
        <div class="content_box">
            <Tabs value="1" @on-click="changeTab">
                <TabPane label="存酒记录" name="1">
                    <!-- <Storagerecord v-if="index==1"></Storagerecord> -->
                </TabPane>
                <TabPane label="取酒记录" name="2">
                    <!-- <Pickingrecord v-if="index==2"></Pickingrecord> -->
                </TabPane>
                <TabPane label="酒品牌" name="3">
                    <!-- <Winebrand v-if="index==3"></Winebrand> -->
                </TabPane>
                <TabPane label="存酒设置" name="4">
                    <!-- <Storagesettings v-if="index==4"></Storagesettings> -->
                </TabPane>
            </Tabs>
            <div>
                <Storagerecord v-if="index==1"></Storagerecord>
                <Pickingrecord v-if="index==2"></Pickingrecord>
                <Winebrand v-if="index==3"></Winebrand>
                <Storagesettings style="width:100%;height:100%" v-if="index==4"></Storagesettings>
            </div>
        </div>
    </div>
</template>

<script>
    import Storagerecord from "./components/storagerecord.vue";
    import Pickingrecord from "./components/pickingrecord.vue";
    import Winebrand from "./components/winebrand.vue";
    import Storagesettings from "./components/storagesettings.vue";
    export default {
        components:{Storagerecord,Pickingrecord,Winebrand,Storagesettings},
        data(){
            return{
                index:1,
            }
        },
        methods:{
            changeTab(e){
                this.index = e
            },
            // 返回
            backBtn() {
                this.$emit('backview', 0)
            }
        }
    }
</script>

<style scoped>
    .back_box{
        width: 100%;
        background: #FFF;
        border-radius: 12px;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        align-items: center;
        padding: 20px;
    }
    .content_box{
        width: 100%;
        height: 77vh;
        position: relative;
        overflow: auto;
        margin-top: 30px;
        background: #FFF;
        border-radius: 12px;
        padding: 10px 20px;
    }
</style>