var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"style_box"},[_c('div',[_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_text"},[_c('Icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"ios-arrow-down"}}),_vm._v("辅助线编辑 ")],1)]),_c('div',{staticStyle:{"margin":"10px 0"}},[_c('Form',{attrs:{"label-position":"right","label-width":70}},[_c('FormItem',{staticStyle:{"margin":"0 0 10px 0"},attrs:{"label":"背景色"}},[_c('ColorPicker',{model:{value:(_vm.formData.content.bgColor),callback:function ($$v) {_vm.$set(_vm.formData.content, "bgColor", $$v)},expression:"formData.content.bgColor"}})],1),_c('FormItem',{staticStyle:{"margin":"0 0 10px 0"},attrs:{"label":"线条样式"}},[_c('div',{staticClass:"border_box"},[_c('div',{staticClass:"border_style wei",style:(_vm.formData.content.border == 'solid'
                      ? 'border-color:#FF761D'
                      : ''),on:{"click":function($event){return _vm.lineBtn('solid')}}},[_c('div',{staticClass:"solid"})]),_c('div',{staticClass:"border_style wei",style:(_vm.formData.content.border == 'double'
                      ? 'border-color:#FF761D'
                      : ''),on:{"click":function($event){return _vm.lineBtn('double')}}},[_c('div',{staticClass:"double"})]),_c('div',{staticClass:"border_style wei",style:(_vm.formData.content.border == 'dashed'
                      ? 'border-color:#FF761D'
                      : ''),on:{"click":function($event){return _vm.lineBtn('dashed')}}},[_c('div',{staticClass:"dashed"})]),_c('div',{staticClass:"border_style wei",style:(_vm.formData.content.border == 'dotted'
                      ? 'border-color:#FF761D'
                      : ''),on:{"click":function($event){return _vm.lineBtn('dotted')}}},[_c('div',{staticClass:"dotted"})])])]),_c('FormItem',{staticStyle:{"margin":"0 0 10px 0"},attrs:{"label":"上下间距"}},[_c('div',[_c('Slider',{model:{value:(_vm.formData.content.paddingData.topBottom),callback:function ($$v) {_vm.$set(_vm.formData.content.paddingData, "topBottom", $$v)},expression:"formData.content.paddingData.topBottom"}})],1)]),_c('FormItem',{staticStyle:{"margin":"0 0 10px 0"},attrs:{"label":"左右间距"}},[_c('div',[_c('Slider',{model:{value:(_vm.formData.content.paddingData.leftRight),callback:function ($$v) {_vm.$set(_vm.formData.content.paddingData, "leftRight", $$v)},expression:"formData.content.paddingData.leftRight"}})],1)])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }