<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div >
        <Icon size="24" type="ios-arrow-back"/>
      </div>
      <div class="ml10">排队取号</div>
    </div>
    <div class="content_box">
        <Form label-position="left" :label-width="100">
            <FormItem label="语音开关：">
              <i-switch size="large" v-model="formData.status" :true-value="1" :false-value="0" >
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
              </i-switch>
            </FormItem>
            <span v-if="formData.status == 1">
              <FormItem label="api_key：">
                <Input style="width:360px" placeholder="请输入api_key" v-model="formData.api_key"></Input>
              </FormItem>
              <FormItem label="secret_key：">
                 <Input style="width:360px" placeholder="请输入secret_key" v-model="formData.secret_key"></Input>
              </FormItem>
              <div style="color:#999;font-size:12px;">注释：百度语音合成服务 <a href="https://ai.baidu.com/ai-doc/SPEECH/qknh9i8ed" target="_blank">https://ai.baidu.com/ai-doc/SPEECH/qknh9i8ed</a></div>
            </span>
            <Button @click="jumpBtn" class="JumpBtn">跳转排队取号</Button>
        </Form>
    </div>

    <div class="bottom_btn">
        <Button @click="addBtn" class="btn">保存</Button>
    </div>
  </div>
</template>

<script>
import {set_take_conf,get_take_conf} from '../../../../api/elseSetting'
import {getCookies} from '@/utils/helper'
export default {
  name: "password",
  data() {
    return {
      formData:  {
        status:0,
        api_key: '',
        secret_key: ''
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      get_take_conf().then(res => {
        this.formData.api_key = res.data.api_key
        this.formData.status = res.data.status
        this.formData.secret_key = res.data.secret_key
      })
    },
    addBtn(){
        set_take_conf(this.formData).then(res=>{
            this.$Message.success(res.msg)
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
    },
    jumpBtn(){
      let url = `${location.origin}/lineup/#/?token=${getCookies('hlzw_canyin_token')}`
      // let url = `http://192.168.1.199:90/lineup/#/?token=${getCookies('hlzw_canyin_token')}`
      window.open( url , '_blank' )
    },
    backBtn() {
      this.$emit('backview', 0)
    }
  }
}
</script>

<style scoped>
.son-page{
    position: relative;
}
.back_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.body_box{
    width: 100%;
    height: 500px;
    overflow: auto;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.chooseeMore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
.JumpBtn{
  margin-top: 20px;
}
</style>
