<template>
    <div>
        <div class="back_box pointer" @click="backBtn">
            <div  ><Icon size="24" type="ios-arrow-back" /></div>
             <div class="ml10">当面付</div>
        </div>
        <div class="content_box">
            <Form label-position="left" :label-width="140">
                <FormItem label="是否开启当面付：">
                    <i-switch @on-change="switchChange" v-model="formData.status" :true-value="1" :false-value="0" size="large">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <FormItem v-if="formData.status" label="小程序码：">
                    <div><img class="code_pic" :src="formData.face_qrcode" alt=""></div>
                    <div class="btn_box"><Button @click="refreshBtn" style="background:#FF761D;color:#FFF;border-color:#FF761D">{{formData.face_qrcode?'刷新二维码':'生成二维码'}}</Button></div>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
    import {getPayQrcode,setPayQrcode,updateFaceStatus} from '@/api/setting'
    export default {
        data(){
            return{
                formData:{}
            }
        },
        created(){
            this.getPayQrcode()
        },
        methods:{
            getPayQrcode(){
                getPayQrcode().then(res=>{
                    this.formData = res.data
                })
            },
            switchChange(e){
                updateFaceStatus().then(res=>{
                    this.$Message.success(res.msg)
                })
            },
            refreshBtn(){
                setPayQrcode().then(res=>{
                    this.getPayQrcode()
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            backBtn(){
                this.$emit('backview',0)
            }
        }
    }
</script>

<style scoped>
.back_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.btn_box{
    width: 200px;
    display: flex;
    justify-content: center;
}
.code_pic{
    width: 200px;
    height: 200px;
}
</style>