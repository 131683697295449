<template>
  <div>
    <div v-if=" message.content.imgs !== undefined ">
      <div
        v-if="message.imgtype == 0"
        :style="`height:${message.content.height}px;background:${message.content.bgColor}`"
      >
        <div
          :style="`width:100%;height:${message.content.height}px;position: relative;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="message.imgtype == 1"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:40%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:60%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 1)"
          :class="{ active: activeDiv === 1 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[1].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="message.imgtype == 2"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:40%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:60%;height:${message.content.height}px;`">
          <div
            :style="`width:100%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 1)"
            :class="{ active: activeDiv === 1 && chooseIndex}"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[1].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:100%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 2)"
            :class="{ active: activeDiv === 2 && chooseIndex}"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[2].picview"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        v-if="message.imgtype == 3"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:40%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div :style="`width:60%;height:${message.content.height}px;`">
          <div
            :style="`width:100%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 1)"
            :class="{ active: activeDiv === 1 && chooseIndex}"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[1].picview"
              alt=""
            />
          </div>
          <div style="display: flex">
            <div
              :style="`width:50%;height:${message.content.height / 2}px;`"
              class="small-div"
              @click="toggleStyle(message.imgtype, 2)"
              :class="{ active: activeDiv === 2 && chooseIndex}"
            >
              <img
                :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
                :src="message.content.imgs[2].picview"
                alt=""
              />
            </div>
            <div
              :style="`width:50%;height:${message.content.height / 2}px;`"
              class="small-div"
              @click="toggleStyle(message.imgtype, 3)"
              :class="{ active: activeDiv === 3 && chooseIndex}"
            >
              <img
                :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
                :src="message.content.imgs[3].picview"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="message.imgtype == 4"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:50%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:50%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 1)"
          :class="{ active: activeDiv === 1 && chooseIndex}"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[1].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="message.imgtype == 5"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:33.3%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:33.3%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 1)"
          :class="{ active: activeDiv === 1 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[1].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:33.3%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 2)"
          :class="{ active: activeDiv === 2 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[2].picview"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="message.imgtype == 6"
        :style="`height:${message.content.height}px;display:flex`"
      >
        <div
          :style="`width:25%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 0)"
          :class="{ active: activeDiv === 0 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[0].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:25%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 1)"
          :class="{ active: activeDiv === 1 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[1].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:25%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 2)"
          :class="{ active: activeDiv === 2 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[2].picview"
            alt=""
          />
        </div>
        <div
          :style="`width:25%;height:${message.content.height}px;`"
          class="small-div"
          @click="toggleStyle(message.imgtype, 3)"
          :class="{ active: activeDiv === 3 && chooseIndex }"
        >
          <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.imgs[3].picview"
            alt=""
          />
        </div>
      </div>
      <div v-if="message.imgtype == 7">
        <div
          :style="`width:100%;height:${
            message.content.height / 2
          }px;display:flex`"
        >
          <div
            :style="`width:50%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 0)"
            :class="{ active: activeDiv === 0 && chooseIndex }"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[0].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:50%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 1)"
            :class="{ active: activeDiv === 1 && chooseIndex }"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[1].picview"
              alt=""
            />
          </div>
        </div>
        <div
          :style="`width:100%;height:${
            message.content.height / 2
          }px;display:flex`"
        >
          <div
            :style="`width:50%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 2)"
            :class="{ active: activeDiv === 2 && chooseIndex }"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[2].picview"
              alt=""
            />
          </div>
          <div
            :style="`width:50%;height:${message.content.height / 2}px;`"
            class="small-div"
            @click="toggleStyle(message.imgtype, 3)"
            :class="{ active: activeDiv === 3 && chooseIndex }"
          >
            <img
              :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
              :src="message.content.imgs[3].picview"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <img
            :style="`width:100%;height:100%;padding:${message.content.paddingData.top}px ${message.content.paddingData.right}px ${message.content.paddingData.bottom}px ${message.content.paddingData.left}px;object-fit: cover;`"
            :src="message.content.picview"
            alt=""
          />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: {},
    },
    chooseIndex: {
      type: Number,
      default: {},
    }
  },
  data() {
    return {
      activeDiv: null,
    };
  },
  methods: {
    toggleStyle(pic_type, index_pic) {
      this.message.imgkey = index_pic;
      // 激活样式
      this.activeDiv = index_pic;
    },
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}
.small-div {
  border: 1px dashed #ccc;
 
}
.active {
  border: 2px dashed #ff761d;
  box-shadow: 5px 5px 3px 4px rgba(0, 0, 0, 0.4);
}
</style>