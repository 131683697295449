<template>
    <div>
        <div>
            <Form inline :label-width="0">
                <FormItem label="">
                    <Input placeholder="请输入酒水品牌、手机号、姓名" v-model="storePrams.keyword" style="width: 220px;margin-right: 20px"/>
                </FormItem>
                <FormItem label="">
                    <DatePicker @on-change="time" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 220px;margin-right: 20px"></DatePicker>
                </FormItem>
                <FormItem label="">
                    <Select v-model="storePrams.status" style="width:220px">
                        <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.value }}</Option>
                    </Select>
                </FormItem>
                <FormItem style="margin-left:20px">
                    <div style="display:flex">
                        <Button @click="searchBtn" class="btn">查询</Button>
                        <Button @click="daochu" style="margin-left:20px">导出</Button>
                    </div>
                </FormItem>
            </Form>
            <div>
                <Table stripe :columns="columns" :data="data">
                    <template slot-scope="{ row }" slot="status">
                        <div style="color:#FF853B" v-if="row.status==1">待审核</div>
                        <div style="color:#42BBF1" v-if="row.status==2">已取酒</div>
                        <div style="color:#FF0000" v-if="row.status==1002">已拒绝</div>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button v-if="row.status==1" @click="examineBtn(row)" type="warning" ghost size="small" style="margin-right: 5px">审核</Button>
                    </template>
                </Table>
                <br/>
                <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
            </div>
        </div>

        <!-- 寄存审核 -->
        <Modal
            width="400"
            v-model="examineModal"
            title="审核"
            ok-text="同意"
            cancel-text="拒绝"
            @on-cancel="examineCancel">
            <Form :label-width="100">
                <FormItem style="margin:0" label="订单编号：">
                    {{WineData.number_no}}
                </FormItem>
                <FormItem style="margin:0" label="酒水名称：">
                    {{WineData.brand_name}}
                </FormItem>
                <FormItem style="margin:0" label="联系名称：">
                    {{WineData.name}}
                </FormItem>
                <FormItem style="margin:0" label="联系电话：">
                    {{WineData.mobile}}
                </FormItem>
                <FormItem style="margin:0" label="取酒时间：">
                    {{WineData.created_at}}
                </FormItem>
                <FormItem style="margin:0" label="寄存数量：">
                    {{WineData.set_num}}
                </FormItem>
                <FormItem style="margin:0" label="取酒数量：">
                    {{WineData.num}}
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="examineOk(1002)">拒绝</Button>
                <Button class="btn" @click="examineOk(2)">同意</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
    import {takeList,takeApprove} from '@/api/setting'
    import config from '@/config';
    export default {
        data(){
            return{
                uploads: config.apiUrl + '/storeapi/alcohol/takeExport',
                typeList:[
                    {id:0,value:'全部'},
                    {id:1,value:'待审核'},
                    {id:2,value:'已取酒'},
                    {id:1002,value:'已拒绝'}
                ],
                total:0,
                WineData:{},
                storePrams:{
                    keyword:'',
                    status:0,
                    start_time:'',
                    end_time:'',
                    page:1,
                    limit:10
                },
                columns:[
                    {
                        title: '订单编号',
                        key: 'number_no',
                        align: 'center'
                    },
                    {
                        title: '酒水品牌',
                        key: 'brand_name',
                        align: 'center'
                    },
                    {
                        title: '联系电话',
                        key: 'mobile',
                        align: 'center'
                    },
                    {
                        title: '联系姓名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '取酒时间',
                        key: 'created_at',
                        align: 'center'
                    },
                    {
                        title: '取酒数量',
                        key: 'num',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot:'status',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'action'
                    }
                ],
                data:[],
                examineModal:false,
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){
                takeList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            examineBtn(e){ // 打开审核弹框
                this.WineData = e
                this.examineModal = true
            },
            examineOk(status){ // 审核确定
                let data = {id:this.WineData.id,status:status}
                takeApprove(data).then(res=>{
                    this.$Message.success(res.msg)
                    this.examineCancel()
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            examineCancel(){ // 审核取消
                this.examineModal = false
            },
            searchBtn(){ // 搜索
                this.getList()
            },
            daochu(){ // 导出
                location.href = `${this.uploads}?data_id=${this.$store.state.userInfo.data_id}&status=${this.storePrams.status}&keyword=${this.storePrams.keyword}&start_time=${this.storePrams.start_time}&end_time=${this.storePrams.end_time}`
            },
            time(e){ // 时间监听
                this.storePrams.start_time = e[0]
                this.storePrams.end_time = e[1]
            },
            delBtn(){
                this.$Modal.confirm({
                    title: '您正使用删除操作',
                    content: '请问是否删除，删除后将无法恢复！！！',
                    onOk: () => {
                        
                    },
                });
            },
            pageChange(index){ // 分页
                this.storePrams.page = index
                this.getList()
            },
        }
    }
</script>

<style scoped>
    .btn{
        background:#FF761D;
        color:#FFF;
        border-color: #FF761D;
    }
</style>