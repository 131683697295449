<template>
  <div class="page-style">
    <div class="left-menu">
      <div class="menu-nav" v-for="(item,index) in leftMenu"
           :class="activeIndex === item.src ? 'active' : ''"
           :key="index" @click="changeLabel(item)">
        <Icon :type="item.icon" size="24" style="margin-right: 10px"/>
        {{ item.title }}</div>
    </div>
    <div class="right-content">
      <div class="content-del">
        <div v-if="activeIndex === '/admin/setting/basic'"><Application></Application></div>
        <div v-if="activeIndex === '/admin/setting/marketing'"><Marketing></Marketing></div>
        <div @replace="replace" v-if="activeIndex === '/admin/setting/plugin' || activeIndex === 'Integralmall' || activeIndex === 'Pointorder' || activeIndex === 'Bannersetup' || activeIndex === 'Integralsetting'" ><Plugin></Plugin></div>
      </div>
    </div>
  </div>
</template>
<script>
import Application from "./components/application";
import Marketing from "./components/marketing";
import Plugin from "./components/plugin";
export default {
  components: {Application,Marketing,Plugin},
  data() {
    return {
      leftMenu:[
        {
          icon:'ios-cafe-outline',
          label:'基本应用',
          path:'/admin/setting/basic'
        },
        {
          icon:'ios-bicycle',
          label:'营销应用',
          path:'/admin/setting/marketing'
        }
      ],
      activeIndex:0
    }
  },
  mounted() {
  },
  created() {
    let menu = this.$store.state.menu
    menu.forEach(item => {
      if (item.id==17) {
        if (this.$store.state.activeIndex==0) {
          this.activeIndex = item.children[this.$store.state.activeIndex].src
        }else{
          this.activeIndex = this.$store.state.activeIndex
        }
        this.leftMenu = JSON.parse(JSON.stringify(item.children))
        if (this.$store.state.plugin.length!=0) {
          this.leftMenu.push(
            {
              icon:'ios-apps-outline',
              title:'营销插件',
              src:'/admin/setting/plugin'
            }
          ) 
        }
      }
    });
  },
  methods: {
    changeLabel(item){
      this.$store.commit('setActiveIndex', item.src)
      this.activeIndex = item.src
    },
    goback(){
      this.$store.commit('setActiveIndex', 0)
      let menu = this.$store.state.menu
      menu.forEach(item => {
        if (item.id==17) {
          if (this.$store.state.activeIndex==0) {
            this.activeIndex = item.children[this.$store.state.activeIndex].src
          }else{
            this.activeIndex = this.$store.state.activeIndex
          }
          this.leftMenu = JSON.parse(JSON.stringify(item.children))
          if (this.$store.state.plugin.length!=0) {
            this.leftMenu.push(
              {
                icon:'ios-apps-outline',
                title:'营销插件',
                src:'/admin/setting/plugin'
              }
            ) 
          }
        }
      });
      console.log(this.$store.state.activeIndex);
    },
    // 积分商城替换菜单
    replace(){
      this.leftMenu = [
        {
          icon:'ios-pricetags-outline',
          title:'积分商城',
          src:'Integralmall'
        },
        {
          icon:'ios-paper-outline',
          title:'积分订单',
          src:'Pointorder'
        },
        {
          icon:'ios-desktop-outline',
          title:'banner设置',
          src:'Bannersetup'
        },
        {
          icon:'ios-cog-outline',
          title:'积分设置',
          src:'Integralsetting'
        }
      ]
      this.$store.commit('setActiveIndex', 'Integralmall')
      this.activeIndex = 'Integralmall'
    }
  }
}
</script>
<style scoped>
.page-style {
  display: flex;

}

.left-menu {
  width: 210px;
  height: 93vh;
  background: white;
  margin-right: 20px;
  padding: 20px 20px;
  box-sizing: border-box;
}

.right-content {
  /* flex: 1; */
  width: calc(100% - 230px);
  padding: 20px 20px 0 0;
  box-sizing: border-box;
}
.content-del {
  /*background: #fff;*/
  width: 100%;
  height: 88vh;
  border-radius: 8px;
  overflow: auto;
}
.menu-nav {
  border: 1px solid #FFF;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 200px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.menu-nav:hover{
  background: #FFF7F2;
  color: #686868;
}
.menu-nav.active{
  /* background: #FF761D; */
  border: 1px solid #FF761D;
  color: #FF761D;
  /* color: white; */
}

</style>
