<template>
  <div class="son-page">
    <div class="back_box pointer" @click="backBtn">
      <div >
        <Icon size="24" type="ios-arrow-back"/>
      </div>
      <div class="ml10">充值活动</div>
    </div>
    <div class="add_box">
      <div>
        <Button style="width:80px" @click="cardModalBtn('add')" class="btn">创建</Button>
        <Button style="margin-left:50px;width:80px" @click="descriptionBtn" class="btn">活动说明</Button>
      </div>
      <div>
            <Select v-model="storePrams.status" style="width:300px">
                <Option v-for="item in selectList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <Button @click="searchBtn" style="margin-left:20px;padding:0 30px">执行</Button>
      </div>
    </div>
    <div class="content_box">
      <Table stripe style="margin-top:20px" :columns="columns12" :data="data6">
        <template slot-scope="{ index }" slot="index">
          <strong>{{ index + 1 }}</strong>
        </template>
        <template slot-scope="{ row }" slot="type">
          <div>{{ row.page_type === 1 ? '首页' : row.page_type === 2 ? '商品页' : ''}}</div>
        </template>
        <template slot-scope="{ row }" slot="member_give_status">
            <i-switch disabled v-model="row.member_give_status" :false-value='0' :true-value='1'></i-switch>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button type="success" ghost size="small" style="margin-right: 20px"  v-if="row.status!=1" @click="push(row)">发布</Button>
          <Button type="warning" ghost size="small" v-if="row.status==1" @click="push(row)" style="margin-right: 20px;color:#6F6E71;border-color:#6F6E71">停止</Button>
          <Button type="primary" @click="cardEdit(row)" ghost v-if="row.status!=2"  size="small" style="margin-right: 20px;color:#2D8CF0;border:1px solid #2D8CF0;background:none">编辑</Button>
          <Button type="error" ghost size="small" @click="removeIt(row)">删除</Button>
        </template>
      </Table>
      <br/>
      <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        v-model="cardModal"
        :title="cardtitle"
        width="500"
        @on-cancel="cardCancel">
        <div class="body_box">
            <Form label-position="right" :label-width="160">
                <FormItem label="充值金额：">
                    <Input type="number" placeholder="请输入充值金额" v-model="formData.recharge_money"></Input>
                </FormItem>
                <FormItem label="赠送金额：">
                    <Input type="number" placeholder="请输入赠送金额" v-model="formData.give_money"></Input>
                </FormItem>
                <FormItem label="会员独立赠送：">
                    <i-switch v-model="formData.member_give_status" :false-value='0' :true-value='1'></i-switch>
                </FormItem>
                <span v-if="formData.member_give_status">
                  <FormItem v-for="(item,index) of formData.member_give_money" :key="index" :label="item.level_name + '赠送金额：'">
                      <Input type="number" placeholder="请输入赠送金额" v-model="item.give_money"></Input>
                  </FormItem>
                </span>
            </Form>
        </div>
      <div slot="footer">
        <Button @click="cardCancel">取消</Button>
        <Button class="btn" @click="cardadd">保存</Button>
      </div>
    </Modal>

    <!-- 活动说明弹框 -->
    <Modal
        v-model="activityModal"
        title="活动说明"
        width="800"
        @on-cancel="activityCancel">
        <div class="body_box">
          <div id="websiteEditorElem" style="background: #ffffff;"></div>
        </div>
      <div slot="footer">
        <Button @click="activityCancel">取消</Button>
        <Button class="btn" @click="activityadd">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {rechargeList, rechargehandle, getMemberList, rechargecreate, rechargeDel, updateRecharge,getRechargeContent,setRechargeContent} from '../../../../api/elseSetting'
import E from 'wangeditor'
import config from '@/config';
import {getCookies} from '@/utils/helper'
export default {
  name: "password",
  data() {
    return {
      uploads: config.apiUrl + '/storeapi/data/uploads',
      phoneEditor:'',
      cardtitle: '创建卡卷',
      cardModal: false,
      activityModal: false,
      card_is_enable:null,
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
        status:0
      },
      formData:  {
        recharge_money: '',
        give_money: '',
        member_give_status: null,
        member_give_money: []
      },
      selectList: [
        {value: 0,label: '全部'},
        {value: 1,label: '已发布'},
        {value: 2,label: '已停止'}
      ],
      columns12: [
        {
          title: '#',
          slot: 'index',
          width: 60,
          align: 'center'
        },
        {
          title: '充值金额',
          key: 'recharge_money',
          align: 'center'
        },
        {
          title: '赠送金额',
          key: 'give_money',
          align: 'center'
        },
        {
          title: '会员独立赠送',
          slot: 'member_give_status',
          align: 'center'
        },
        {
          title: '发布时间',
          key: 'publish_time',
          align: 'center'
        },
        {
          title: '停止时间',
          key: 'stop_time',
          align: 'center'
        },
        {
          title: '操作',
          width: 220,
        //   fixed: 'right',
          align: 'center',
          slot: 'action'
        }
      ],
      data6: [],
      sharePic:'',
      activityPic:'',
      needId:''
    }
  },
  mounted() {
    this.phoneEditor = new E('#websiteEditorElem')
    this.phoneEditor.config.uploadImgServer = this.uploads// 请求地址
    // this.phoneEditor.config.showLinkImg = false // 隐藏网络图片
    this.phoneEditor.config.uploadFileName = 'file'  //上传参数 自定义
    this.phoneEditor.config.uploadImgHeaders = {
      
          'hlzw-canyin-authori-zation':getCookies('hlzw_canyin_token') // 设置请求头
    }
    this.phoneEditor.config.uploadImgHooks = {  //监听
        customInsert: function (insertImg, result) {
            insertImg(result.data.path)
        }
    }
    this.phoneEditor.create() // 创建一个富文本编辑器
  },
  created() {
    this.getList()
  },
  methods: {
      // 获取列表
    getList() {
      rechargeList(this.storePrams).then(res => {
        this.data6 = res.data.data
        this.total = res.data.total
      })
    },
    // 获取会员等级 + 是否开启会员卡
    getMemberList(){
        getMemberList().then(res=>{
            this.formData.member_give_status = res.data.card_is_enable
            this.formData.member_give_money = []
            this.card_is_enable = res.data.card_is_enable
            let data = res.data.level_list
            data.forEach(item=>{
              this.formData.member_give_money.push({give_money:'',level_id:item.id,level_name:item.level_name})
            })
        })
    },
    descriptionBtn(){
      getRechargeContent().then(res=>{
        this.phoneEditor.txt.html(res.data.content)
        this.activityModal = true
      })
    },
    activityCancel(){
      this.activityModal = false
    },
    activityadd(){
      setRechargeContent({content:this.phoneEditor.txt.html()}).then(res=>{
        this.$Message.success(res.msg)
        this.activityModal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    // 执行按钮
    searchBtn(){
        this.getList()
    },
    push(e){
      let status = 0, msg = "请问您是否确认启用活动，确定无误后点击确认发布。";
      if (e.status == 0) {
        status = 1
      }else if(e.status == 1){
        status = 2; msg = "请问您是否确认停止活动，确定无误后点击确认停止。";
      }else if(e.status == 2){
        status = 1
      }
      this.$Modal.confirm({
        title: '发布提示',
        content: msg,
        onOk: () => {
          rechargehandle({id:e.id,status:status}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    removeIt(row){
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除，删除后您将无法恢复！！！',
        onOk: () => {
          rechargeDel({id:row.id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    cardModalBtn(e) {
      if (e == 'add') {
        this.cardtitle = '新建'
        this.getMemberList()
        this.cardModal = true
      }
    },
    cardEdit(e){
      this.getMemberList()
      this.cardtitle = '编辑'
      this.formData.id = e.id
      this.formData.recharge_money = e.recharge_money
      this.formData.give_money = e.give_money
      setTimeout(() => {
        this.formData.member_give_status = e.member_give_status
        let data = this.formData.member_give_money
        let newData = JSON.parse(JSON.stringify(e.member_give_money))
        // this.formData.member_give_money.push({give_money:'',level_id:item.id,level_name:item.level_name})
        // this.formData.member_give_money.push({give_money:'',level_id:item.id,level_name:item.level_name})
        data.forEach(item=>{
          newData.forEach(j=>{
            if (item.level_id==j.level_id) {
              item.give_money = j.give_money
            }
          })
        })
        // console.log(this.formData.member_give_money);

        // this.formData.member_give_money = JSON.parse(JSON.stringify(e.member_give_money))
      }, 500);
        // member_give_money: []


      this.cardModal = true
    },
    cardCancel() {
      this.clearForm()
      this.cardModal = false
    },
    cardadd() {
      if (this.cardtitle == '新建') {
        let data = {
            recharge_money: this.formData.recharge_money,
            give_money: this.formData.give_money,
            member_give_status: this.formData.member_give_status,
            member_give_money: this.formData.member_give_money
        }
        rechargecreate(data).then(res => {
          this.$Message.success(res.msg)
          this.cardModal = false
          this.clearForm()
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      } else {
        updateRecharge(this.formData).then(res => {
          this.$Message.success(res.msg)
          this.cardModal = false
          this.clearForm()
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }
    },
    clearForm(){
      this.formData.recharge_money = ''
      this.formData.give_money = ''
      // this.formData.member_give_status = null
      // this.formData.member_give_money = []
      // this.formData = {
      //   recharge_money: '',
      //   give_money: '',
      //   member_give_status: null,
      //   member_give_money: []
      // }
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    backBtn() {
      this.$emit('backview', 0)
    }
  }
}
</script>

<style scoped>
/* .son-page{

} */
.back_box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 20px;
}
.add_box{
    width: 100%;
    margin-top: 20px;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 66vh;
    overflow: auto;
    margin-top: 20px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.body_box{
    width: 100%;
    max-height: 500px;
    overflow: auto;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.upPic {
  margin-left: 20px;
  width: 105px;
  height: 105px;
  position: relative;
}
.upPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.chooseeMore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>

<style>
.ivu-select-single .ivu-select-selection{
    border: unset;
    background: #F8F8FA;
}
.ivu-switch-disabled.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
</style>
